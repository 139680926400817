import { Palette } from '../index';

export type NextButtonType = {
    nextButton: {
        fontFamily?: string,
        background: Palette | string,
        color: Palette | string,
        margin?: string,
    },
    nextButtonHovered: {
        background: Palette | string,
        color?: Palette | string,
    },
    nextButtonFocus: {
        background: Palette | string,
        color?: Palette | string,
        boxShadow?: string,
    },
    nextButtonDisabled: {
        cursor: string,
        background: Palette | string
        color: Palette | string,
    }
}

export const NextButtonStyles: NextButtonType = {
    nextButton: {
        background: '#FE8F20',
        color: '#FFFFFF',
        margin: '20px 0',
    },
    nextButtonHovered: {
        background: '#FFA449',
    },
    nextButtonFocus: {
        boxShadow: 'rgb(255 164 73 / 50%) 0px 0px 0px 0.2rem', // #FFA449
        background: '#DB7610',
    },
    nextButtonDisabled: {
        cursor: 'not-allowed',
        background: '#121620',
        color: '#c7c7c7',
    }
};
