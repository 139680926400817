import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    circle: {
        width: '100vw',
        height: '100vh',
        maxHeight: '206px',
        fill: 'none',
        marginTop: '16px',
        marginBottom: '32px',
    },
    circleProgress: {
        stroke: '#FE8F20',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
    },
    circleBackground: {
        stroke: '#C5C5D1',
        '&, $circleProgress': {
            fill: 'none',
        }
    },
    circleText: {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        textAlign: 'center',
        fontWeight: 400,
        fontSize: '3.75rem',
        fill: '#202B47',

        '& $percentSign': {
            fontWeight: 500,
            fontSize: '2.5rem',
        },
    },
    percentSign: {}
});
