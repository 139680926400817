import { getWeightDifference } from '../services/body-plan-calculator.service';

type EnchargeUserType = {
    email?: string,
    userId?: string,
    Gender?: string,
    UserAge?: string,
    Language?: string,
    BodyWeightMetricSystem?: string,
    BodyHeightMetricSystem?: string,
    CurrentBodyWeight?: number,
    CurrentBodyHeight?: number,
    GoalBodyWeight?: number,
    WeightDifference?: number,
};

export function getEnchargeUser(userId: string, eventName: string, params: {} | string): EnchargeUserType {
    const user: EnchargeUserType = {};

    const email = localStorage.getItem('email');
    if (email) {
        user.email = email;
        user.Language = window.navigator.language;
    } else {
        user.userId = userId;
    }

    if (eventName.includes('GenderClicked')) {
        user.Gender = params as string;
    }

    if (eventName.includes('AgeClicked')) {
        user.UserAge = params as string;
    }

    if (eventName.includes('HeightWeightClicked')) {
        // @ts-ignore
        user.BodyWeightMetricSystem = params.unit;

        // @ts-ignore
        user.BodyHeightMetricSystem = params.unit;

        // @ts-ignore
        user.CurrentBodyWeight = parseFloat(params.weight);

        // @ts-ignore
        user.CurrentBodyHeight = parseFloat(params.height);
    }

    if (eventName.includes('GoalWeightClicked')) {
        user.GoalBodyWeight = parseFloat(params as string);

        user.WeightDifference = getWeightDifference();
    }

    return user;
}
