import { API } from './api';

class SignUpApi extends API {

    signUp(data: any): Promise<any> {
        return this.post('/signup/web', data);
    }

    getAddonPrice(chargeID: string, countryCode: string): Promise<any> {
        if (!chargeID || !countryCode) {
            return Promise.reject(`No getAddonPrice provided - chargeID: ${chargeID}, countryCode - ${countryCode}`);
        }
        return this.get('/chargebee/charge', { chargeID, country: countryCode });
    }

    getPaymentKeys(data: any): Promise<any> {
        return this.post('/payment-keys', data);
    }
}

export const SignUpApiProvider = new SignUpApi();
