import React from "react";

import { useStyles } from "./styles";

export const ColoredPage = ({ children }: any) => {
    const { coloredPageWrapper, childrenWrapper } = useStyles();

    return (
        <div className={coloredPageWrapper}>
            <div className={childrenWrapper}>
                {children}
            </div>
        </div>
    )
};
