import { useEffect } from "react";

import { GeoCodingProvider } from '../api/geo-coding.api';

import { useLocalStorage } from './useLocalStorage';

const CUBA_KEY = 'CU';
const US_KEY = 'US';
const BS_KEY = 'BS';

export const useCountryCode = () => {
    const { pageValue: countryCode, setPageValue: setCountryCode } = useLocalStorage({ key: 'countryCode', defaultValue: '' });

    useEffect(() => {
        console.log('[countryCode useEffect:]', JSON.stringify(countryCode));
        console.log('[countryCode useEffect2:]', !!countryCode);
        // if (countryCode) {
        //     return;
        // }

        console.log('[countryCode useCountryCode:]');
        GeoCodingProvider.getUserData().then((res: any) => {
            // res.then((v: any) => {
            //     console.log('[countryCode resolve res]', v);
            // }
            // ).catch((e: any) => {
            //     console.log("[countryCode resolve res] error:", e);
            //     console.log(e)
            // });
            const countryCode = res;
            console.log('[countryCode res:]', countryCode);
            if (countryCode) {
                const code = countryCode === CUBA_KEY ? US_KEY : countryCode;
                setCountryCode(code);
            } else {
                // @description: Set default country code to BS
                setCountryCode(BS_KEY);
            }
        }).catch((e: any) => {
            console.log('[countryCode GeoCodingProvider.getUserData() err]', e)
        });
    }, []);

    return {
        countryCode
    }
};
