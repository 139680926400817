// Food icons
import sweet from '../assets/images/svg/food-icons/sweet.svg';
import caffeine from '../assets/images/svg/food-icons/caffeine.svg';
import fries from '../assets/images/svg/food-icons/fries.svg';
import alcohol from '../assets/images/svg/food-icons/alcohol.svg';
import salt from '../assets/images/svg/food-icons/salt.svg';

// Body types images
import ectomorph from '../assets/images/svg/body-type/male/ectomorph.svg';
import ectoMesomorph from '../assets/images/svg/body-type/male/ecto-mesomorph.svg';
import mesomorph from '../assets/images/svg/body-type/male/mesomorph.svg';
import endomorph from '../assets/images/svg/body-type/male/endomorph.svg';
import strawberry from '../assets/images/svg/body-type/female/strawberry.svg';
import triangle from '../assets/images/svg/body-type/female/triangle.svg';
import rectangle from '../assets/images/svg/body-type/female/rectangle.svg';
import pear from '../assets/images/svg/body-type/female/pear.svg';
import hourglass from '../assets/images/svg/body-type/female/hourglass.svg';

// Graphics
import weightLossGraphic from '../assets/images/svg/weight-loss/weight-loss-graphic.svg';

// Reinforcement icons
import armWithHeart from '../assets/images/svg/reinforcement-icons/arm-with-heart.svg';
import scales from '../assets/images/svg/reinforcement-icons/scales.svg';
import mountainWithFlag from '../assets/images/svg/reinforcement-icons/mountain-with-flag.svg';
import clock from '../assets/images/svg/reinforcement-icons/clock.svg';
import appleWithGlass from '../assets/images/svg/reinforcement-icons/apple-with-glass.svg';
import moon from '../assets/images/svg/reinforcement-icons/moon.svg';
import chat from '../assets/images/svg/reinforcement-icons/chat.svg';
import sunWithCloud from '../assets/images/svg/reinforcement-icons/sun-with-cloud.svg';
import congratulations from '../assets/images/svg/reinforcement-icons/congratulations.svg';
import ladderWithStar from '../assets/images/svg/reinforcement-icons/ladder-with-star.svg';
import star from '../assets/images/svg/reinforcement-icons/star.svg';

// Testimonials
import jeans from '../assets/images/webp/testimonials/jeans.webp';
import testimonialUserAvatar from '../assets/images/webp/testimonials/testimonial-image.webp';
import female from '../assets/images/webp/testimonials/answer-female.webp';
import male from '../assets/images/webp/testimonials/answer-male.webp';

// Access account
import accessLogin from '../assets/images/png/access-account/login.png';
import accessContinue from '../assets/images/png/access-account/continue.png';


export const BackgroundLoadingImages = () => {
    return {
        images: [
            { key:'svg/food-icons/sweet.svg', pathUrl: sweet },
            { key:'svg/food-icons/caffeine.svg', pathUrl: caffeine },
            { key:'svg/food-icons/fries.svg', pathUrl: fries },
            { key:'svg/food-icons/alcohol.svg', pathUrl: alcohol },
            { key:'svg/food-icons/salt.svg', pathUrl: salt },
            { key:'svg/body-type/male/ectomorph.svg', pathUrl: ectomorph },
            { key:'svg/body-type/male/ecto-mesomorph.svg', pathUrl: ectoMesomorph },
            { key:'svg/body-type/male/mesomorph.svg', pathUrl: mesomorph },
            { key:'svg/body-type/male/endomorph.svg', pathUrl: endomorph },
            { key:'svg/body-type/female/strawberry.svg', pathUrl: strawberry },
            { key:'svg/body-type/female/triangle.svg', pathUrl: triangle },
            { key:'svg/body-type/female/rectangle.svg', pathUrl: rectangle },
            { key:'svg/body-type/female/pear.svg', pathUrl: pear },
            { key:'svg/body-type/female/hourglass.svg', pathUrl: hourglass },
            { key:'svg/weight-loss/weight-loss-graphic.svg', pathUrl: weightLossGraphic },
            { key:'svg/reinforcement-icons/arm-with-heart.svg', pathUrl: armWithHeart },
            { key:'svg/reinforcement-icons/scales.svg', pathUrl: scales },
            { key:'svg/reinforcement-icons/mountain-with-flag.svg', pathUrl: mountainWithFlag },
            { key:'svg/reinforcement-icons/clock.svg', pathUrl: clock },
            { key:'svg/reinforcement-icons/apple-with-glass.svg', pathUrl: appleWithGlass },
            { key:'svg/reinforcement-icons/moon.svg', pathUrl: moon },
            { key:'svg/reinforcement-icons/chat.svg', pathUrl: chat },
            { key:'svg/reinforcement-icons/sun-with-cloud.svg', pathUrl: sunWithCloud },
            { key:'svg/reinforcement-icons/congratulations.svg', pathUrl: congratulations },
            { key:'svg/reinforcement-icons/ladder-with-star.svg', pathUrl: ladderWithStar },
            { key:'svg/reinforcement-icons/star.svg', pathUrl: star },
            { key:'webp/testimonials/jeans.webp', pathUrl: jeans },
            { key:'webp/testimonials/testimonial-image.webp', pathUrl: testimonialUserAvatar },
            { key:'webp/testimonials/answer-female.webp', pathUrl: female },
            { key:'webp/testimonials/answer-male.webp', pathUrl: male },
            { key:'png/access-account/login.png', pathUrl: accessLogin },
            { key:'png/access-account/continue.png', pathUrl: accessContinue },
        ]
    }
};
