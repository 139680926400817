// node modules
import { createUseStyles } from 'react-jss'

import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ input, typography }: ThemeProps) => ({
    inputWrapper: {
        position: 'relative',
        background: '#F6F6F6',
        borderRadius: '.5rem',
        boxSizing: 'border-box',
        display: 'flex',
        fontFamily: 'SF Pro Text',
        alignItems: 'center',
        height: '56px',
        maxWidth: '560px',
        margin: '0 auto',
        padding: '8px 16px',
    },
    input: {
        border: 'none',
        outline: 'none',
        background: 'none',
        all: 'unset',
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        borderRadius: '.5rem',
        color: '#202B47',
        marginTop: '20px',
        caretColor: '#4B46EC',
    },
    inputCloseIconClass: {
        padding: '16px 16px 16px 0',
    },
    inputActiveClass: {
        backgroundImage: 'linear-gradient(#4B46EC, #4B46EC)',
        backgroundPosition: 'bottom 0 center',
        backgroundSize: '100% 2px',
        backgroundRepeat: 'no-repeat',
    },
    inputDisabledClass: {
        cursor: 'not-allowed',
        opacity: .4
    },
    inputErrorClass: {
        backgroundImage: 'linear-gradient(#FF4D2B, #FF4D2B)',
        backgroundPosition: 'bottom 0 center',
        backgroundSize: '100% 2px',
        backgroundRepeat: 'no-repeat',
    },
    inputLabelClass: {},
    inputLabelActiveClass: typography.inputLabelActive,
    inputLabelErrorClass: typography.inputLabelError,
    inputEndAdornmentClass: {
        marginTop: '20px',
    },
}));

