import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const AchieveGoal = () => {
    const { pageValue: achieveGoal, setPageValue }: any = useLocalStorage({ key: LocalStorageKeys[RoutePath.AchieveGoal], defaultValue: '' });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'fast', value: 'As fast as possible' },
        { key: 'slow', value: 'Slow and steady' },
        { key: 'average', value: 'Somewhere in between' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                How quickly would you like to achieve your goal?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == achieveGoal}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
