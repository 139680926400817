import React, { useContext } from 'react';

import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { MultiChoiceButton, MultiChoiceType } from '../../components/multichoice-button';
import { FloatedButton } from '../../components/floated-button';

import { BaseContext } from '../../context/base.context';
import { RoutePath } from '../../routes/route-path.constant';

import { useLocalStorage } from '../../hooks/useLocalStorage';

export const LoseWeight = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({ key: LocalStorageKeys[RoutePath.LoseWeight], defaultValue: [] });

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    const multiChoices: MultiChoiceType[] = [
        { key: 'feel-good', value: 'To feel good in my body' },
        { key: 'do-more', value: 'To be able to do more' },
        { key: 'fewer-medications', value: 'To take fewer medications' },
        { key: 'more-confidence', value: 'To have more confidence' },
        { key: 'live-longer', value: 'To live longer' },
        { key: 'be-healthier', value: 'To be healthier' },
        { key: 'other', value: 'Other' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                Why do you want to lose weight?
            </Typography>
            <Typography variant={TypographyVariants.h2}>
                Select all that apply
            </Typography>
            {
                multiChoices.map((v: MultiChoiceType) => (
                    <MultiChoiceButton
                        key={v.key}
                        item={v}
                        pageValue={pageValue}
                        setPageValue={setPageValue}
                    />
                ))
            }
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
