import React from 'react';
import { useNavigate } from 'react-router-dom';

import leftArrow from '../../../assets/images/svg/left-arrow.svg';

import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { isBoolean } from '../../../helpers';

import { useStyles } from './styles';
import { Typography } from '../../../components/typography';
import { TypographyVariants } from '../../../constants';


export const EmailHeader = React.memo(({ classNames, backButtonAvailable }: any) => {

    const {
        header,
        backButton,
        backButtonWrapper,
        backButtonIconInnerWrapper,
        headerContent,
        headerAvatar,
        headerEmail,
    } = useStyles();

    const navigate = useNavigate();
    const handleBack = () => navigate(-1);

    const isBackButtonAvailable = isBoolean(backButtonAvailable)
        ? backButtonAvailable
        : backButtonAvailable || true;

    // window.history.state?.idx > 0

    const { pageValue: email } = useLocalStorage({ key: 'email', defaultValue: '' });

    const firstLetter = email ? email.split('')[0].toUpperCase() : '';

    return (
        <header className={`${header} ${classNames?.EmailHeaderHeader}`}>

            {isBackButtonAvailable && <div className={backButtonWrapper}>
                <div className={backButtonIconInnerWrapper} onClick={handleBack}>
                    <img className={backButton} src={leftArrow} alt='' />
                </div>
            </div>
            }

            <div className={`${headerContent} ${classNames?.EmailHeaderContent}`}>
                <div className={`${headerAvatar} ${classNames?.EmailHeaderHeaderAvatar}`}>{firstLetter}</div>
                <Typography variant={TypographyVariants.h3}
                            className={`${headerEmail} ${classNames?.EmailHeaderHeaderEmail}`}>
                    {email}
                </Typography>
            </div>

            {isBackButtonAvailable && <div className={backButtonWrapper}></div>}
        </header>
    );
});
