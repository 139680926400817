import React, {useContext} from 'react';

import {Typography} from '../../components/typography';
import {ChoiceButton, ChoiceType} from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import {useLocalStorage} from '../../hooks/useLocalStorage';
import {BaseContext} from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const BodyGender = () => {

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: bodyGender, setPageValue }: any = useLocalStorage({ key: LocalStorageKeys[RoutePath.BodyGender], defaultValue: '' });

    const handleClick = (goal: ChoiceType) => {
        setPageValue(goal.key);

        pageConfiguration.handleOnPageComplete(goal.value);
    };

    const genders: ChoiceType[] = [
        { key: 'female', value: 'Female' },
        { key: 'male', value: 'Male' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                What is your biological sex?
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                Your biological sex and hormones impact how your body metabolizes food.
            </Typography>

            {
                genders.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key === bodyGender}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    )
};
