import React from 'react';

import { TypographyVariants } from '../../../constants';

import { Typography } from '../../../components/typography';

import { useStyles } from './styles';

type PaymentInfoProps = {
    price: string;
}

export const PaymentInfo = ({ price }: PaymentInfoProps) => {
    const {
        specialOffer, title, totalPriceCount,
        codeHint, initialPrice, finalPrice, costPerWeekPrice, costPerWeekText,
        subtitle, totalPriceWrapper, totalPrice, howMuchSaveText, reminderText
    } = useStyles();


    return (
        <>
            <Typography variant={TypographyVariants.h3} className={specialOffer}>
                Special Offer
            </Typography>
            <Typography className={title} variant={TypographyVariants.h2}>
                Start your 7-day trial
            </Typography>
            <Typography className={subtitle}  variant={TypographyVariants.h3}>
                No commitment. Cancel anytime.
            </Typography>
            <div className={totalPriceWrapper}>
                <Typography className={totalPrice} variant={TypographyVariants.h3}>Total today</Typography>
                <Typography className={totalPriceCount} variant={TypographyVariants.h3}>
                    ${price}
                </Typography>
            </div>
            <Typography className={codeHint} variant={TypographyVariants.h3}>
                Code ABLE22 applied!
            </Typography>
            <div className={costPerWeekPrice}>
                <Typography variant={TypographyVariants.h3} className={costPerWeekText}>
                    Your cost per week after trial
                </Typography>
                <Typography className={initialPrice} variant={TypographyVariants.h3}>
                    <s>$29</s>
                </Typography>
                <Typography className={finalPrice} variant={TypographyVariants.h3}>
                    $19
                </Typography>
            </div>
            <Typography className={howMuchSaveText} variant={TypographyVariants.h3}>
                Save $10 every week
            </Typography>
            <Typography className={reminderText} variant={TypographyVariants.h3}>
                We’ll email you a reminder before your trial period ends. Cancel anytime.
            </Typography>
        </>
    );
};
