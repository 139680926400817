import React, {useContext, useEffect, useState} from 'react';

import {RoutePath} from '../../routes/route-path.constant';
import { MultipleLocalStorageKeys, TypographyVariants } from '../../constants';
import { getUnitSystem, TABS, UNIT_SYSTEM } from '../../constants/body-height-weight';

import { BaseContext } from '../../context/base.context';
import { useLocalStorage } from '../../hooks/useLocalStorage';

import { Tabs } from '../../components/tabs';
import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';

import { convertToCm, convertToFtInches, convertToKg, convertToLbs } from '../../helpers/unit-converter';

import { LbsInput } from '../../components/mass-input/lbs-input';
import { KgInput } from '../../components/mass-input/kg-input';

import { CmInput } from './cm-input';
import { FtInchesInputs } from './ft-inches-inputs';

import { useStyles } from './styles';

export const BodyHeightWeight = () => {
    const { marginTop } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const bodyHeightWeightKeys = MultipleLocalStorageKeys[RoutePath.BodyHeightWeight];
    const countryCode = localStorage.getItem('countryCode') as string;

    const [pageError, setPageError] = useState(false);
    const [disabledButtonState, setDisabledButtonState] = useState(true);
    const [errors, setError] = useState({});

    const handleClick = () => {
        if (disabledButtonState) {
            return;
        }

        const data = {
            unit: bodyHeightWeightUnitSystem,
            height: bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? `${bodyHeightFt}.${bodyHeightInches}` : bodyHeightCm,
            weight: bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? bodyWeightLbs : bodyWeightKg,
        };

        pageConfiguration.handleOnPageComplete(data);
    };

    const { pageValue: bodyHeightWeightUnitSystem, setPageValue: setBodyHeightWeightUnitSystem } = useLocalStorage({
        key: bodyHeightWeightKeys.unitSystem,
        defaultValue: countryCode === 'US' ? UNIT_SYSTEM.imperial : UNIT_SYSTEM.metric,
    });

    const { pageValue: bodyHeightCm, setPageValue: setBodyHeightCm } = useLocalStorage({
        key: bodyHeightWeightKeys.heightCm,
        defaultValue: '',
    });

    const { pageValue: bodyHeightFt, setPageValue: setBodyHeightFt } = useLocalStorage({
        key: bodyHeightWeightKeys.heightFt,
        defaultValue: '',
    });

    const { pageValue: bodyHeightInches, setPageValue: setBodyHeightInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });

    const { pageValue: bodyWeightLbs, setPageValue: setBodyWeightLbs } = useLocalStorage({
        key: bodyHeightWeightKeys.weightLbs,
        defaultValue: '',
    });

    const { pageValue: bodyWeightKg, setPageValue: setBodyWeightKg } = useLocalStorage({
        key: bodyHeightWeightKeys.weightKg,
        defaultValue: '',
    });

    const onTabChange = (nextTab: string) => {

        if (nextTab !== bodyHeightWeightUnitSystem) {
            if (nextTab === UNIT_SYSTEM.imperial) {
                const lbs = convertToLbs(Number(bodyWeightKg));
                const { ft: convertedFt, inches: convertedInches } = convertToFtInches(Number(bodyHeightCm));
                if (lbs) {
                    setBodyWeightLbs(lbs);
                }else if (bodyWeightKg == '') {
                    setBodyWeightLbs('');
                }
                if (convertedFt) {
                    setBodyHeightFt(convertedFt);
                } else if (bodyHeightCm == '') {
                    setBodyHeightFt('');
                }
                if (convertedInches) {
                    setBodyHeightInches(convertedInches);
                } else if (bodyHeightCm == '') {
                    setBodyHeightInches('');
                }
            } else {
                const kg = convertToKg(Number(bodyWeightLbs));
                const cm = convertToCm(Number(bodyHeightFt), Number(bodyHeightInches));

                if (kg) {
                    setBodyWeightKg(kg);
                } else if (bodyWeightLbs == '') {
                    setBodyWeightKg('');
                }

                if (cm) {
                    setBodyHeightCm(cm);
                } else if (bodyHeightFt == '' && bodyHeightInches == '') {
                    setBodyHeightCm('');
                }
            }
            setBodyHeightWeightUnitSystem(nextTab);
        }
    };

    const onChangeFt = (e: any) => { setBodyHeightFt(e.target.value); };
    const onChangeInches = (e: any) => { setBodyHeightInches(e.target.value); };
    const onChangeCm = (e: any) => { setBodyHeightCm(e.target.value); };
    const onChangeLbs = (e: any) => { setBodyWeightLbs(e.target.value); };
    const onChangeKg = (e: any) => { setBodyWeightKg(e.target.value); };

    const onError = (error: any, key: any) => {
        // @ts-ignore
        errors[key] = error;

        // @ts-ignore
        setPageError(Object.keys(errors).some(key => errors[key]));
        setError(errors);
    };

    function getButtonState() {
        const unit = getUnitSystem();

        if (unit === UNIT_SYSTEM.imperial) {
            return pageError || (!bodyHeightFt || !bodyWeightLbs);
        }

        return pageError || (!bodyHeightCm || !bodyWeightKg);
    }

    useEffect(() => {
        setDisabledButtonState(getButtonState());
    }, [bodyHeightWeightUnitSystem, bodyHeightCm, bodyHeightFt, bodyWeightKg, bodyWeightLbs, pageError]);

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                What is your height and current weight?
            </Typography>

            <Tabs
                onChange={onTabChange}
                tabs={TABS}
                activeTab={bodyHeightWeightUnitSystem} />

            {bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial && (
                <>
                    <FtInchesInputs
                        ft={bodyHeightFt}
                        inches={bodyHeightInches}
                        onChangeFt={onChangeFt}
                        onChangeInches={onChangeInches}
                        onError={onError} />

                    <div className={marginTop}>
                        <LbsInput
                            value={bodyWeightLbs}
                            onChange={onChangeLbs}
                            onError={onError} />
                    </div>
                </>
            )}

            {bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric && (
                <>
                    <CmInput
                        value={bodyHeightCm}
                        onChange={onChangeCm}
                        onError={onError} />

                    <div className={marginTop}>
                        <KgInput
                            value={bodyWeightKg}
                            onChange={onChangeKg}
                            onError={onError} />
                    </div>
                </>
            )}

            <NextButton onClick={handleClick} disabled={disabledButtonState}/>
        </>
    );
};
