// node modules
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #C5C5D1',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        width: '160px',
        paddingBottom: '15px',
        paddingTop: '16px',
        boxSizing: 'border-box'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

        '& h1': {
            margin: 0,
        }
    },
    icon: {
        marginTop: '12px',
        width: '23px',
    },
    btn: {
        minWidth: 'auto',
        width: 'calc(100% - 28px)',
        background: 'rgb(224, 227, 235)',
        color: 'rgb(134, 140, 153)',
        marginTop: '20px',
        borderRadius: '10px',

        '& span': {
            fontSize: '14px',
            lineHeight: '18px',
            margin: 0,
        },
        '&:active': {
            background: '#FE8F20',
            color: '#FFFFFF',
        }
    },
    activeBtn: {
        background: '#FE8F20',
        color: '#FFFFFF',
        '&:active': {
            background: 'rgb(224, 227, 235)',
            color: 'rgb(134, 140, 153)',
        }
    },
    activeImg: {
        width: '16px',
    },
    typClass: {
        margin: '12px 0 0',
        color: 'rgb(134, 140, 153)',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '14px',
    },
    totalSavingTitle: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
        margin: 0,
    },
    priceWrapperActive: {
        fontWeight: 600,
    },
    priceWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        margin: 0,
    },
    wrapperActive: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderColor: '#FE8F20',
        position: 'relative',
    },
    activePopUp: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '-33px',
        left: '-2px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        fontFamily: 'SF Pro Text',
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 600,
        background: '#FE8F20',
        height: '32px',
        width: 'calc(100% + 4px)',
        color: 'rgb(255, 255, 255)',
    },
    priceTextStyle: {
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        margin: 0,
    },
})
