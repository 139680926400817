import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import { SignUpApiProvider } from '../../api/sign-up.api';

import { useLocalStorage } from '../../hooks/useLocalStorage';

import { LocalStorageKeys } from '../../constants';
import { RoutePath } from '../../routes/route-path.constant';
import { PLANCODES } from '../../constants/trial-pay';

export const usePaywallInfo = () => {
    const [stripeProp, setStripeProp] = useState<any>({});
    const [stripePromise, setStripePromise] = useState<any>(null);
    const [loadedKeys, sedLoadedKeys] = useState(false);

    const { pageValue: planCode } = useLocalStorage({ key: LocalStorageKeys[RoutePath.TrialPay], defaultValue: PLANCODES[0] });
    const { pageValue: countryCode } = useLocalStorage({ key: 'countryCode', defaultValue: '' });
    const { pageValue: checkoutPayKey, setPageValue: setCheckoutPayKey } = useLocalStorage({ key: 'checkoutPayKey', defaultValue: '' });
    const { pageValue: stripePublishableKey, setPageValue: setStripePublishableKey } = useLocalStorage({ key: 'stripePublishableKey', defaultValue: '' });

    useEffect( () => {
        if(countryCode) {

            SignUpApiProvider
                .getAddonPrice(planCode.plan, countryCode)
                .then(({ price, currency_code }) => {
                    setStripeProp({ price, currency: currency_code });
                }).catch((e: any) => {
                    console.error('getAddonPrice', e);
                });

            SignUpApiProvider.getPaymentKeys({
                countryCode,
                amount: {
                    currency: 'USD',
                    value: 0,
                },
            }).then((response) => {
                const stripePublishableKey = response.stripePublishableKey;
                const checkoutPublishableKey = response.checkoutPublicKey;

                setStripePublishableKey(stripePublishableKey);
                setCheckoutPayKey(checkoutPublishableKey);

                setStripePromise(loadStripe(stripePublishableKey));
            }).then(() => {
                sedLoadedKeys(true);
            }).catch((e: any) => {
                console.error('getPaymentKeys', e);
            });
        } else {
            console.error('No countryCode provided');
        }
    }, [countryCode]);

    return {
        price: planCode.price,
        stripeProp,
        countryCode,
        loadedKeys,
        accessPaymentsKeys: {
            checkoutPayKey,
            stripePromise,
            braintreeAuthorization: process.env.REACT_APP_BRAINTREE_AUTHORIZATION as string,
            amazonSellerId: '', // Able doesn't have an Amazon payment
            amazonClientId: '' // Able doesn't have an Amazon payment
        }
    }
};
