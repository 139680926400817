import { createUseStyles } from "react-jss";

const commonLabelStyles = {
    fontSize: '16px',
    borderTopRightRadius: '32px',
    borderBottomRightRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    paddingLeft: '31px',
}

export const useStyles = createUseStyles({
    wrapper: {
        borderRadius: '12px',
        maxWidth: '343px',
        margin: '0 16px',
        background: '#ffffff',
        padding: '24px 16px',
        width: '100%',
    },
    title: {
        fontSize: '22px',
        maxWidth: '250px',
        margin: '0 auto 8px',
        letterSpacing: '0.7px',
    },
    subtitle: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '0 auto 32px',
        maxWidth: '305px',
        '& span': {
            fontWeight: 600,
        }
    },
    finalText: {
        maxWidth: 'auto',
        textAlign: 'left',
        margin: '0 0 12px',
        '& span': {
            color: '#FE8F20',
            fontWeight: 600,
        }
    },
    textWrapper: {
        minWidth: '107px',
        height: '60px',
        background: '#FFD702',
        borderRadius: '12px',
        position: 'relative',
        padding: '12px',
        boxSizing: 'border-box',
        marginBottom: '12px'
    },
    triangle: {
        position: 'absolute',
        bottom: '-9px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontWeight: 600,
    },
    labelWrapper: {
        minWidth: '107px',
        maxWidth: '107px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 14
    },
    gainWrapper: {
      maxWidth: 127
    },
    imageContent: {
        paddingTop: '72px',
        position: 'relative',
        paddingRight: '98px',
        marginBottom: '32px',
    },
    planLabel: {
        ...commonLabelStyles,
        background: '#FE8F20',
        color: '#ffffff',
        marginBottom: '12px',
    },
    ableLabel: {
        ...commonLabelStyles,
        color: '#4B46EC',
        border: '1px solid #4B46EC',
        background: '#F0F0FF',
        width: '106px',
        boxSizing: 'border-box',
        margin: 0,
    }
});
