// node modules
import { createUseStyles } from 'react-jss'

import {ThemeProps} from '../../themes';
import {ButtonType} from '../../themes/default/button';
import image from '../../assets/images/svg/alcohol.svg';

export const useStyles = createUseStyles(({ multiChoiceButton }: ThemeProps) => ({
    multiChoiceButton: {
        ...multiChoiceButton.multiChoiceButton,
        display: 'flex',
        alignItems: 'center',
        '&:active': {
            ...multiChoiceButton.multiChoiceButtonActive,
        }
    },
    multiChoiceButtonSelected: multiChoiceButton.multiChoiceButtonSelected,
    imageClass: {
        width: '32px',
        height: '32px',
        marginLeft: 'auto',
    }
}));
