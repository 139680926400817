import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        display: "flex",
        marginTop: 24,
        width: '100%',
        justifyContent: 'space-between'
    },
    inputWrapperClass: {
        width: '48%'
    }
});
