import React, { FC, useEffect } from 'react';

import { ValidatedInput } from '../../validated-input';

import { lbsValidationQuery } from "./lbs-validation-query";
import {kgValidationQuery} from '../kg-input/kg-validation-query';

const props = { label: 'Weight', maxLength: 3 };

const LABEL = 'lbs';

type LbsInputProps = {
    value: string,
    onChange: (e: any) => void;
    onError: (error: boolean, key: string) => void,
    lessThen?: number,
    autoFocus?: boolean,
    withEndAdornment?: boolean
}

export const LbsInput: FC<LbsInputProps> = ({ value, onChange, onError, lessThen, autoFocus, withEndAdornment = true }) => {

    const handleError = (key: string) => (error: boolean) => onError(error, key);

    useEffect(() => () => onError(false, LABEL), []);

    const endAdornmentLabel = withEndAdornment ? LABEL : '';

    // @TODO: Write it more elegant (for eg. custom validation query);
    const validationQuery = JSON.parse(JSON.stringify(lbsValidationQuery));
    if (lessThen) {
        validationQuery.type.lessThen.value = lessThen;
    }

    return (
        <ValidatedInput
            type="number"
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            onError={handleError(LABEL)}
            validationQuery={validationQuery}
            endAdornment={endAdornmentLabel}
            {...props}
        />
    )
}
