import { Palette } from '../index';

export type ChoiceButtonType = {
    choiceButton: {
        border: string,
        width: string,
        minWidth: string,
        maxWidth: string,
        minHeight: string,
        height: string,
        margin: string,
        padding: string,
    }
    choiceButtonSelected: {
        background: Palette | string
        color: Palette | string,
    },
    choiceButtonActive?: {
        boxShadow: string,
    }
}

export const ChoiceButtonStyles: ChoiceButtonType = {
    choiceButton: {
        border: '1px solid #F6F6F6',
        minWidth: '280px',
        width: '100%',
        maxWidth: '560px',
        minHeight: '56px',
        height: 'auto',
        margin: '0 0 12px',
        padding: '16px',
    },
    choiceButtonSelected: {
        background: '#4B46EC',
        color: '#F6F6F6',
    },
    choiceButtonActive: {
        boxShadow: 'rgb(145 156 248 / 50%) 0px 0px 0px 0.2rem',
    }
};
