import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    chargedText: {
        fontFamily: 'SF Pro Text',
        textAlign: 'left',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        margin: '0 0 16px 0',
        color: '#202B47',
        '&>span': {
            fontWeight: 600
        }
    }
});
