export class API {

    get(action: string, params?: any): Promise<any> {
        const searchParams = new URLSearchParams();

        for (let key in params) {
            searchParams.append(key, params[key]);
        }

        const url = `${process.env.REACT_APP_API_URL}/v1${action}?${searchParams}`;

        return fetch(url, {
            method: 'GET',
            mode: 'cors',
        }).then((response) => {
            if (!response.ok) {
                console.error('GET error', response);
            }
            return response.json();
        }).then((data) => {
            if (data.error) {
                return Promise.reject(data);
            }
            return Promise.resolve(data);
        });
    }

    post(action: string, params: any = ''): Promise<any> {
        const url = `${process.env.REACT_APP_API_URL}/v1${action}`;

        return fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(params)
        }).then((response) => {
            if (!response.ok) {
                console.error('POST error', response);
            }
            return response.json();
        }).then((data) => {
            if (data.error) {
                return Promise.reject(data);
            }
            return Promise.resolve(data);
        });
    }
}
