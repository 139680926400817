import React, { useContext, useEffect, useState } from 'react';

import { BaseContext } from '../../context/base.context';

import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { ColoredPage } from '../../components/colored-page';

import { PreloadImageServiceInstanceCTX } from '../../services/preload-image.service';
import { RoutePath } from '../../routes/route-path.constant';

export const UpsAndDown = () => {
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);

    const { pageConfiguration } = useContext(BaseContext);

    const [img, setImg] = useState('');

    const sunWithCloudIconPath = 'svg/reinforcement-icons/sun-with-cloud.svg';
    const starIconPath = 'svg/reinforcement-icons/star.svg';

    const failureAnswer = ['3', '4', '5'];
    const failure = failureAnswer.includes(localStorage.getItem(LocalStorageKeys[RoutePath.FeelFailure]) as string);

    const item = failure ? sunWithCloudIconPath : starIconPath;

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    useEffect(() => {
        (async () => {
            if (item) {
                const img = await getImageByLocalPath(item);
                setImg(img as string);
            }
        })();
    }, [item, getImageByLocalPath]);

    let text;

    if (failure) {
        text = `Nobody is perfect. We all have our ups and down.
        
                Even if you fall off the wagon, you are NEVER a failure.

                We’ll help you identify self-sabotaging thoughts that may be getting in the way of your health and happiness.`;
    } else {
        text = `You are a superstar.

                We’re so glad you’re thinking constructively. 

                You can accomplish almost anything with a healthy mindset.`;
    }
    return (
        <ColoredPage>
            <div>
                <img src={img} alt='' />
                <Typography variant={TypographyVariants.coloredPage}>
                    {text}
                </Typography>
            </div>

            <NextButton bottomMargin onClick={handleClick} typographyText='Got it!' />
        </ColoredPage>
    );
};
