import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 16px'
    }
});
