import React, { useContext, useEffect, useState } from 'react'

import { BaseContext } from '../../context/base.context';

import { TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { ColoredPage } from "../../components/colored-page";

import { PreloadImageServiceInstanceCTX } from "../../services/preload-image.service";

export const SmallChanges = () => {
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);

    const { pageConfiguration } = useContext(BaseContext);

    const [img, setImg] = useState('');

    const item = 'svg/reinforcement-icons/clock.svg';

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    useEffect(() => {
        (async () => {
            if (item) {
                const img = await getImageByLocalPath(item);
                setImg(img as string);
            }
        })();
    }, [item, getImageByLocalPath]);

    return (
        <ColoredPage>
            <div>
                <img src={img} alt="" />

                <Typography variant={TypographyVariants.coloredPage}>
                    10 minutes a day.
                    <br />
                    <br />
                    That’s all you need to develop a routine that works for you.
                    <br />
                    <br />
                    Small changes can add up to big results.
                    <br />
                    <br />
                    You’ve got this!
                </Typography>
            </div>

            <NextButton bottomMargin onClick={handleClick} typographyText="Got it!" />
        </ColoredPage>
    )
};
