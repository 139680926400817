import * as React from 'react';

import { Button } from '../button';
import {isBoolean} from '../../helpers';

import { useStyles } from './styles';
import {TypographyVariants} from '../../constants';

export type ChoiceType = { key: string, value: string };

export const ChoiceButton = ({selected, center, typographyVariant, children, ...props}: any) => {

    const { choiceButton, choiceButtonSelected, textAlignCenter } = useStyles();

    let selectBtnClass = `${choiceButton}`;

    if (isBoolean(center) && center) {
        selectBtnClass += ` ${textAlignCenter}`;
    }

    if (selected) {
        selectBtnClass += ` ${choiceButtonSelected}`;
    }

    let tVariant = TypographyVariants.choiceButton;

    if (typographyVariant) {
        // @ts-ignore // Remove Typography for passing from up, because of typographyVariant.choiceButton -> typographyVariant.choiceButtonTypography
        tVariant = TypographyVariants[typographyVariant.replace('Typography', '')] ? TypographyVariants[typographyVariant.replace('Typography', '')] : tVariant;
    }

    return (
        <Button className={selectBtnClass} typographyVariant={'choiceButton'} {...props}>{children}</Button>
    );
};
