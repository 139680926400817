export const ftValidationQuery: any = {
    type: {
        value: "number",
        moreThen: {
            value: 1,
            errorMessage: "Make sure you insert the correct height."
        },
        lessThen: {
            value: 9,
            errorMessage: "Make sure you insert the correct height."
        }
    }
};

export const inchesValidationQuery: any = {
    type: {
        value: "number",
        lessThen: {
            value: 12,
            errorMessage: "Make sure you insert the correct height."
        }
    }
};
