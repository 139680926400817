import React from 'react';

// @TODO: Move to helpers
import { getNextMonth } from '../../../services/body-plan-calculator.service';

import { GoalChartPopover } from '../chart-popover';

import { useStyles } from './styles';

export const GoalChartLoseWeight = ({ unit, weightPlan, monthsPlan, latestMonth }: { unit: string, weightPlan: number[], monthsPlan: string[], latestMonth: string }) => {
    const { numbersClass, monthClass, weightGraphic, popoverWrapper } = useStyles();

    const monthsPlanDisplay = [...monthsPlan];

    if (latestMonth && monthsPlanDisplay.length) {
        const isLatestSame = monthsPlanDisplay[monthsPlanDisplay.length - 1].toLowerCase() === latestMonth.toLowerCase();
        if (!isLatestSame) {
            const isOneBeforeLatestSame = monthsPlanDisplay[monthsPlanDisplay.length - 2].toLowerCase() === latestMonth.toLowerCase();
            if (isOneBeforeLatestSame) {
                monthsPlanDisplay.pop();
            } else {
                monthsPlanDisplay[monthsPlanDisplay.length - 1] = latestMonth;
            }
        }
    }

    if (monthsPlanDisplay.length) {
        // @description: just for chart
        const lastMonth = getNextMonth(monthsPlanDisplay[monthsPlanDisplay.length - 1]);
        monthsPlanDisplay.push(lastMonth);
    }

    const weightPlanDiplay = weightPlan?.map((weight, i) => {
        if (!(weight % 2)) {
            return weight;
        }
        // @description: For ODD numbers. Latest element less
        if (weightPlan.length === i + 1) {
            return weight - 1;
        }
        return weight + 1;
    });

    // @description: weightPlan with odd numbers, we increase the display items and lost the medium item
    if (weightPlanDiplay.length === 2) {
        const first = weightPlanDiplay[0];
        const second = weightPlanDiplay[1];

        const diff = (first - second) / 2;

        if (!(diff % 2)) {
            weightPlanDiplay.splice(1, 0, first - diff);
        }
    }

    return (
        <>
            <div className={weightGraphic}>

                <div className={numbersClass}>
                    {
                        weightPlanDiplay.map((weight) => (<span key={weight}>{weight}</span>))
                    }
                </div>

                <GoalChartPopover weight={weightPlan[weightPlan.length - 1]} unit={unit} popoverWrapperClass={popoverWrapper} />

                <svg width="100%" height="100%" viewBox="0 0 360 215" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <g>
                        <path strokeWidth="2" stroke="#E0E3EB" d="m1.90317,212.92857"/>
                        <path stroke="null" fill="#E0E3EB" d="m360,191.06765c-109.96311,5.54168 -173.55631,-31.70359 -250.38226,-96.50748c-34.44129,-33.67016 -72.24027,-79.82148 -109.50695,-79.82148l0,200.2613l359.88921,0l0,-23.93233l0,-0.00001z" opacity="0.3"/>
                        <path stroke="#36B992" className="weightGraphicPath" strokeWidth="3" d="M0.7142999999999802,13.857020000000006C39.37223999999998,13.857020000000006 75.29193999999998,63.15854000000001 109.64096999999998,94.58097000000001C179.17647999999997,158.19135 249.31622999999996,199.24315 358.69744,193.5603"/>
                        <path stroke="#E0E3EB" d="m0.07794,213.82583l360.066,0" strokeWidth="2"/>
                    </g>
                </svg>

            </div>

            <div className={monthClass}>
                {
                    monthsPlanDisplay.map((month, i) => (<span key={`${month}-${i}`}>{month}</span>))
                }
            </div>
        </>
    )
};
