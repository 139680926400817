import { useContext, useState } from 'react';

import { BaseContext } from '../../context/base.context';

import { getSignUpData } from '../../helpers/sign-up-data';

import { SignUpApiProvider } from '../../api/sign-up.api';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { LocalStorageKeys } from '../../constants';
import { RoutePath } from '../../routes/route-path.constant';
import { PLANCODES } from '../../constants/trial-pay';

const PAYMENT_METHODS = {
    creditCard: 'Credit card',
    applePay: 'Apple Pay',
    payPal: 'PayPal',
    googlePay: 'Google Pay'
};

export const usePayment = () => {
    const { pageConfiguration, optimizely } = useContext(BaseContext);

    const [error, setError] = useState('');
    const [cardError, setCardError] = useState('');
    const [loadingRequest, setLoadingRequest] = useState(false);

    const { pageValue: planCode } = useLocalStorage({ key: LocalStorageKeys[RoutePath.TrialPay], defaultValue: PLANCODES[0] });
    const { pageValue: email } = useLocalStorage({ key: LocalStorageKeys[RoutePath.Email], defaultValue: '' });

    const { pageValue: countryCode } = useLocalStorage({ key: 'countryCode', defaultValue: '' });

    const PaymentSignUp = (paymentMethod: string, signUpData: {}, event?: any) => {
        return SignUpApiProvider.signUp(signUpData)
            .then(() => {

                pageConfiguration.event('OnboardingCheckoutStartClicked', {
                    value: planCode?.key,
                    source: paymentMethod,
                    status: '200'
                });

                // @TODO: Track for now, better to rewrite it if we'll use optimizely
                optimizely.track('WebSubscriptionPurchased', pageConfiguration.getUserId());

                //@TODO
                pageConfiguration.event('OnboardingSubscriptionPurchased', { PlanName: planCode?.key, Source: paymentMethod });

                window.gtag('event', 'Purchase', {
                    'event_category': '<WebOnboarding>',
                    'event_label': '<WeightLoss>',
                    'value': { conversionValue:  100 },
                    'send_to': 'agency',
                    'conversionValue':  100,
                });

                event?.complete('success');
            })
            .then(() => {
                return pageConfiguration.handleOnPageComplete();
            })
            .catch((e: any) => {
                console.log(e);

                pageConfiguration?.event('OnboardingCheckoutStartClicked', {
                    value: planCode?.key,
                    source: paymentMethod,
                    status: e?.statusCode + ' ' + e?.error
                });

                event?.complete('fail');

                if (paymentMethod === PAYMENT_METHODS.creditCard) {
                    setError('');
                    setCardError(e?.error);
                } else {
                    setError(e?.error);
                    setCardError('');
                }
            })
            .finally(() => {
                setLoadingRequest(false);
            });
    };

    const onSubmitPayPal = ({ nonce: braintreeToken, details: billingContact }: any) => {
        const { shippingAddress, firstName, lastName } = billingContact;
        const data = getSignUpData({
            billingAddress: {
                country: shippingAddress.countryCode,
                postalCode: shippingAddress.postalCode,
                stateOrProvince: shippingAddress.state,
                line1: shippingAddress.line1,
                city: shippingAddress.city,
            },
            planCode: planCode?.key,
            braintreeToken,
            advertisingID: localStorage.getItem('userID'),
            paymentMethod: {
                type: 'paypal_express_checkout',
            },
            name: `${firstName} ${lastName}`,
            kountSessionID: localStorage.getItem('kount_session_id'),
            email,
        });

        return PaymentSignUp(PAYMENT_METHODS.payPal, data);
    };

    const onSubmitCard = ({ token, name, postalCode }: any) => {
        const data = getSignUpData({
            name,
            billingAddress: { country: countryCode, postalCode },
            planCode: planCode?.key,
            advertisingID: localStorage.getItem('userID'),
            checkoutToken: token,
            kountSessionID: localStorage.getItem('kount_session_id')
        });

        return PaymentSignUp(PAYMENT_METHODS.creditCard, data);
    };

    const onSubmitApplePay = ({ event, price }: any) => {
        const tokenObj = event.token;

        const data = getSignUpData({
            billingAddress: {
                stateOrProvince: tokenObj.card?.address_state || '',
                country: tokenObj.card?.address_country || '',
                postalCode: tokenObj.card?.address_zip || '',
                city: tokenObj.card?.address_city || '',
                line1: tokenObj.card?.address_line1 || '',
                line2: tokenObj.card?.address_line2 || '',
            },
            planCode: planCode?.key,
            amount: price,
            advertisingID: localStorage.getItem('userID'),
            applePayStripeToken: tokenObj.id,
            name: tokenObj.card?.name,
            stripePublicKey: localStorage.getItem('stripePublishableKey'),
            kountSessionID: localStorage.getItem('kount_session_id'),
            currency: 'USD'
        }, true);

        return PaymentSignUp(PAYMENT_METHODS.applePay, data, event);
    };

    const onSubmitGooglePay = ({ event }: any) => {
        const tokenObj = event.token;
        const data = getSignUpData({
            billingAddress: {
                stateOrProvince: tokenObj.card?.address_state || '',
                country: tokenObj.card?.address_country || '',
                postalCode: tokenObj.card?.address_zip || '',
                city: tokenObj.card?.address_city || '',
                line1: tokenObj.card?.address_line1 || '',
                line2: tokenObj.card?.address_line2 || '',
            },
            planCode: planCode?.key,
            advertisingID: localStorage.getItem('userID'),
            stripeToken: tokenObj.id,
            name: tokenObj.card?.name,
            stripePublicKey: localStorage.getItem('stripePublishableKey'),
            kountSessionID: localStorage.getItem('kount_session_id'),
            currency: 'USD'
        }, true);

        return PaymentSignUp(PAYMENT_METHODS.googlePay, data, event);
    };

    const submitter = {
        applePay: onSubmitApplePay,
        googlePay: onSubmitGooglePay,
        card: onSubmitCard,
        payPal: onSubmitPayPal,
    };

    const handleSubmit = ({ type, ...props }: any) => {
        if(type !== 'card') {
            setLoadingRequest(true);
        }

        pageConfiguration.event('OnboardingSubscriptionPaymentMethodSource',  { Source: type });

        // @ts-ignore
        return submitter[type](props)
    };

    const handleClickAmazonButton = () => {};

    const modalCheckoutWindowTriggered = (state: boolean) => {
        if (state) {
            pageConfiguration.event('OnboardingSubscriptionUseCreditcardClicked');
        }
    };

    return {
        error,
        cardError,
        loadingRequest,
        handleSubmit,
        handleClickAmazonButton,
        modalCheckoutWindowTriggered
    }
};
