import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const WellnessGoal = () => {
    const { pageValue: wellnessGoal, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.WellnessGoal],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'run-5k', value: 'Run a 5K' },
        { key: 'more-confident', value: 'Feel more confident in my body' },
        { key: 'healthier-routine', value: 'Build a healthier routine' },
        { key: 'more-energy', value: 'Enjoy more energy' },
        { key: 'other', value: 'Other' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                In addition to your goal weight, what else would you like to achieve?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == wellnessGoal}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
