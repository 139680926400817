import React, { useContext } from 'react';

import { Typography } from '../../components/typography';

import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import { RatingType } from '../../components/rating-button';
import { RatingScale } from '../../components/rating-scale';
import { RoutePath } from '../../routes/route-path.constant';

export const FeelFailure = () => {
    const { pageValue: feelFailure, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.FeelFailure],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (rating: RatingType) => {
        setPageValue(rating.key);

        pageConfiguration.handleOnPageComplete(rating.value);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                Do you relate to the following:
            </Typography>
            <Typography variant={TypographyVariants.h2}>
                “Sometimes I feel like a failure, because  one unhealthy decision leads to another.”
            </Typography>

            <RatingScale value={feelFailure} handleClick={handleClick}/>
        </>
    );
}
