
import { DEFAULT_PAGES_CONFIGURATION, PageConfigurationType } from './defautPagesConfiguration';
import { RoutePath } from '../routes/route-path.constant';

export const ABPagesConfiguration = (layoutVariation?: string | null) => {

    if (layoutVariation == '1') {
        return Object.assign({}, DEFAULT_PAGES_CONFIGURATION, {
            [RoutePath.BodyGender]: {
                ...DEFAULT_PAGES_CONFIGURATION[RoutePath.BodyGender],
                route: {
                    next: () => RoutePath.ConsumedItems,
                },
            },
            [RoutePath.ConsumedItems]: {
                ...DEFAULT_PAGES_CONFIGURATION[RoutePath.ConsumedItems],
                route: {
                    next: () => RoutePath.BodyHeightWeight,
                },
            },
            [RoutePath.BodyHeightWeight]: {
                ...DEFAULT_PAGES_CONFIGURATION[RoutePath.Email],
                route: {
                    next: () => RoutePath.BodyAge,
                },
            },
            [RoutePath.HappyWeight]: {
                ...DEFAULT_PAGES_CONFIGURATION[RoutePath.HappyWeight],
                header: {
                    email: true,
                    logo: false,
                    percents: 50,
                },
                route: {
                    next: () => RoutePath.BodyGender,
                },
                events: {
                    onLeave: () => 'happy-weight',
                }
            },
        }) as PageConfigurationType;
    }

    return DEFAULT_PAGES_CONFIGURATION;
};
