import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { BaseContext } from '../../context/base.context';

import { CouponsApiProvider } from '../../api/coupons.api';

import { RoutePath } from '../../routes/route-path.constant';
import { LocalStorageKeys } from '../../constants';

const CURRENCY = 'USD';

type UseAddonProps = {
    prefix: string,
    prefixDiscount: string
}

export const useAddon = ({ prefix, prefixDiscount }: UseAddonProps) => {
    const navigate = useNavigate();
    const { pageConfiguration } = useContext(BaseContext);

    const [isDiscount, setIsDiscount] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]) || '';

    const handleClick = (isDiscountValue: boolean) => {
        pageConfiguration.handleOnPageComplete(isDiscountValue);
    };

    useEffect(() => {
        const newIsDiscount = window.location.pathname.includes(RoutePath.AddonsDiscount);

        setIsDiscount(newIsDiscount);
        setOpen(newIsDiscount);
    }, [navigate]);

    const onSubmit = () => {
        const longPrefix = isDiscount ? `_discount${prefixDiscount}` : prefix;
        const addon = `custom_meal_self_care_pr_bundle${longPrefix}`;
        const charge = `${addon}-${CURRENCY}`;
        setLoading(true);

        CouponsApiProvider
            .upgradeAddon({ charge, email })
            .then(() => {
                const eventName = isDiscount ? 'OnboardingAddonsDiscountAddClicked' : 'OnboardingAddonsAddClicked';

                pageConfiguration.event(eventName, { value: addon, status: 200 });

                handleClick(isDiscount);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClose = () => {
        pageConfiguration.event('OnboardingAblePlanBenefitsContinueClicked');
        setOpen(false);
    };

    const skipAddons = () => {
        setOpen(true);

        pageConfiguration.event('OnboardingAddonsSkipTopClicked');

        handleClick(true);
    };

    const skipAddonsDiscount = () => {
        pageConfiguration.event('OnboardingAddonsDiscountSkipTopClicked');

        handleClick(false);
    };

    const skip = isDiscount ? skipAddonsDiscount : skipAddons;


    return {
        open,
        loading,
        isDiscount,
        skip,
        onSubmit,
        handleClose,
    };
};
