import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    goalTitleClass: {
        marginTop: '32px',
        marginBottom: '16px',
        textAlign: 'left',
    },
    goalTextClass: {
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#202B47',
        margin: '6px 0 6px',
    },
    dateTextClass: {
        fontFamily: 'SF Pro Text',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#000000',
        margin: '6px 0 16px',
    },
    byDateTextClass: {
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'lowercase',
    }
});
