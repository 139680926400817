import { useEffect } from 'react';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';

export const Timer = (props: { initialMinute: number, initialSeconds: number, endOfTimerHandler: () => void }) => {
    const {
        initialMinute = 0,
        initialSeconds = 0,
        endOfTimerHandler = () => {}
    } = props;

    const { pageValue: minutes, setPageValue: setMinutes } = useLocalStorage({
        key: 'subscribe-timer-minutes',
        defaultValue: initialMinute,
    });

    const { pageValue: seconds, setPageValue: setSeconds } = useLocalStorage({
        key: 'subscribe-timer-seconds',
        defaultValue: initialSeconds,
    });

    useEffect(() => {
        let timerInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(timerInterval);
                    endOfTimerHandler();
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(timerInterval);
        };
    });

    return (
        <>
            { minutes === 0 && seconds === 0
                ? <span>00:00</span>
                : <span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
            }
        </>
    )
}
