import * as React from 'react';


import { useStyles } from './styles';
import {ChoiceButton} from '../choice-button';
import {Checkbox} from '../checkbox';
import {Typography} from '../typography';
import {TypographyVariants} from '../../constants';
import {PreloadImageServiceInstanceCTX} from '../../services/preload-image.service';
import {useContext, useEffect, useState} from 'react';

export type MultiChoiceType = { key: string, value: string, image?: string };

export const MultiChoiceButton = ({item, pageValue, setPageValue}: any) => {
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);

    const { multiChoiceButton, multiChoiceButtonSelected, imageClass } = useStyles();

    const [image, setImage] = useState('');

    // Remove item or add
    const buttonClicked = () => {
        let newPageValue = [...pageValue];

        //@description: Deselect all values or remove 'None' value;
        if (item.key === 'none') {
            newPageValue = [];
        } else {
            //@TODO: Think about it. Hack for now
            newPageValue = pageValue.filter((i: string) => i !== 'None' && i !== 'None of the above');
        }

        if (pageValue.includes(item.value)) {
            newPageValue = pageValue.filter((i: string) => i !== item.value);
        } else {
            newPageValue.push(item.value);
        }

        setPageValue(newPageValue);
    };


    useEffect(() => {

        (async () => {
            if (item?.image) {
                const img = await getImageByLocalPath(item?.image);
                setImage(img as string);
            }
        })();

    }, [item.image, getImageByLocalPath]);

    const selectedItem = pageValue.includes(item.value);

    let combinedMultiChoiceButtonClass = `${multiChoiceButton} `;

    if (selectedItem) {
        combinedMultiChoiceButtonClass += `${multiChoiceButtonSelected}`;
    }

    return (
        <ChoiceButton
            selected={selectedItem}
            className={combinedMultiChoiceButtonClass}
            typographyVariant={TypographyVariants.multiChoiceButton}
            onClick={buttonClicked}>

            <Checkbox checked={selectedItem} />

            <Typography variant={TypographyVariants.multiChoiceButton}>
                {item.value}
            </Typography>

            {image && <img className={imageClass} src={image} alt={item.value}/>}

        </ChoiceButton>
    );
};
