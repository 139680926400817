import { Palette } from '../index';

export type ColoredPageType = {
    coloredPageWrapper: {
        display: string,
        alignItems: string,
        flexDirection: string,
        background: Palette | string,
        position: string,
        height: string,
        width: string,
        padding: string,
        overflowY: string,
    },
    coloredPageIcon: {
        margin: string,
    },
}

export const ColoredPageStyles: ColoredPageType = {
    coloredPageWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        background: '#4B46EC',
        position: 'absolute',
        height: 'calc(100vh - 52px)', // @description: 52px - header height
        width: '100vw',
        padding: '0 16px',
        overflowY: 'scroll',
    },
    coloredPageIcon: {
        margin: '48px 0',
    },
};
