import React, { useContext } from 'react';

import { BaseContext } from '../../context/base.context';

import { TypographyVariants } from '../../constants';
import { Typography } from '../../components/typography';

import logo2 from '../../assets/images/svg/logo-black.svg';

import { useStyles } from './styles';


export const YouAreAble = () => {
    const { animationPageWrapper, textWrapper, animatedText, first, second, third, dot } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
            <div className={animationPageWrapper}>
                <img src={logo2} alt='' />
                <div className={textWrapper}>
                    <Typography onAnimationStart={() => pageConfiguration.event('OnboardingFinisher2Viewed')} className={`${animatedText} ${first}`} variant={TypographyVariants.h1}>
                        You.
                    </Typography>
                    <Typography onAnimationStart={() => pageConfiguration.event('OnboardingFinisher3Viewed')} className={`${animatedText} ${second}`} variant={TypographyVariants.h1}>
                        Are.
                    </Typography>
                    <Typography onAnimationEnd={() => handleClick()} className={`${animatedText} ${third}`} variant={TypographyVariants.h1}>
                        Able<span className={dot}>.</span>
                    </Typography>
                </div>
            </div>
    )
};
