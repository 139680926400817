import React from 'react';
import { useStyles } from './styles';

interface Props {
    height?: number;
    width?: number;
}

export const DottedLoader = ({ height = 40, width = 40 }: Props) => {
    const style = {
        width: width ? `${width}px` : 'auto',
        height: height ? `${height}px` : 'auto',
    };
    const { dottedLoader } = useStyles();

    return (
        <div className={dottedLoader} style={style}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};
