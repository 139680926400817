// node modules
import React, { FC } from 'react';

import checkIcon from '../../../assets/images/svg/check-icon.svg';
import closeIcon from '../../../assets/images/svg/close.svg';

import { Typography } from '../../../components/typography';
import { TypographyVariants } from '../../../constants';
import { NextButton } from '../../../components/next-button';

import { useStyles } from './styles';

export interface PriceBlockProps {
    btnLabel: any;
    active: boolean;
    price: string | number;
    term?: string;
    priceText?: string;
    savingPrice: string | number;
    trialTitle: string;
    onClick?: () => void;
    currency?: string;
    className?: string;
    trialIcon?: boolean;
    totalSavingText?: string;
    label?: string;
    translation?: {
        price_block_yes?: string,
        price_block_no?: string
    }
}

export const PriceBlock: FC<PriceBlockProps> = ({
                                                    totalSavingText,
                                                    btnLabel,
                                                    active,
                                                    price,
                                                    term,
                                                    priceText,
                                                    savingPrice,
                                                    trialTitle,
                                                    currency = '$',
                                                    className = '',
                                                    onClick = () => {
                                                    },
                                                    trialIcon = true,
                                                    label = 'POPULAR',
                                                    translation,
                                                }) => {
    const {
        wrapper,
        icon,
        content,
        btn,
        activeBtn,
        activeImg,
        typClass,
        priceWrapper,
        priceWrapperActive,
        wrapperActive,
        activePopUp,
        priceTextStyle,
        totalSavingTitle,
    } = useStyles();

    return (
        <div className={`${wrapper} ${active ? wrapperActive : ''} ${className}`}>
            <div className={content}>
                <Typography className={`${priceWrapper} ${active ? priceWrapperActive : ''}`}
                            variant={TypographyVariants.h1}>
                    {currency}{price}
                    {term && <Typography variant={TypographyVariants.h2}>/{term}</Typography>}
                </Typography>
                {priceText &&
                <Typography className={priceTextStyle} variant={TypographyVariants.h2}> {priceText}</Typography>}
                <Typography className={typClass}
                            variant={TypographyVariants.h4}>{totalSavingText || 'Total savings'}</Typography>
                <Typography className={totalSavingTitle} variant={TypographyVariants.h2}>
                    {currency}{savingPrice}
                </Typography>
                <Typography className={typClass} variant={TypographyVariants.h4}>{trialTitle}</Typography>
                {
                    trialIcon
                        ? <img className={`${icon} ${active ? activeImg : ''}`} src={active ? closeIcon : checkIcon}
                               alt='' />
                        : <Typography className={priceTextStyle} variant={TypographyVariants.h2}>
                            {active ? translation?.price_block_no || 'no' : translation?.price_block_yes || 'yes'}
                        </Typography>
                }
                <NextButton onClick={onClick} className={`${btn} ${active ? activeBtn : ''}`}
                            typographyText={btnLabel} />
            </div>
            {active && (
                <Typography className={activePopUp} variant={TypographyVariants.label}>
                    {label}
                </Typography>
            )}
        </div>
    );
};
