import React, { useContext } from 'react';

import { BaseContext } from '../../context/base.context';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { WeightChart } from '../../components/charts/weight-chart';

import { TypographyVariants } from '../../constants';

import { useBodyPlanData } from '../../hooks/useBodyPlanData';

import { useStyles } from './styles';

export const GoalChart = () => {
    const { text, goalTitleClass, goalTextClass } = useStyles();
    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const { weightPlan, monthsPlan, goal, unit, lossDay, lossMonth } = useBodyPlanData();

    return (
        <>
            <Typography variant={TypographyVariants.h3} className={goalTitleClass}>
                We predict you’ll be
            </Typography>

            <Typography variant={TypographyVariants.span} className={goalTextClass}>
                {`${weightPlan[weightPlan.length - 1]} ${unit} by ${lossMonth}, ${lossDay}`}
            </Typography>

            <WeightChart weightPlan={weightPlan} monthsPlan={monthsPlan} goal={goal} unit={unit} />

            <Typography variant={TypographyVariants.h2} className={text}>
                Great news! Based on Able users like you, we predict you’ll be able to conservatively hit your weight loss goal by {lossMonth}, {lossDay}.
                <br/>
                <br/>
                Now, let’s see if you can reach your goal even sooner. Tell us a bit more about yourself and your lifestyle.
            </Typography>

            <NextButton onClick={handleClick} />
        </>
    )
};
