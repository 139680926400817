import { useCallback, useContext, useEffect, useState } from 'react';

import { BaseContext } from '../../context/base.context';

import { useLoadingProgress } from '../../hooks/useLoadingProgress';

import { LocalStorageKeys, TypographyVariants } from '../../constants';
import { RoutePath } from '../../routes/route-path.constant';
import { AGES } from '../../constants/body-age';

import { Typography } from '../../components/typography';
import { ProgressBar } from '../../components/progress-bar';

import { useStyles } from './styles';
import { PreloadImageServiceInstanceCTX } from '../../services/preload-image.service';

export const HelpedThousands = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);
    const [pageImages, setPageImages] = useState<string[]>([]);
    const { customerReviewWrapper, customerReview, jeansImg, userAvatar, name, reviewText, text2, bar } = useStyles();
    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) === 'female' ? 'women' : 'men';
    const bodyAge = localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge]);
    const userAge = AGES.find((age) => age.key === bodyAge)?.value?.toLowerCase();

    const handleClick = useCallback(() => {
        pageConfiguration.handleOnPageComplete();
    }, []);

    const { progress } = useLoadingProgress({
        onDone: handleClick,
    });

    const items = ['webp/testimonials/testimonial-image.webp', 'webp/testimonials/jeans.webp'];

    useEffect(() => {
        let images: string[] = [];

        items.map(item => {
            (async () => {
                if (item) {
                    const img = await getImageByLocalPath(item);
                    images.push(img as string);
                    setPageImages(images);
                }
            })();
        });
    }, [getImageByLocalPath]);


    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                We’ve helped thousands of {userGender} in their {userAge} to lose weight!
            </Typography>

            <div className={customerReviewWrapper}>
                <img src={pageImages[0]} className={userAvatar} height={64} width={64} alt='nicole' />
                <div className={customerReview}>
                    <Typography variant={TypographyVariants.h3} className={reviewText}>
                        “I can finally fit back into my favorite jeans! Thank you Able :)”
                    </Typography>
                    <Typography variant={TypographyVariants.h3} className={name}>
                        Nicole S.
                    </Typography>
                </div>
            </div>

            <img src={pageImages[1]} className={jeansImg} alt='jeans' />

            <br />
            <Typography variant={TypographyVariants.p} className={text2}>
                Analyzing responses...
            </Typography>

            <ProgressBar progress={progress} className={bar} />

            <br/>
        </>
    );
};
