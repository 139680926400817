import { AnalyticsService } from './analytics.service';

import { PageType } from './defautPagesConfiguration';

import { ABPagesConfiguration } from './ABPagesConfiguration';
import { RoutePath } from '../routes/route-path.constant';

export class PageConfigurationService extends AnalyticsService {

    // @ts-ignore
    private page: PageType;

    private navigate: any = {};

    constructor() {
        super();
    }

    private onPageComplete = (data: {} | string | undefined) => {

        if (this.page.events.onLeave) {
            const name = this.page.events.onLeave(data);
            this.event(name, data);
        }

        setTimeout(() => {
            return this.navigate(`..${this.page.route.next(data)}`);
        }, 100);
    };

    setNavigate(navigate: any) {
        this.navigate = navigate;
        return this;
    }

    getPageConfiguration() {
        return this.page;
    }

    handleOnPageEnter() {
        if (this.page.events.onEnter) {
            this.onEnterEvent(this.page.events.onEnter());
        }

        return this;
    }

    handleOnPageComplete(data?: {} | string) {
        return this.onPageComplete(data);
    }

    setConfigurationByLocation(location: any) {
        const layoutVariation = localStorage.getItem("layoutVariation");

        let page = ABPagesConfiguration(layoutVariation)[location.pathname];

        if (!page) {
            console.error(`Failed to take page configuration for location.pathname = ${location.pathname}`);
            page = ABPagesConfiguration(layoutVariation)[RoutePath.MainGoal];
        }

        this.page = page;

        return this;
    }
}
