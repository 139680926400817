import { createUseStyles } from 'react-jss';
import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ animatedPage }: ThemeProps) => ({
    animationPageWrapper: {
        ...animatedPage.animationPageWrapper,
    },
    textWrapper: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    animatedText: {
        fontWeight: 400,
        fontSize: '2.5rem',
        opacity: 0,
        padding: '8px',

            '&$first': {
                animation: '$animationFirst 6s 1 0s',
            },
            '&$second': {
                animation: '$animationSecond 6s 1 1.5s',
            },
            '&$third': {
                color: '#FE8F20',
                animation: '$animationThird 3s 1 3s',
            },
    },
    dot: {
        color: '#202B47'
    },
    '@keyframes animationFirst': {
        '0%': {
            opacity: 0,
        },
        '25%': {
            opacity: 1,
        },
        '75%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },

    },
    '@keyframes animationSecond': {
        '0%': {
            opacity: 0,
        },
        '25%': {
            opacity: 1,
        },
        '50%': {
            opacity: 1,
        },
        '75%': {
            opacity: 0,
        },
        '100%': {
            opacity: 0,
        },

    },
    '@keyframes animationThird': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },

    },
    first: {},
    second: {},
    third: {}
}));
