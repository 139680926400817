import React from "react";

import { Typography } from '../../../../components/typography';
import { TypographyVariants } from '../../../../constants';

import starIcon from '../../../../assets/images/svg/subscribe/reviews/star.svg';
import darkStarIcon from '../../../../assets/images/svg/subscribe/reviews/dark-star.svg';

import { useStyles } from "./styles";

export const Review = ({ avatar, name, review }: any) => {
    const {
        wrapper, typ, header, customerImg, typ2,
        contentWrapper, customReviewRecommended, comment, starBlock
    } = useStyles();

    return (
        <div className={wrapper}>
            <div className={header}>
                <img className={customerImg} src={avatar} alt='customer' />
                <div className={contentWrapper}>
                    <div className={typ}>
                        {name}
                        <div className={starBlock}>
                            <img src={starIcon} alt="" />
                            <img src={starIcon} alt="" />
                            <img src={starIcon} alt="" />
                            <img src={starIcon} alt="" />
                            <img src={name === 'Michael' ? darkStarIcon : starIcon} alt="" />
                        </div>
                    </div>

                    <div className={customReviewRecommended}>
                        <Typography className={typ2} variant={TypographyVariants.h3}>
                            recommends Able
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography className={comment} variant={TypographyVariants.h3}>
                {review}
            </Typography>
        </div>
    )
}
