import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const EatTimes = () => {
    const { pageValue: eatTimes, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.EatTimes],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: '2-less-times', value: '2 or less times a day' },
        { key: '3-times', value: '3 times a day' },
        { key: '4-times', value: '4 times a day' },
        { key: '5-more-times', value: '5 or more times a day' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                How many times per day
                do you usually eat?
            </Typography>
            <Typography variant={TypographyVariants.h2}>
                In addition to what you eat, how often you eat can impact your metabolism.
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == eatTimes}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
