import {HeaderStyles} from './header';

export type ProgressBarType = {
    wrapperBar: {
        position: string,
        height: number,
        background: string,
        top: string | number,
    },
    progressBar: {
        borderRadius: '0px 8px 8px 0px',
        background: string,
    }
}

export const ProgressBarStyles: ProgressBarType = {
    wrapperBar: {
        position: HeaderStyles.header.position,
        height: 4,
        background: "#E0E3EB",
        top: HeaderStyles.header.position === 'sticky' ? HeaderStyles.header.height : '0px'
    },
    progressBar: {
        borderRadius: '0px 8px 8px 0px',
        background: "#FE8F20",
    }
};
