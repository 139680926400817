import React from 'react';

import { GoalChartPopover } from '../chart-popover';

import { useStyles } from './styles';

export const GoalChartGetFit = ({ unit, weightPlan, monthsPlan }: { unit: string, weightPlan: number[], monthsPlan: string[] }) => {
    const { numbersClass, monthClass, weightGraphic, popoverWrapper } = useStyles();

    return (
        <>
            <div className={weightGraphic}>

                <GoalChartPopover weight={weightPlan[weightPlan.length - 1]} unit={unit} popoverWrapperClass={popoverWrapper} />

                <svg width="100%" height="100%" viewBox="0 0 360 215" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <g>
                        <path strokeWidth="2" stroke="#E0E3EB" d="m1.90317,212.92857"/>
                        <path stroke="null" fill="#E0E3EB" d="m0.58863,191.32896c109.85085,5.54168 173.37912,-31.70359 250.12664,-96.50748c34.40612,-33.67016 72.16652,-79.82148 109.39516,-79.82148l0,200.2613l-359.5218,0l0,-23.93233l0,-0.00001" opacity="0.3"/>
                        <path stroke="#36B992" className="weightGraphicPath" strokeWidth="3" d="m1.0508,193.5603c109.35533,5.68285 179.47848,-35.36895 248.99755,-98.97933c34.3409,-31.42243 70.2521,-80.72395 108.9009,-80.72395"/>
                        <path stroke="#E0E3EB" d="m0.07794,213.82583l360.066,0" strokeWidth="2"/>
                    </g>
                </svg>

                <div className={numbersClass}>
                    {
                        weightPlan.map((weight) => (<span key={weight}>{weight}</span>))
                    }
                </div>

            </div>

            <div className={monthClass}>
                {
                    monthsPlan.map((month) => (<span key={month}>{month}</span>))
                }
            </div>
        </>
    )
};
