import { createUseStyles } from 'react-jss';
import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ animatedPage }: ThemeProps) => ({
    animationPageWrapper: {
        ...animatedPage.animationPageWrapper,
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    animatedText: {
        fontWeight: 400,
        fontSize: '24px',
        opacity: 0,

            '&$first': {
                animation: '$animation 0.75s ease-out 1s forwards'
            },
            '&$second': {
                animation: '$animation 0.75s ease-out 3s forwards'
            },
            '&$third': {
                animation: '$animation 2s ease-out 5s forwards'
            },
            '&$forth': {
                animation: '$animation 2s ease-out 7s forwards'
            },
    },
    '@keyframes animation': {
        '100%': {
            opacity: 1
        }
    },
    orangeText: {
        color: '#FE8F20'
    },
    first: {},
    second: {},
    third: {},
    forth: {}
}));
