import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';
import { AGES } from '../../constants/body-age';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const DietActivity = () => {

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: dietActivity, setPageValue }: any = useLocalStorage({ key: LocalStorageKeys[RoutePath.DietActivity], defaultValue: '' });

    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) === 'female' ? 'Women' : 'Men';

    const bodyAge = localStorage.getItem(LocalStorageKeys[RoutePath.BodyAge] as string);
    const userAge = AGES.find((age) => age.key === bodyAge)?.value?.toLowerCase();

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'need-work', value: 'My diet and activity need a lot of work' },
        { key: 'some-healthy-habits', value: 'I have some healthy habits' },
        { key: 'eat-well-stay-active', value: 'I mostly eat well and stay active' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {userGender} in their {userAge} typically need a weight loss strategy designed for their lifestyle.
            </Typography>

            <Typography variant={TypographyVariants.h2}>
                Which best describes you?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key === dietActivity}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    )
};
