// node modules
import { createUseStyles } from 'react-jss'

import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ nextButton }: ThemeProps) => ({
    nextButton: {
        ...nextButton.nextButton,
        '&:hover:enabled': {
            ...nextButton.nextButtonHovered,
        },
        '&:focus:enabled': {
            ...nextButton.nextButtonFocus,
        }
    },
}));
