import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const GlassesWater = () => {
    const { pageValue: glassesWater, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.GlassesWater],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'do-not-drink-any', value: 'I don’t drink any' },
        { key: '1-4-glasses', value: '1-4' },
        { key: '4-8-glasses', value: '4-8' },
        { key: '8-more-glasses', value: 'More than 8' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                How many glasses of water do you drink per day?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == glassesWater}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
