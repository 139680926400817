import React, {useContext} from 'react';

import { BaseContext } from '../../context/base.context';
import { useLocalStorage } from '../../hooks/useLocalStorage';

import { RoutePath } from '../../routes/route-path.constant';
import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import { AGES } from '../../constants/body-age';

export const BodyAge = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: bodyAge, setPageValue }: any = useLocalStorage({ key: LocalStorageKeys[RoutePath.BodyAge], defaultValue: '' });

    const handleClick = (age: ChoiceType) => {
        setPageValue(age.key);

        pageConfiguration.handleOnPageComplete(age.value);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                What is your age?
            </Typography>

            {
                AGES.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == bodyAge}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    )
};
