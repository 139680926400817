import {createUseStyles} from "react-jss";
import {ThemeProps} from '../../../themes';

export const useStyles = createUseStyles(({ header }: ThemeProps) => ({
    header: {
        zIndex: 2,
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: header.header.height,
        left: '0px',
        background: header.header.background,
        position: header.header.position,
    },
    backButtonWrapper: {
        flex: 1,
    },
    backButtonIconInnerWrapper: {
        width: '50px',
    },
    backButton: {
        width: 14,
        height: 14,
        marginLeft: '16px',
    },
}));
