export const RoutePath = {
    MainGoal: '/',
    BodyGender: '/body-gender',
    BodyAge: '/body-age',
    BodyType: '/body-type',
    WeightLoss: '/weight-loss',
    BodyBreastfeeding: '/body-breastfeeding',
    BodyRisk: '/body-risk',
    OurMission: '/our-mission',
    WeightGoal: '/weight-goal',
    HappyWeight: '/happy-weight',
    LoseWeight: '/lose-weight',
    TypicalDay: '/typical-day',
    AchieveGoal: '/achieve-goal',
    NewsOffer: '/news-offer',
    GoalChart: '/goal-chart',
    DietActivity: '/diet-activity',
    LifestyleAdvice: '/lifestyle-advice',
    WeightGain: '/weight-gain',
    PastTrials: '/past-trials',
    PersonalJourney: '/personal-journey',
    CurrentLifestyle: '/current-lifestyle',
    HealthyFridge: '/healthy-fridge',
    ConsumedItems: '/consumed-items',
    EatTimes: '/eat-times',
    PlanFits: '/plan-fits',
    AverageBusy: '/average-busy',
    SmallChanges: '/small-changes',
    WorkoutTimes: '/workout-times',
    WithoutWorkout: '/without-workout',
    MovementImportant: '/movement-important',
    GlassesWater: '/glasses-water',
    SleepHours: '/sleep-hours',
    SleepGameChanger: '/sleep-game-changer',
    HowMotivated: '/how-motivated',
    OutsideMotivation: '/outside-motivation',
    BodyAndMind: '/body-and-mind',
    OldHabits: '/old-habits',
    PeopleAround: '/people-around',
    FeelFailure: '/feel-failure',
    UpsAndDown: '/ups-and-down',
    WellnessGoal: '/wellness-goal',
    RealisticTargets: '/realistic-targets',
    HearSource: '/hear-source',
    BodyHeightWeight: '/body-height-weight',
    BodyIdealWeight: '/body-ideal-weight',
    HelpedThousands: '/helped-thousands',
    Testimonials: '/testimonials',
    Email: '/email',
    HitGoalChart: '/hit-goal-chart',
    ProgramBuilder: '/program-builder',
    YouAreAble: '/you-are-able',
    PersonalizedProgram: '/personalized-program',
    TrialPay: '/trial-pay',
    Subscribe: '/subscribe',
    Payment: '/payment',
    Congrats: '/congrats',
    SkipTrial: '/skip-trial',
    Addons: '/addons',
    AddonsDiscount: '/addons-discount',
    Wait: '/wait',
    AccessAccount: '/access-account',
};
