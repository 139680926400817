import { useContext, useEffect, useState } from 'react';

import { BaseContext } from '../../context/base.context';
import { LocalStorageKeys, TypographyVariants } from '../../constants';
import { RoutePath } from '../../routes/route-path.constant';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { QuoteIcon } from './quote-icon';

import { useStyles } from './styles';
import { PreloadImageServiceInstanceCTX } from '../../services/preload-image.service';


export const Testimonials = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);
    const [pageImages, setPageImages] = useState<string[]>([]);
    const { container, testimonialImg, testimonialText } = useStyles();
    const userGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete([]);
    };

    const items = ['webp/testimonials/answer-female.webp', 'webp/testimonials/answer-male.webp'];

    useEffect(() => {
        let images: string[] = [];

        items.map(item => {
            (async () => {
                if (item) {
                    const img = await getImageByLocalPath(item);
                    images.push(img as string);
                    setPageImages(images);
                }
            })();
        });
    }, [getImageByLocalPath]);

    let text = 'Sticking to a healthy routine can be hard, but Able makes it do-able.';
    if (userGender === 'female') {
        text = 'Sticking to a healthy routine can be hard, but Able makes it do-easy.';
    }

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                {text}
            </Typography>

            <div className={container}>
                <img
                    src={userGender === 'female' ? pageImages[0] : pageImages[1]}
                    alt={userGender === 'female' ? 'Woman' : 'Man'}
                    className={testimonialImg}
                />
                <div>
                    <Typography variant={TypographyVariants.p} className={testimonialText}>
                        "I can turn to my Able app for support, anytime, anywhere."
                        <QuoteIcon />
                    </Typography>
                </div>
            </div>

            <br />

            <NextButton typographyText='Got it!' onClick={handleClick} />
        </>
    );
};
