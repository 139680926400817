import React, { useContext } from 'react';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { BaseContext } from '../../context/base.context';

import { RoutePath } from '../../routes/route-path.constant';

import { useLocalStorage } from '../../hooks/useLocalStorage';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

export const CurrentLifestyle = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: currentLifestyle, setPageValue }: any = useLocalStorage({ key: LocalStorageKeys[RoutePath.CurrentLifestyle], defaultValue: '' });

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'school', value: 'I\'m in school and my schedule changes a lot.' },
        { key: 'career', value: 'I have a career that takes a lot of my focus.' },
        { key: 'parent', value: 'I\'m a parent with a busy schedule.' },
        { key: 'none', value: 'None of these feel quite like me.' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                When it comes to your lifestyle,<br/>
                which sounds the most like you?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == currentLifestyle}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
