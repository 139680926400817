import { RoutePath } from '../routes/route-path.constant';
import { LocalStorageKeys } from '../constants';

export type PageType = {
    customWrapper?: boolean,
    scrollableContent?: boolean,
    header: {
        email: boolean,
        logo: boolean,
        progress: {
            percents: number | null,
            label: string | null
        } | null
    },
    route: {
        next: (data?: any) => string,
    },
    events: {
        onEnter?: (data?: any) => string,
        onLeave?: (data?: any) => string,
    }
};

export type PageConfigurationType = {
    [key: string]: PageType;
};

export const DEFAULT_PAGES_CONFIGURATION: PageConfigurationType = {
    [RoutePath.MainGoal]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyGender,
        },
        events: {
            onEnter: () => 'OnboardingStartViewed',
            onLeave: () => 'OnboardingStartClicked'
        }
    },
    [RoutePath.BodyGender]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 10,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyAge,
        },
        events: {
            onEnter: () => 'OnboardingGenderViewed',
            onLeave: () => 'OnboardingGenderClicked',
        }
    },
    [RoutePath.BodyAge]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 20,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyType,
        },
        events: {
            onEnter: () => 'OnboardingAgeViewed',
            onLeave: () => 'OnboardingAgeClicked',
        }
    },
    [RoutePath.BodyType]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 25,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WeightLoss,
        },
        events: {
            onEnter: () => 'OnboardingBodyTypeViewed',
            onLeave: () => 'OnboardingBodyTypeClicked',
        }
    },
    [RoutePath.WeightLoss]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null,
        },
        route: {
            next: () => localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]) === 'female' ? RoutePath.BodyBreastfeeding : RoutePath.BodyRisk,
        },
        events: {
            onEnter: () => 'OnboardingReinRestrictiveDietViewed',
            onLeave: () => 'OnboardingReinRestrictiveDietClicked',
        }
    },
    [RoutePath.BodyBreastfeeding]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 30,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyRisk,
        },
        events: {
            onEnter: () => 'OnboardingPregnantViewed',
            onLeave: () => 'OnboardingPregnantClicked',
        }
    },
    [RoutePath.BodyRisk]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 35,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.OurMission,
        },
        events: {
            onEnter: () => 'OnboardingRisksViewed',
            onLeave: () => 'OnboardingRisksClicked',
        }
    },
    [RoutePath.OurMission]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.BodyHeightWeight,
        },
        events: {
            onEnter: () => 'OnboardingReinSharingViewed',
            onLeave: () => 'OnboardingReinSharingClicked',
        }
    },
    [RoutePath.BodyHeightWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 40,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyIdealWeight,
        },
        events: {
            onEnter: () => 'OnboardingHeightWeightViewed',
            onLeave: () => 'OnboardingHeightWeightClicked'
        }
    },
    [RoutePath.BodyIdealWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 45,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.WeightGoal,
        },
        events: {
            onEnter: () => 'OnboardingGoalWeightViewed',
            onLeave: () => 'OnboardingGoalWeightClicked'
        }
    },
    [RoutePath.WeightGoal]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.HappyWeight,
        },
        events: {
            onEnter: () => 'OnboardingReinGoalViewed',
            onLeave: () => 'OnboardingReinGoalClicked',
        }
    },
    [RoutePath.HappyWeight]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 50,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.LoseWeight,
        },
        events: {
            onEnter: () => 'OnboardingReachWeightViewed',
            onLeave: () => 'OnboardingReachWeightClicked',
        }
    },
    [RoutePath.LoseWeight]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 55,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.TypicalDay,
        },
        events: {
            onEnter: () => 'OnboardingWhyLoseViewed',
            onLeave: () => 'OnboardingWhyLoseClicked',
        }
    },
    [RoutePath.TypicalDay]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 58,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.AchieveGoal,
        },
        events: {
            onEnter: () => 'OnboardingTypicalDayViewed',
            onLeave: () => 'OnboardingTypicalDayClicked',
        }
    },
    [RoutePath.AchieveGoal]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 60,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.HelpedThousands,
        },
        events: {
            onEnter: () => 'OnboardingHowQuickViewed',
            onLeave: () => 'OnboardingHowQuickClicked',
        }
    },
    [RoutePath.HelpedThousands]: {
        header: {
            email: false,
            logo: true,
            progress: null,
        },
        route: {
            next: () => RoutePath.Email,
        },
        events: {
            onEnter: () => 'OnboardingLoadingViewed',
        }
    },
    [RoutePath.Email]: {
        header: {
            email: false,
            logo: true,
            progress: null,
        },
        route: {
            next: () => RoutePath.NewsOffer,
        },
        events: {
            onEnter: () => 'OnboardingEmailViewed',
            onLeave: () => 'OnboardingEmailClicked'
        }
    },
    [RoutePath.NewsOffer]: {
        header: {
            email: true,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.GoalChart,
        },
        events: {
            onEnter: () => 'OnboardingNewsOffersViewed',
            onLeave: () => 'OnboardingNewsOffersClicked',
        }
    },
    [RoutePath.GoalChart]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.DietActivity,
        },
        events: {
            onEnter: () => 'OnboardingCustomPlanViewed',
            onLeave: () => 'OnboardingCustomPlanClicked',
        }
    },
    [RoutePath.DietActivity]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 62,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.LifestyleAdvice,
        },
        events: {
            onEnter: () => 'OnboardingTypicalViewed',
            onLeave: () => 'OnboardingTypicalClicked',
        }
    },
    [RoutePath.LifestyleAdvice]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.WeightGain,
        },
        events: {
            onEnter: () => 'OnboardingReinCoveredViewed',
            onLeave: () => 'OnboardingReinCoveredClicked',
        }
    },
    [RoutePath.WeightGain]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 64,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PastTrials,
        },
        events: {
            onEnter: () => 'OnboardingEventsToGainViewed',
            onLeave: () => 'OnboardingEventsToGainClicked',
        }
    },
    [RoutePath.PastTrials]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 66,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PersonalJourney,
        },
        events: {
            onEnter: () => 'OnboardingTriedInPastViewed',
            onLeave: () => 'OnboardingTriedInPastClicked',
        }
    },
    [RoutePath.PersonalJourney]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.CurrentLifestyle,
        },
        events: {
            onEnter: () => 'OnboardingReinJourneyViewed',
            onLeave: () => 'OnboardingReinJourneyClicked',
        }
    },
    [RoutePath.CurrentLifestyle]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 68,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.HealthyFridge,
        },
        events: {
            onEnter: () => 'OnboardingLifestyleViewed',
            onLeave: () => 'OnboardingLifestyleClicked',
        }
    },
    [RoutePath.HealthyFridge]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 70,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.ConsumedItems,
        },
        events: {
            onEnter: () => 'OnboardingStockVegitablesViewed',
            onLeave: () => 'OnboardingStockVegetablesClicked',
        }
    },
    [RoutePath.ConsumedItems]: {
        scrollableContent: true,
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 72,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.EatTimes,
        },
        events: {
            onEnter: () => 'OnboardingConsumeWeeklyViewed',
            onLeave: () => 'OnboardingConsumeWeeklyClicked',
        }
    },
    [RoutePath.EatTimes]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 74,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PlanFits,
        },
        events: {
            onEnter: () => 'OnboardingDailyEatTimesViewed',
            onLeave: () => 'OnboardingDailyEatTimesClicked',
        }
    },
    [RoutePath.PlanFits]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 76,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.Testimonials,
        },
        events: {
            onEnter: () => 'OnboardingRelatePlanFitsViewed',
            onLeave: () => 'OnboardingRelatePlanFitsClicked',
        }
    },
    [RoutePath.Testimonials]: {
        header: {
            email: false,
            logo: true,
            progress: null,
        },
        route: {
            next: () => RoutePath.AverageBusy,
        },
        events: {
            onEnter: () => 'OnboardingReinRoutineViewed',
            onLeave: () => 'OnboardingReinRoutineClicked',
        }
    },
    [RoutePath.AverageBusy]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 78,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SmallChanges,
        },
        events: {
            onEnter: () => 'OnboardingAverageBusyViewed',
            onLeave: () => 'OnboardingAverageBusyClicked',
        }
    },
    [RoutePath.SmallChanges]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.WorkoutTimes,
        },
        events: {
            onEnter: () => 'OnboardingReinTenMinViewed',
            onLeave: () => 'OnboardingReinTenMinClicked',
        }
    },
    [RoutePath.WorkoutTimes]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 80,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WithoutWorkout,
        },
        events: {
            onEnter: () => 'OnboardingWorkoutTimesViewed',
            onLeave: () => 'OnboardingWorkoutTimesClicked',
        }
    },
    [RoutePath.WithoutWorkout]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 82,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.MovementImportant,
        },
        events: {
            onEnter: () => 'OnboardingWithoutWorkoutViewed',
            onLeave: () => 'OnboardingWithoutWorkoutClicked',
        }
    },
    [RoutePath.MovementImportant]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.GlassesWater,
        },
        events: {
            onEnter: () => 'OnboardingReinMovementViewed',
            onLeave: () => 'OnboardingReinMovementClicked',
        }
    },
    [RoutePath.GlassesWater]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 84,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SleepHours,
        },
        events: {
            onEnter: () => 'OnboardingGlassesWaterViewed',
            onLeave: () => 'OnboardingGlassesWaterClicked',
        }
    },
    [RoutePath.SleepHours]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 86,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SleepGameChanger,
        },
        events: {
            onEnter: () => 'OnboardingSleepViewed',
            onLeave: () => 'OnboardingSleepClicked',
        }
    },
    [RoutePath.SleepGameChanger]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.HowMotivated,
        },
        events: {
            onEnter: () => 'OnboardingReinSleepViewed',
            onLeave: () => 'OnboardingReinSleepClicked',
        }
    },
    [RoutePath.HowMotivated]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 88,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.HitGoalChart,
        },
        events: {
            onEnter: () => 'OnboardingMotivatedViewed',
            onLeave: () => 'OnboardingMotivatedClicked',
        }
    },
    [RoutePath.HitGoalChart]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.OutsideMotivation,
        },
        events: {
            onEnter: () => 'OnboardingGoalPredictViewed',
            onLeave: () => 'OnboardingGoalPredictClicked',
        }
    },
    [RoutePath.OutsideMotivation]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 90,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyAndMind,
        },
        events: {
            onEnter: () => 'OnboardingRelateOutsideMotivationViewed',
            onLeave: () => 'OnboardingRelateOutsideMotivationClicked',
        }
    },
    [RoutePath.BodyAndMind]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.OldHabits,
        },
        events: {
            onEnter: () => 'OnboardingReinBodyMindViewed',
            onLeave: () => 'OnboardingReinBodyMindClicked',
        }
    },
    [RoutePath.OldHabits]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 92,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PeopleAround,
        },
        events: {
            onEnter: () => 'OnboardingRelateOldHabitsViewed',
            onLeave: () => 'OnboardingRelateOldHabitsClicked',
        }
    },
    [RoutePath.PeopleAround]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 94,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.FeelFailure,
        },
        events: {
            onEnter: () => 'OnboardingRelatePeopleAroundViewed',
            onLeave: () => 'OnboardingRelatePeopleAroundClicked',
        }
    },
    [RoutePath.FeelFailure]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 96,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.UpsAndDown,
        },
        events: {
            onEnter: () => 'OnboardingRelateFailureViewed',
            onLeave: () => 'OnboardingRelateFailureClicked',
        }
    },
    [RoutePath.UpsAndDown]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WellnessGoal,
        },
        events: {
            onEnter: () => 'OnboardingReinNoOnePerfectViewed',
            onLeave: () => 'OnboardingReinNoOnePerfectClicked',
        }
    },
    [RoutePath.WellnessGoal]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 98,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.RealisticTargets,
        },
        events: {
            onEnter: () => 'OnboardingOtherGoalViewed',
            onLeave: () => 'OnboardingOtherGoalClicked',
        }
    },
    [RoutePath.RealisticTargets]: {
        customWrapper: true,
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: null,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.HearSource,
        },
        events: {
            onEnter: () => 'OnboardingReinCongratsViewed',
            onLeave: () => 'OnboardingReinCongratsClicked',
        }
    },
    [RoutePath.HearSource]: {
        header: {
            email: true,
            logo: false,
            progress: {
                percents: 99,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.ProgramBuilder,
        },
        events: {
            onEnter: () => 'OnboardingSourceViewed',
            onLeave: () => 'OnboardingSourceClicked',
        }
    },
    [RoutePath.ProgramBuilder]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.YouAreAble,
        },
        events: {
            onLeave: () => 'OnboardingLoadingScreenViewed',
        }
    },
    [RoutePath.YouAreAble]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.PersonalizedProgram,
        },
        events: {
            onEnter: () => 'OnboardingFinisher1Viewed',
            onLeave: () => 'OnboardingFinisher4Viewed',
        }
    },
    [RoutePath.PersonalizedProgram]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.TrialPay,
        },
        events: {
            onLeave: () => 'OnboardingFinisher5Viewed',
        }
    },
    [RoutePath.TrialPay]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Subscribe,
        },
        events: {
            onEnter: () => 'OnboardingTrialPayNextViewed',
        }
    },
    [RoutePath.Subscribe]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Payment,
        },
        events: {
            onEnter: () => 'OnboardingCheckoutViewed',
            onLeave: () => 'OnboardingCheckoutClicked',
        }
    },
    [RoutePath.Payment]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Congrats,
        },
        events: {}
    },
    [RoutePath.Congrats]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.SkipTrial,
        },
        events: {
            onLeave: () => 'OnboardingCongratsViewed',
        }
    },
    [RoutePath.SkipTrial]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Addons,
        },
        events: {
            onEnter: () => 'OnboardingSkipTrialViewed',
        }
    },
    [RoutePath.Addons]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: (isDiscount) => isDiscount ? RoutePath.AddonsDiscount : RoutePath.Wait,
        },
        events: {
            onEnter: () => 'OnboardingAddonsViewed',
            onLeave: () => 'OnboardingAddonsViewed',
        }
    },
    [RoutePath.AddonsDiscount]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: (isDiscount) => !isDiscount ? RoutePath.AccessAccount : RoutePath.Wait,
        },
        events: {
            onEnter: () => 'OnboardingAddonsDiscountViewed',
        }
    },
    [RoutePath.Wait]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.AccessAccount,
        },
        events: {
            onEnter: () => 'OnboardingWaitGotItViewed',
            onLeave: () => 'OnboardingWaitGotItClicked',
        }
    },
    [RoutePath.AccessAccount]: {
        header: {
            email: false,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.MainGoal,
        },
        events: {
            onEnter: () => 'OnboardingDownloadButtonViewed',
            onLeave: () => 'OnboardingDownloadButtonClicked',
        }
    }
};
