import React, { useContext, useEffect, useState } from 'react'

import { BaseContext } from '../../context/base.context';

import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { ColoredPage } from "../../components/colored-page";

import { PreloadImageServiceInstanceCTX } from "../../services/preload-image.service";
import { RoutePath } from '../../routes/route-path.constant';

export const LifestyleAdvice = () => {
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);

    const { pageConfiguration } = useContext(BaseContext);

    const [img, setImg] = useState('');

    const item = 'svg/reinforcement-icons/ladder-with-star.svg';

    let text;

    switch (localStorage.getItem(LocalStorageKeys[RoutePath.DietActivity])) {
        case 'need-work':
            text = `You’re probably doing better than you think.
            
                    We'll help you figure out what works for you and how to stick with it!`;
            break;
        case 'some-healthy-habits':
            text = `We've got you covered.
            
                    We'll start with small, realistic changes that really add up.`;
            break;
        case 'eat-well-stay-active':
            text = `Amazing!
            
                    We'll dig into what's working (and what’s not) and help you get to the next level.`;
            break;
    }

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    useEffect(() => {
        (async () => {
            if (item) {
                const img = await getImageByLocalPath(item);
                setImg(img as string);
            }
        })();
    }, [item, getImageByLocalPath]);

    return (
        <ColoredPage>
            <div>
                <img src={img} alt="" />

                <Typography variant={TypographyVariants.coloredPage}>
                    {text}
                </Typography>
            </div>

            <NextButton bottomMargin onClick={handleClick} typographyText="Got it!" />
        </ColoredPage>
    )
};
