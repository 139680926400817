import React from 'react';

import { Paywall } from '@ruby-labs/ruby-web-common';

import { usePayment } from './use-payment.hook';
import { usePaywallInfo } from './use-paywall-info.hook';

import { PaymentInfo } from './payment-info';

import { useStyles } from './styles';

export const Payment = () => {

    const { chargedText } = useStyles();

    const { price, stripeProp, countryCode, loadedKeys, accessPaymentsKeys } = usePaywallInfo();

    const { error, cardError, loadingRequest, handleSubmit, handleClickAmazonButton, modalCheckoutWindowTriggered } = usePayment();

    return (
        <>
            <PaymentInfo price={price} />

            {loadingRequest ? <span>Loading...</span> :
                <Paywall
                    isUpdateForm={false}
                    loading={!loadedKeys}
                    error={error}
                    cardError={cardError}
                    countryCode={countryCode}
                    accessPaymentsKeys={accessPaymentsKeys}
                    stripeProp={stripeProp}
                    env={process.env.REACT_APP_ENV as string}
                    isAmazonActive={false}
                    isPaypalActive
                    isApplePayActive
                    isGooglePayActive
                    onSubmit={handleSubmit}
                    onAmazonButtonClicked={handleClickAmazonButton}
                    modalCheckoutWindowTriggered={modalCheckoutWindowTriggered}
                    formText={
                        <div className={chargedText}>
                            You will be charged only <span>{price}$ for your 7-day trial.</span> <br />
                            We'll <span>email you a reminder</span> before your trial period ends. Cancel anytime.
                        </div>
                    }
                />
            }
        </>
    )
};
