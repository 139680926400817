import React, { useContext, useState } from 'react';

import { useMultiClickProtector } from '@ruby-labs/ruby-web-common';

import { disableBrowserBackButton } from '../../helpers/disableBrowserBackButton';
import { Typography } from '../../components/typography';
import { PriceBlock } from './price-block';
import { DottedLoader } from '../../components/dotted-loader';

import { CouponsApiProvider } from '../../api/coupons.api';
import { LocalStorageKeys, TypographyVariants } from '../../constants';
import { BaseContext } from '../../context/base.context';
import { RoutePath } from '../../routes/route-path.constant';
import { useStyles } from './styles';

const COUPON = 'UPGRADEPLAN33';

export const SkipTrial = () => {
    const { pageConfiguration } = useContext(BaseContext);
    const { handleMultiClickProtector } = useMultiClickProtector();
    const [loading, setLoading] = useState(false);

    disableBrowserBackButton(window.location.href);

    const onSubmit = () => {
        const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]) as string;

        setLoading(true);

        CouponsApiProvider.upgradePlan({
            email,
            coupon: COUPON,
        })
            .then((res: any) => {
                pageConfiguration.event('OnboardingSkipTrialStartPlanClicked', { status: res?.statusCode })
            })
            .catch((res: any) => {
                pageConfiguration.event('OnboardingSkipTrialStartPlanClicked', { status: res?.statusCode })
            })
            .finally(() => {
                setLoading(false);

                disableBrowserBackButton(`${window.location.origin}${RoutePath.Addons}`);

                localStorage.setItem('skipTrial', 'true');

                pageConfiguration.handleOnPageComplete();
            });
    };

    const skip = (top: boolean = false) => () => {
        if (loading) return;

        const eventName = top ? 'OnboardingSkipTrialSkipTopClicked' : 'OnboardingSkipTrialStartTrialClicked';

        pageConfiguration.event(eventName);

        pageConfiguration.handleOnPageComplete();
    };

    const {
        page,
        skipWrapper,
        skipButton,
        title, text, secondCard,
        cards, firstCard,
    } = useStyles();

    return (
        <div className={page}>
            <div className={skipWrapper} onClick={skip(true)}>
                <Typography
                    variant={TypographyVariants.h2}
                    className={skipButton}
                >
                    Skip {'>'}
                </Typography>
            </div>
            <Typography variant={TypographyVariants.h1} className={title}>
                Not planning on looking back?
            </Typography>
            <Typography variant={TypographyVariants.h2} className={text}>
                Get a head start and take <span>33% off</span> your<br/>
                first 1-week plan by skipping the trial and<br/>
                starting today!
            </Typography>
            <div className={cards}>
                <PriceBlock
                    btnLabel='Start trial'
                    active={false}
                    price={'19 for'}
                    priceText='1-week plan'
                    savingPrice={0}
                    trialTitle='7-day trial'
                    className={firstCard}
                    onClick={handleMultiClickProtector(skip())}
                    trialIcon={false}
                />
                <PriceBlock
                    btnLabel={loading
                        ? <DottedLoader />
                        : 'Skip trial and start plan'
                    }
                    active={true}
                    price={'12.73 for'}
                    priceText='1-week plan'
                    savingPrice={'6.27'}
                    trialTitle='7-day trial'
                    onClick={handleMultiClickProtector(onSubmit)}
                    className={secondCard}
                    label={'SAVE 33%'}
                    trialIcon={false}
                />
            </div>
        </div>
    );
};
