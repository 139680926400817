import React, { useContext } from 'react';

import { BaseContext } from '../../context/base.context';

import { useBodyPlanData } from '../../hooks/useBodyPlanData';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { WeightChart } from '../../components/charts/weight-chart';
import { PartyIcon } from './party-icon';

import { TypographyVariants } from '../../constants';

import { useStyles } from './styles';

export const HitGoalChart = () => {
    const { goalTitleClass, goalTextClass, dateTextClass, byDateTextClass } = useStyles();
    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const { earlierWeightPlan, earlierMonthsPlan, goal, unit, earlierLossMonth, earlierLossDay, earlierWeightLossDifference } = useBodyPlanData();

    return (
        <>
            <Typography variant={TypographyVariants.h2} className={goalTitleClass}>
                Nice! <PartyIcon />
                <br/>
                Now that we know you better, we think you can hit your goal {earlierWeightLossDifference} days earlier.
            </Typography>

            <Typography variant={TypographyVariants.h3} className={goalTextClass}>
                We predict you’ll be
            </Typography>

            <Typography variant={TypographyVariants.span} className={dateTextClass}>
                {earlierWeightPlan[earlierWeightPlan.length - 1]} {unit} <span className={byDateTextClass}>by</span> {earlierLossMonth}, {earlierLossDay}
            </Typography>

            <WeightChart weightPlan={earlierWeightPlan} monthsPlan={earlierMonthsPlan} goal={goal} unit={unit} latestMonth={earlierLossMonth}/>

            <NextButton onClick={handleClick} />
        </>
    )
};
