import React, { useContext, useEffect, useState } from 'react'

import { BaseContext } from '../../context/base.context';

import { TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { ColoredPage } from "../../components/colored-page";

import { PreloadImageServiceInstanceCTX } from "../../services/preload-image.service";

export const PersonalJourney = () => {
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);

    const { pageConfiguration } = useContext(BaseContext);

    const [img, setImg] = useState('');

    const item = 'svg/reinforcement-icons/mountain-with-flag.svg';

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    useEffect(() => {
        (async () => {
            if (item) {
                const img = await getImageByLocalPath(item);
                setImg(img as string);
            }
        })();
    }, [item, getImageByLocalPath]);

    return (
        <ColoredPage>
            <div>
                <img src={img} alt="" />

                <Typography variant={TypographyVariants.coloredPage}>
                    Able is about your personal
                    weight loss journey.
                    <br />
                    <br />
                    Restrictive diets (and other single-minded approaches) fail because they are unsustainable and one-size-fits-all.
                    <br />
                    <br />
                    At Able, your health coach will help you set weekly goals and always be a message away.
                </Typography>
            </div>

            <NextButton bottomMargin onClick={handleClick} typographyText="Got it!" />
        </ColoredPage>
    )
};
