
import { RoutePath } from '../routes/route-path.constant';
import { LocalStorageKeys, MultipleLocalStorageKeys } from '../constants';
import { getUnitSystem, UNIT_SYSTEM } from '../constants/body-height-weight';

import { convertToCm } from './unit-converter';

import { getLocalStorageData } from './local-storage-data';

const DEFAULT_VALUES = { currency: 'USD', lastName: '' };

const getBodyHeight = () => {
    const unitSystem = getUnitSystem();
    let height: string | number = localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].heightCm) as string;

    if (unitSystem === UNIT_SYSTEM.imperial) {
        const heightFt = localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].heightFt);
        const heightInches = localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].heightInches);

        height = convertToCm(Number(heightFt), Number(heightInches));
    }

    return Number(height);
};

const getBodyWeightCurrent = () => {
    const unitSystem = getUnitSystem();
    let weight = localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].weightKg);

    if(unitSystem === UNIT_SYSTEM.imperial) {
        weight = localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].weightLbs);
    }

    return Number(weight);
};

const getSignUpDataFromLocalStorage = () => {

    const localStorageData = getLocalStorageData(Object.values(LocalStorageKeys));

    return {
        ...localStorageData,
        height: getBodyHeight(),
        weightCurrent: getBodyWeightCurrent(),
    };
};

// @param: additionalData - object with post data not related to local storage
export const getSignUpData = (paymentData: any = {}, gPay?: boolean) => {
    const data = getSignUpDataFromLocalStorage();
    const unitSystem = getUnitSystem();

    data.weightGoal = Number(data.weightGoal);

    data.unit = unitSystem;
    data.unitWeight = unitSystem;
    data.unitHeight = unitSystem;
    data.planCode = JSON.parse(data.planCode)?.key;
    data.isBreastfeeding = data.isBreastfeeding === 'yes';
    if (!gPay) {
        data.name = paymentData.name;
    }

    return {
        ...data,
        ...DEFAULT_VALUES,
        ...paymentData,
    }
};
