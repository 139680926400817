import { Palette } from '../index';
import {ChoiceButtonStyles} from './choice-button';

export type MultiChoiceButtonType = {
    multiChoiceButton: {
        width: string,
        minWidth: string,
        height: string,
        padding: string,
        border: string,
        margin: string,
        background: Palette | string,
    },
    multiChoiceButtonCheckboxCheckmark: {
        border: string,
        backgroundColor: string,
        borderRadius: string,
        borderColor: string,
    },
    multiChoiceButtonSelected: {
        border: string,
        background: Palette | string,
        color: Palette | string,
    },
    multiChoiceButtonActive?: {
        boxShadow: string,
    }
}

export const MultiChoiceButtonStyles: MultiChoiceButtonType = {
    multiChoiceButton: {
        ...ChoiceButtonStyles.choiceButton,
        background: '#F6F6F6',
    },
    multiChoiceButtonCheckboxCheckmark: {
        border: 'none',
        backgroundColor: '#4B46EC',
        borderRadius: '2px',
        borderColor: 'inherit',
    },
    multiChoiceButtonSelected: {
        ...ChoiceButtonStyles.choiceButtonSelected,
        border: '1px solid #4b46ec',
        background: '#f0f0ff',
        color: '#202B47'
    },
    multiChoiceButtonActive: {
        boxShadow: 'rgb(145 156 248 / 50%) 0px 0px 0px 0.2rem',
    }
};
