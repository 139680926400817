// node modules
import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    text1: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#4B46EC',
        margin: '16px auto 4px',
        display: 'flex',
        justifyContent: 'center',
    },
    img: {
        marginRight: '8px',
    },
    text2: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        margin: '4px auto 32px',
    },
    text3: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#232323',
        opacity: .5,
        marginBottom: '12px'
    }
});
