import React from 'react';

import { RatingButton, RatingType } from '../rating-button';
import { Typography } from '../typography';
import { TypographyVariants } from '../../constants';

import { useStyles } from './styles';

interface Props {
    value: string;
    ratings?: RatingType[];
    ratingGradationInfo?: string[];
    handleClick: (rating: RatingType) => void;
}

export const ratingScaleDefaultAnswers: RatingType[] = ['1', '2', '3', '4', '5'].map(label => ({
    key: label,
    value: label,
}));

const defaultProps = {
    ratings: ratingScaleDefaultAnswers,
    ratingGradationInfo: ['Strongly Disagree', 'Strongly Agree'],
};

export const RatingScale = (props: Props) => {
    const { ratingScaleWrapper, ratingScale, ratingGradationInfoWrapper } = useStyles();

    const { value, ratings, handleClick, ratingGradationInfo } = props;

    const ratingButtons = ratings?.map(rating => <RatingButton
        key={rating.key}
        rating={rating}
        selected={value == rating.key}
        handleClick={() => handleClick(rating)}
    />);

    return (
        <div className={ratingScaleWrapper}>
            <div className={ratingScale}>
                {ratingButtons}
            </div>
            <div className={ratingGradationInfoWrapper}>
                <Typography variant={TypographyVariants.ratingGradationInfo}>
                    {ratingGradationInfo && ratingGradationInfo[0]}
                </Typography>
                <Typography variant={TypographyVariants.ratingGradationInfo}>
                    {ratingGradationInfo && ratingGradationInfo[1]}
                </Typography>
            </div>
        </div>
    );
};

RatingScale.defaultProps = defaultProps;
