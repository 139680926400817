import React, { useContext } from 'react';

import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { FloatedButton } from '../../components/floated-button';
import { MultiChoiceButton, MultiChoiceType } from '../../components/multichoice-button';

import { BaseContext } from '../../context/base.context';
import { RoutePath } from '../../routes/route-path.constant';

import { useLocalStorage } from '../../hooks/useLocalStorage';

export const BodyRisk = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({ key: LocalStorageKeys[RoutePath.BodyRisk], defaultValue: [] });

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    const multiChoices: MultiChoiceType[] = [
        { key: 'diabetes', value: 'Diabetes' },
        { key: 'hormonal-issues', value: 'Hormonal issues' },
        { key: 'insomnia', value: 'Insomnia' },
        { key: 'high-blood-pressure', value: 'High blood pressure' },
        { key: 'heart-disease', value: 'Heart disease' },
        { key: 'depression-anxiety', value: 'Depression / Anxiety' },
        { key: 'high-cholesterol', value: 'High cholesterol' },
        { key: 'kidney-disease', value: 'Kidney disease' },
        { key: 'asthma', value: 'Asthma' },
        { key: 'osteoarthritis', value: 'Osteoarthritis' },
        { key: 'other', value: 'Other' },
        { key: 'none', value: 'None' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                Are you at risk of any of the following?
            </Typography>
            <Typography variant={TypographyVariants.h2}>
                Select all that apply
            </Typography>
            {
                multiChoices.map((v: MultiChoiceType) => (
                    <MultiChoiceButton
                        key={v.key}
                        item={v}
                        pageValue={pageValue}
                        setPageValue={setPageValue}
                    />
                ))
            }

            <NextButton onClick={handleClick} disabled={!pageValue.length} />
        </>
    );
};
