import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    numbersClass: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        marginLeft: '8px',
        marginTop: '5px',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#6B6B6B'
    },
    monthClass: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: '10%',
        paddingTop: '8px',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#6B6B6B',
        '@media (min-width: 560px)' : {
            paddingRight: '6%'
        },
    },
    weightGraphic: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '80px',
        '& svg': {
            display: 'block',
        }
    },
    popoverWrapper: {
        top: '-12%',
        left: '80%',
    },
    '@media (max-width: 540px)' : {
        popoverWrapper: {
            top: '-16%',
        }
    },
    '@media (max-width: 480px)' : {
        popoverWrapper: {
            top: '-23%',
        }
    },
    '@media (max-width: 430px)' : {
        popoverWrapper: {
            top: '-24%',
        }
    },
    '@media (max-width: 414px)' : {
        popoverWrapper: {
            top: '-15%',
            left: '70.5%',
        }
    },
    '@media (max-width: 393px)' : {
        popoverWrapper: {
            top: '-18%',
            left: '70.5%',
        }
    },
    '@media (max-width: 390px)' : {
        popoverWrapper: {
            top: '-20%',
            left: '70.5%',
        }
    },
    '@media (max-width: 375px)' : {
        popoverWrapper: {
            top: '-21.5%',
            left: '70.5%',
        }
    },
    '@media (max-width: 360px)' : {
        popoverWrapper: {
            top: '-24%',
            left: '70.5%',
        }
    },
    '@media (max-width: 320px)' : {
        popoverWrapper: {
            top: '-27%',
            left: '66.5%',
        }
    }
});
