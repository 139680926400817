import React, {useContext, useEffect} from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import { RoutePath } from '../../routes/route-path.constant';

const answers: ChoiceType[] = [
    { key: 'physician-dietitian', value: 'Your physician or dietitian' },
    { key: 'poster-billboard', value: 'Poster or billboard' },
    { key: 'friend-family', value: 'Friend or family member' },
    { key: 'instagram', value: 'Instagram' },
    { key: 'mail', value: 'Direct mail or package insert' },
    { key: 'online-streaming-tv', value: 'Online TV or streaming TV' },
    { key: 'tv', value: 'TV' },
    { key: 'radio', value: 'Radio' },
    { key: 'search-engine', value: 'Search engine (Google, Bing. etc.)' },
    { key: 'newspaper-magazine', value: 'Newspaper or magazine' },
    { key: 'facebook', value: 'Facebook' },
    { key: 'blog-website-review', value: 'Blog post or website review' },
    { key: 'podcast', value: 'Podcast' },
    { key: 'influencer', value: 'Influencer' },
    { key: 'youtube', value: 'YouTube' },
    { key: 'pinterest', value: 'Pinterest' },
    { key: 'other', value: 'Other' },
];

export const HearSource = () => {
    const { pageValue: hearSource, setPageValue }: any = useLocalStorage({ key: LocalStorageKeys[RoutePath.HearSource], defaultValue: '' });

    const {
        pageValue: hearSourceAnswers,
        setPageValue: setHearSourceAnswers
    }: any = useLocalStorage({ key: 'hearSourceAnswers', defaultValue: [] });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    useEffect(() => {
        if (!hearSourceAnswers.length) {
            let shuffledAnswers = answers
                .slice(0, answers.length - 1)
                .map(answer => ({ answer, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ answer }) => answer);

            shuffledAnswers.push(answers[answers.length - 1]);

            setHearSourceAnswers(shuffledAnswers);
        }
    }, []);



    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                Where did you hear about us?
            </Typography>

            {
                hearSourceAnswers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == hearSource}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
