import React, { useContext } from 'react';

import { BaseContext } from '../../context/base.context';

import { useStyles } from "./styles";

import { DayTrial } from './day-trial';
import { TimeRemaining } from './time-remaining';
import { HighLightList } from './highlight-list';
import { CustomerReviews } from './customer-reviews';
import { Excellent } from './excellent';
import { NextButton } from '../../components/next-button';
import { WeightChart } from '../../components/charts/weight-chart';
import { useBodyPlanData } from '../../hooks/useBodyPlanData';
import { FloatedButton } from '../../components/floated-button';

export const Subscribe = () => {
    const { pageConfiguration, optimizely } = useContext(BaseContext);
    const { content } = useStyles();

    const handleClick = () => {
        optimizely.track('WebCheckoutContinueClicked', pageConfiguration.getUserId());
        pageConfiguration.handleOnPageComplete();
    };

    const { earlierWeightPlan, earlierMonthsPlan, goal, unit, earlierLossMonth } = useBodyPlanData();

    return (
        <>
            <div>
                <DayTrial />
                <HighLightList />
                <TimeRemaining />

                <WeightChart weightPlan={earlierWeightPlan} monthsPlan={earlierMonthsPlan} goal={goal} unit={unit} latestMonth={earlierLossMonth} />

                <div className={content}>
                    <CustomerReviews />
                    <Excellent />
                </div>

                <FloatedButton floated withGradient>
                    <NextButton onClick={handleClick} />
                </FloatedButton>
            </div>
        </>
    );
}
