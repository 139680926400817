import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const HowMotivated = () => {
    const { pageValue: howMotivated, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.HowMotivated],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'ready', value: 'I’m ready!' },
        { key: 'feeling-hopeful', value: 'Feeling hopeful' },
        { key: 'taking-easy', value: 'Taking it easy' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                How motivated are you to reach your target weight?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == howMotivated}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
