import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    numbersClass: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginRight: '8px',
        marginTop: '5px',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#6B6B6B'
    },
    monthClass: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '7%',
        paddingTop: '8px',
        fontFamily: 'SF Pro Text',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#6B6B6B',
    },
    weightGraphic: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        '& svg': {
            display: 'block',
        }
    },
    popoverWrapper: {
        top: '66%',
        left: '81%',
    },
    '@media (max-width: 428px)' : {
        popoverWrapper: {
            top: '57%',
            left: '75.5%',
        }
    },
    '@media (max-width: 414px)' : {
        popoverWrapper: {
            top: '55%',
            left: '75.5%',
        }
    },
    '@media (max-width: 393px)' : {
        popoverWrapper: {
            top: '53%',
            left: '75.5%',
        }
    },
    '@media (max-width: 390px)' : {
        popoverWrapper: {
            top: '52%',
            left: '75.5%',
        }
    },
    '@media (max-width: 375px)' : {
        popoverWrapper: {
            top: '51%',
            left: '75.5%',
        }
    },
    '@media (max-width: 360px)' : {
        popoverWrapper: {
            top: '50%',
            left: '75.5%',
        }
    },
    '@media (max-width: 320px)' : {
        popoverWrapper: {
            top: '43%',
            left: '75%',
        }
    }
});
