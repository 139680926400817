import React from 'react';
import { useNavigate } from 'react-router-dom';

import leftArrow from '../../../assets/images/svg/left-arrow.svg';
import logo2 from '../../../assets/images/svg/logo-black.svg';

import { useStyles } from './styles';
import { isBoolean } from '../../../helpers';

export const LogoHeader = React.memo(({ classNames, backButtonAvailable }: any) => {

    const {
        header,
        backButton,
        backButtonWrapper,
        backButtonIconInnerWrapper,
    } = useStyles();

    const navigate = useNavigate();
    const handleBack = () => navigate(-1);

    const isBackButtonAvailable = isBoolean(backButtonAvailable)
        ? backButtonAvailable
        : backButtonAvailable || true;

    // window.history.length > 0

    return (
        <header className={`${header} ${classNames?.LogoHeaderHeader}`}>

            {isBackButtonAvailable && <div className={backButtonWrapper}>
                <div className={backButtonIconInnerWrapper} onClick={handleBack}>
                    <img className={backButton} src={leftArrow} alt='' />
                </div>
            </div>
            }

            <img src={logo2} alt='' />

            {isBackButtonAvailable && <div className={backButtonWrapper}></div>}
        </header>
    );
});
