import React, {useContext, useState} from 'react';

import { LocalStorageKeys, MultipleLocalStorageKeys, TypographyVariants } from '../../constants';
import { getUnitSystem, UNIT_SYSTEM } from '../../constants/body-height-weight';
import { RoutePath } from '../../routes/route-path.constant';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';

import { Typography } from '../../components/typography';
import { LbsInput } from '../../components/mass-input/lbs-input';
import { KgInput } from '../../components/mass-input/kg-input';
import { NextButton } from '../../components/next-button';

import { useStyles } from './styles';

export const BodyIdealWeight = () => {

    const { text3 } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: bodyIdealWeight, setPageValue: setBodyIdealWeight } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight], defaultValue: ''
    });

    let weightKg = Number(localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].weightKg));
    let weightLbs = Number(localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].weightLbs));

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete(bodyIdealWeight);
    };

    const [pageError, setPageError] = useState(false);

    const unitSystem = getUnitSystem();

    const onChange = (e: any) => { setBodyIdealWeight(e.target.value); };

    const onError = (error: boolean) => {
        setPageError(error)
    };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                What is your goal weight?
            </Typography>

            {unitSystem === UNIT_SYSTEM.metric &&
                <KgInput
                    value={bodyIdealWeight}
                    lessThen={weightKg}
                    onChange={onChange}
                    onError={onError} />
            }

            {unitSystem === UNIT_SYSTEM.imperial &&
                <LbsInput
                    value={bodyIdealWeight}
                    lessThen={weightLbs}
                    onChange={onChange}
                    onError={onError} />
            }

            <Typography variant={TypographyVariants.p} className={text3}>
                *Using data from real Able users, we’ll predict how much weight you can lose and how quickly, by following your custom plan.
            </Typography>

            <NextButton onClick={handleClick} disabled={!bodyIdealWeight || pageError}/>
        </>
    )
};
