import { createUseStyles } from 'react-jss';
import {ThemeProps} from '../../themes';

export const useStyles = createUseStyles(({ ratingScale }: ThemeProps) => ({
    text: {
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left',
        marginBottom: '12px',
    },
    firstParagraph: {
        marginTop: '40px',
    },
    boldText: {
        fontWeight: 600,
    },
    blueText: {
        color: '#4B46EC',
        fontWeight: 600,
        margin: '12px 0 24px',
    },
    ratingScaleWrapper: ratingScale.ratingScaleWrapper,
    ratingScale: ratingScale.ratingScale,
    ratingButton: {
        width: '72px',
        height: '68px',
        padding: '17px 10px',
        minWidth: '72px',
    },
    ratingText: {
        fontSize: '16px',
        fontWeight: 600,
    },
    popularChoice: {
        fontSize: '9px',
        lineHeight: '10px',
        textTransform: 'uppercase',
        marginTop: '20px',
        marginBottom: '12px',
        position: 'relative',
        '&::before': {
            top: '-16px',
            left: '34px',
            position: 'absolute',
            content: '""',
            width: '1px',
            background: '#202B47',
            height: '15px',
        },
    },
}));
