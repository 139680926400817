import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

import { getUnitSystem, UNIT_SYSTEM } from '../constants/body-height-weight';
import { LocalStorageKeys, MultipleLocalStorageKeys } from '../constants';
import { RoutePath } from '../routes/route-path.constant';
import { convertToLbsWithoutRounding } from '../helpers/unit-converter';

dayjs.extend(localeData);

dayjs().localeData();

type PropsType = {
    goalWeight: number,
    weight: number;
    height: number;
    unit: 'kg' | 'lb';
}

type WeightUnitType = { step: number, maxDifference: number };

type WeightType = { kg: WeightUnitType, lb: WeightUnitType };

const WEIGHT: WeightType = {
    kg: { step: 2, maxDifference: 44 },
    lb: { step: 4, maxDifference: 88 },
};

export class BodyPlanCalculator {
    weight: number;
    height: number;
    goalWeight: number;
    unit: 'kg' | 'lb';

    constructor({ weight, height, goalWeight, unit }: PropsType) {
        this.weight = weight;
        this.height = height;
        this.goalWeight = goalWeight;
        this.unit = unit;
    }

    getBmi = (weight: number): number => {
        let height = this.unit == 'lb' ? this.height : this.height / 100;
        return weight / (height * height) * (this.unit == 'lb' ? 703 : 1);
    };

    getMonthlyWeightLoss = (weight: number, adjusted: boolean): number => {
        const bmi = this.getBmi(weight);

        let result;
        if (bmi < 18.5) {
            result = adjusted ? 0.6 : 0.5;
        } else if (bmi < 25) {
            result = adjusted ? 2.5 : 2;
        } else if (bmi < 30) {
            result = adjusted ? 3.9 : 3.5;
        } else {
            result = adjusted ? 5 : 4.5;
        }

        return this.unit == 'lb' ? convertToLbsWithoutRounding(result) : result;
    };

    getWeightLossGraphicData = (earlier: boolean) => {
        let weightLabels = [];
        let monthLabels = [];

        let weight = this.weight;
        let monthlyWeightLoss = this.getMonthlyWeightLoss(weight, earlier);
        let months = 0;

        monthLabels.push(dayjs().format('MMM'));
        weightLabels.push(Math.round(weight));

        while (weight - monthlyWeightLoss >= this.goalWeight) {
            months++;
            weight -= monthlyWeightLoss;
            monthlyWeightLoss = this.getMonthlyWeightLoss(weight, earlier);

            const monthLabel = dayjs().add(months, 'M').format('MMM');
            if (!monthLabels.includes(monthLabel)) {
                monthLabels.push(monthLabel);
            }
            if (!weightLabels.includes(Math.round(weight))) {
                weightLabels.push(Math.round(weight));
            }
        }

        const averageDaysInMonth = 30.437;
        const days = (weight - this.goalWeight) / monthlyWeightLoss * averageDaysInMonth;

        const finalDate = dayjs().add(months, 'M').add(days, 'day');
        const finalMonth = finalDate.format('MMM');
        const finalDay = finalDate.format('D');

        if (!weightLabels.includes(Math.round(this.goalWeight))) {
            weightLabels.push(Math.round(this.goalWeight));
        }
        if (!monthLabels.includes(finalMonth)) {
            monthLabels.push(finalMonth);
        }

        return { finalDay, finalMonth, weights: weightLabels, dates: monthLabels, finalDate };
    };

    getEarlierDays = () => {
        const { finalDate } = this.getWeightLossGraphicData(false);
        const { finalDate: earlierFinalDate } = this.getWeightLossGraphicData(true);

        return finalDate.diff(earlierFinalDate, 'd');
    };
}

const getWeightByUnit = (): WeightUnitType => {
    const unitSystem = getUnitSystem();
    const unit = unitSystem === UNIT_SYSTEM.imperial ? 'lb' : 'kg';

    // @ts-ignore
    return WEIGHT[unit];
};

export const getWeightDifference = (): number => {
    const unitSystem = getUnitSystem();

    const bodyHeightWeight = MultipleLocalStorageKeys[RoutePath.BodyHeightWeight];

    const weight = unitSystem === UNIT_SYSTEM.imperial ? localStorage.getItem(bodyHeightWeight.weightLbs) : localStorage.getItem(bodyHeightWeight.weightKg);
    const idealWeight = localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight]) || weight;

    // @ts-ignore
    return +weight - +idealWeight;
};

export const getCorrectedWeight = (): number => {
    const weight = getWeightByUnit();
    const weightDifference = Math.abs(getWeightDifference());
    return weightDifference > weight.maxDifference ? weight.maxDifference : weightDifference;
};

export const getNextMonth = (month: string) => {
    const monthsShort = dayjs.monthsShort();

    let nextMonthIndex = monthsShort.findIndex(m => m.toLowerCase() === month.toLowerCase()) + 1;

    if (nextMonthIndex > 11) {
        nextMonthIndex = 0;
    }

    return monthsShort[nextMonthIndex];
};
