export const kgValidationQuery: any = {
    type: {
        value: "number",
        moreThen: {
            value: 29,
            errorMessage: "Make sure you insert the correct weight."
        },
        lessThen: {
            value: 451,
            errorMessage: "Make sure you insert the correct weight."
        }
    }
}
