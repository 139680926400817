import React, { useContext } from 'react';

import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { MultiChoiceButton, MultiChoiceType } from '../../components/multichoice-button';
import { FloatedButton } from '../../components/floated-button';

import { BaseContext } from '../../context/base.context';
import { RoutePath } from '../../routes/route-path.constant';

import { useLocalStorage } from '../../hooks/useLocalStorage';

export const WeightGain = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({ key: LocalStorageKeys[RoutePath.WeightGain], defaultValue: [] });

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    const multiChoices: MultiChoiceType[] = [
        { key: 'busier-life', value: 'Busier work or family life' },
        { key: 'pregnancy', value: 'Pregnancy' },
        { key: 'relationships', value: 'Marriage or relationships' },
        { key: 'medication', value: 'Medication or hormonal disorder' },
        { key: 'stress', value: 'Stress or mental health' },
        { key: 'injury', value: 'Injury or accident' },
        { key: 'post-covid', value: 'Post-Covid “new normal”' },
        { key: 'other', value: 'Other' },
        { key: 'none', value: 'None of the above' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                In the last few years, have any life events led to weight gain?
            </Typography>
            <Typography variant={TypographyVariants.h2}>
                Select all that apply
            </Typography>
            {
                multiChoices.map((v: MultiChoiceType) => (
                    <MultiChoiceButton
                        key={v.key}
                        item={v}
                        pageValue={pageValue}
                        setPageValue={setPageValue}
                    />
                ))
            }

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
