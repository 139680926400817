import React from 'react';

import { ModalWindow } from '@ruby-labs/ruby-web-common';

import { useAddon } from './addon.hook';
import { Typography } from '../../components/typography';
import { TypographyVariants } from '../../constants';
import { NextButton } from '../../components/next-button';
import { PriceBox } from './price-box';
import { DiscountBlock } from './discount-block';
import { ModalContent } from './modal-content';

import { useStyles } from './styles';

export const Addons = () => {
    const {
        wrapper, skipButton, skipWrapper, title,
        subtitle, tryItTitle, buttonContent, icon, helperText,
    } = useStyles();

    const { open, loading, isDiscount, skip, onSubmit, handleClose } = useAddon({ prefix: '', prefixDiscount: '' });

    return (
        <div className={wrapper}>
            <div className={skipWrapper} onClick={skip}>
                <Typography
                    variant={TypographyVariants.h2}
                    className={skipButton}
                >
                    Skip {'>'}
                </Typography>
            </div>
            <Typography className={title} variant={TypographyVariants.h1}>
                Get extra help with our<br/>
                Premium Bundle
            </Typography>
            <Typography className={subtitle} variant={TypographyVariants.h3}>
                Exclusive offer recommended for you to achieve your goals faster.
            </Typography>
            <PriceBox
                price={19}
                discountPrice={15}
                originalPrice={45}
            />
            {isDiscount && (
                <>
                    <DiscountBlock />
                    <Typography className={tryItTitle} variant={TypographyVariants.h1}>
                        Try it out for only <s>$45</s> $15
                    </Typography>
                </>
            )}
            <NextButton
                loading={loading}
                onClick={onSubmit}
                typographyText={(
                    <span className={buttonContent}>
                        <svg
                            className={icon}
                            width='17'
                            height='16'
                            viewBox='0 0 17 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M12.6485 7.33301H4.35218C3.69762 7.33301 3.16699 7.92996 3.16699 8.66634V13.333C3.16699 14.0694 3.69762 14.6663 4.35218 14.6663H12.6485C13.303 14.6663 13.8337 14.0694 13.8337 13.333V8.66634C13.8337 7.92996 13.303 7.33301 12.6485 7.33301Z'
                                fill='white' stroke='white' strokeWidth='0.833333' strokeLinecap='round'
                                strokeLinejoin='round' />
                            <path
                                d='M5.16699 7.33301V4.66634C5.16699 3.78229 5.51818 2.93444 6.1433 2.30932C6.76842 1.6842 7.61627 1.33301 8.50033 1.33301C9.38438 1.33301 10.2322 1.6842 10.8573 2.30932C11.4825 2.93444 11.8337 3.78229 11.8337 4.66634V7.33301'
                                stroke='white' strokeWidth='0.833333' strokeLinecap='round' strokeLinejoin='round' />
                        </svg>
                        Buy Now
                    </span>
                )}
            />
            <Typography className={helperText} variant={TypographyVariants.h3}>
                *On click, your account will be charged one payment of ${isDiscount ? 15 : 19} for your
                Premium Bundle.
            </Typography>
            <ModalWindow open={isDiscount && open}>
                <ModalContent handleClose={handleClose} />
            </ModalWindow>
        </div>
    );
};
