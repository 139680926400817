import { API } from './api';

interface AddonInterface {
    email: string;
    charge: string | number;
}

interface PlanInterface {
    email: string;
    coupon: string | number;
}

class CouponsApi extends API {
    upgradeAddon(data: AddonInterface): Promise<any> {
        return this.post('/chargebee/charge', data);
    }
    upgradePlan(data: PlanInterface): Promise<any> {
        return this.post('/chargebee/upgrade', data);
    }
}

export const CouponsApiProvider = new CouponsApi();
