import React, { useContext, useEffect, useState } from 'react'

import { TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';

import { PreloadImageServiceInstanceCTX } from '../../services/preload-image.service';
import { BaseContext } from '../../context/base.context';

import { useStyles } from './styles';

export const WeightLoss = () => {
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);
    const { graphicInfoWrapper, title, text, graphicTitle, graphicImage, graphicSubTitleWrapper, graphicSubTitle } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);
    const [image, setImage] = useState('');

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const item = 'svg/weight-loss/weight-loss-graphic.svg';

    useEffect(() => {
        (async () => {
            if (item) {
                const img = await getImageByLocalPath(item);
                setImage(img as string);
            }
        })();
    }, [item, getImageByLocalPath]);

    return (
        <>
            <Typography variant={TypographyVariants.h2} className={title}>
                Weight loss is about more than food.
            </Typography>

            <Typography variant={TypographyVariants.h2} className={text}>
                Studies show that you’re likely to lose 3X more weight with a health coach than on your own.
                Able creates real and long-lasting change through 1-on-1 health coaching and a holistic, big picture approach. Not restrictive dieting.
            </Typography>

            <div className={graphicInfoWrapper}>
                <Typography variant={TypographyVariants.h1} className={graphicTitle}>
                    Your weight
                </Typography>
                <img src={image} alt="" className={graphicImage} />
                <div className={graphicSubTitleWrapper}>
                    <Typography variant={TypographyVariants.h3} className={graphicSubTitle}>
                        Month 1
                    </Typography>
                    <Typography variant={TypographyVariants.h3} className={graphicSubTitle}>
                        Month 6
                    </Typography>
                </div>
            </div>

            <NextButton typographyText="Got it!" onClick={handleClick}/>
        </>
    )
};
