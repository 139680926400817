import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    customerReviewWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '18px',
    },
    customerReview: {
        display: 'flex',
        flexDirection: 'column',
    },
    jeansImg: {
        width: '100%',
        height: '100%',
        display: 'block',
        margin: 'auto',
    },
    userAvatar: {
        marginRight: '8px',
        borderRadius: '50%',
        display: 'block',
    },
    name: {
        margin: '0',
        textAlign: 'left',
        color: '#4B46EC',
    },
    reviewText: {
        margin: '0 0 8px 0',
        textAlign: 'left',
    },
    text2: {
        margin: '0 0 12px 0',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    bar: {
        height: '48px !important',
        borderRadius: '8px',
        '& > div': {
            borderRadius: '8px'
        }
    },
    '@media screen and (max-width: 320px)': { // iPhone 4/5 resolution
        customerReviewWrapper: {
            alignItems: 'center',
        },
    },
});
