import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const SleepHours = () => {
    const { pageValue: sleepHours, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.SleepHours],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: '5-less-hours', value: 'Less than 5 hours' },
        { key: '5-7-hours', value: '5-7 hours' },
        { key: '7-9-hours', value: '7-9 hours' },
        { key: '9-more-hours', value: 'More than 9 hours' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                How much do you sleep?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == sleepHours}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
