import React, {useContext, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BaseContext } from '../../context/base.context';

import { LogoHeader } from '../header/logo';
import { EmailHeader } from '../header/email';

import { ProgressBar } from '../../components/progress-bar';

import { useStyles } from './styles';
import { RoutePath } from '../../routes/route-path.constant';
import { usePageAccessChecking } from '../../hooks/usePageAccessChecking.hook';

export const Content = ({ children }: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { routerAvailable } = usePageAccessChecking();

    const { pageConfiguration } = useContext(BaseContext);

    const page = pageConfiguration
        .setNavigate(navigate)
        .setConfigurationByLocation(location)
        .getPageConfiguration();

    useEffect(() => {
        pageConfiguration.handleOnPageEnter();
    },[page]);

    const { mainContent } = useStyles({
        props: '/' === '/',
        scrollableContent: page.scrollableContent,
    });

    const backButtonNotAvailableLocations = [
        RoutePath.MainGoal,
        RoutePath.NewsOffer,
        RoutePath.GoalChart,
        RoutePath.TrialPay,
        RoutePath.Subscribe,
        RoutePath.Payment,
        RoutePath.Congrats,
        RoutePath.SkipTrial,
        RoutePath.Addons,
        RoutePath.AddonsDiscount,
        RoutePath.Wait,
    ];
    const backButtonAvailable = !backButtonNotAvailableLocations.includes(location.pathname);

    if(!routerAvailable) return null;

    return (
        <>
            {page.header.email ? <EmailHeader backButtonAvailable={backButtonAvailable} /> : null}
            {page.header.logo ? <LogoHeader backButtonAvailable={backButtonAvailable} /> : null}
            {
                <>
                    {page.header.progress && <ProgressBar progress={page.header.progress} />}
                    {page.customWrapper ? children : (<div className={mainContent}> {children} </div>)}
                </>
            }
        </>
    );
};
