import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    graphicInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        margin: '32px 0',
        textAlign: 'left',
    },
    text: {
        fontWeight: 400,
        textAlign: 'left',
    },
    graphicTitle: {
        textAlign: 'left',
        margin: '0 0 16px',
        alignSelf: 'flex-start',
    },
    graphicImage: {
        width: '100%',
        maxWidth: '560px',
        height: 'auto',
    },
    graphicSubTitleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '18px',
        width: '100%',
        marginTop: '8px',
        marginBottom: '12px',
    },
    graphicSubTitle: {
        fontSize: '12px',
        color: '#6B6B6B',
        margin: 'unset',
    },
});
