import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { RoutePath } from './route-path.constant';

import { MainGoal } from '../pages/main-goal';
import { BodyGender } from '../pages/body-gender';
import { BodyAge } from '../pages/body-age';
import { BodyType } from '../pages/body-type';
import { WeightLoss } from '../pages/weight-loss';
import { BodyBreastfeeding } from '../pages/body-breastfeeding';
import { BodyRisk } from '../pages/body-risk';
import { OurMission } from '../pages/our-mission';
import { WeightGoal } from '../pages/weight-goal';
import { PersonalJourney } from '../pages/personal-journey';
import { SmallChanges } from '../pages/small-changes';
import { MovementImportant } from '../pages/movement-important';
import { SleepGameChanger } from '../pages/sleep-game-changer';
import { BodyAndMind } from '../pages/body-and-mind';
import { UpsAndDown } from '../pages/ups-and-down';
import { RealisticTargets } from '../pages/realistic-targets';
import { LifestyleAdvice } from '../pages/lifestyle-advice';
import { DietActivity } from '../pages/diet-activity';
import { HappyWeight } from '../pages/happy-weight';
import { LoseWeight } from '../pages/lose-weight';
import { WeightGain } from '../pages/weight-gain';
import { PastTrials } from '../pages/past-trials';
import { ConsumedItems } from '../pages/consumed-items';
import { TypicalDay } from '../pages/typical-day';
import { AchieveGoal } from '../pages/achieve-goal';
import { NewsOffer } from '../pages/news-offer';
import { CurrentLifestyle } from '../pages/current-lifestyle';
import { HealthyFridge } from '../pages/healthy-fridge';
import { EatTimes } from '../pages/eat-times';
import { PlanFits } from '../pages/plan-fits';
import { AverageBusy } from '../pages/average-busy';
import { WorkoutTimes } from '../pages/workout-times';
import { WithoutWorkout } from '../pages/without-workout';
import { GlassesWater } from '../pages/glasses-water';
import { SleepHours } from '../pages/sleep-hours';
import { HowMotivated } from '../pages/how-motivated';
import { WellnessGoal } from '../pages/wellness-goal';
import { HearSource } from '../pages/hear-source';
import { GoalChart } from '../pages/goal-chart';
import { OutsideMotivation } from '../pages/outside-motivation';
import { FeelFailure } from '../pages/feel-failure';
import { OldHabits } from '../pages/old-habits';
import { PeopleAround } from '../pages/people-around';
import { BodyHeightWeight } from '../pages/body-height-weight';
import { BodyIdealWeight } from '../pages/body-ideal-weight';
import { Email } from '../pages/email';
import { HitGoalChart } from '../pages/hit-goal-chart';
import { HelpedThousands } from '../pages/helped-thousands';
import { Testimonials } from '../pages/testimonials';
import { TrialPay } from '../pages/trial-pay';
import { Payment } from '../pages/payment';
import { Subscribe } from '../pages/subscribe';
import { ProgramBuilder } from '../pages/program-builder';
import { YouAreAble } from '../pages/you-are-able';
import { PersonalizedProgram } from '../pages/personalized-program';
import { AccessAccount } from '../pages/access-account';
import { Congrats } from '../pages/congrats';
import { SkipTrial } from '../pages/skip-trial';
import { Addons } from '../pages/addons';
import { Wait } from '../pages/wait';

export const RouterConfig = () => {
    return (
        <Routes>
            {/* List all public routes here */}
            <Route path={RoutePath.MainGoal} element={<MainGoal />} />
            <Route path={RoutePath.BodyGender} element={<BodyGender />} />
            <Route path={RoutePath.BodyAge} element={<BodyAge />} />
            <Route path={RoutePath.BodyType} element={<BodyType />} />
            <Route path={RoutePath.WeightLoss} element={<WeightLoss />} />
            <Route path={RoutePath.BodyBreastfeeding} element={<BodyBreastfeeding />} />
            <Route path={RoutePath.BodyRisk} element={<BodyRisk />} />
            <Route path={RoutePath.OurMission} element={<OurMission />} />
            <Route path={RoutePath.WeightGoal} element={<WeightGoal />} />
            <Route path={RoutePath.HappyWeight} element={<HappyWeight />} />
            <Route path={RoutePath.LoseWeight} element={<LoseWeight />} />
            <Route path={RoutePath.TypicalDay} element={<TypicalDay />} />
            <Route path={RoutePath.AchieveGoal} element={<AchieveGoal />} />
            <Route path={RoutePath.NewsOffer} element={<NewsOffer />} />
            <Route path={RoutePath.GoalChart} element={<GoalChart />} />
            <Route path={RoutePath.DietActivity} element={<DietActivity />} />
            <Route path={RoutePath.LifestyleAdvice} element={<LifestyleAdvice />} />
            <Route path={RoutePath.WeightGain} element={<WeightGain />} />
            <Route path={RoutePath.PastTrials} element={<PastTrials />} />
            <Route path={RoutePath.PersonalJourney} element={<PersonalJourney />} />
            <Route path={RoutePath.CurrentLifestyle} element={<CurrentLifestyle />} />
            <Route path={RoutePath.HealthyFridge} element={<HealthyFridge />} />
            <Route path={RoutePath.ConsumedItems} element={<ConsumedItems />} />
            <Route path={RoutePath.EatTimes} element={<EatTimes />} />
            <Route path={RoutePath.PlanFits} element={<PlanFits />} />
            <Route path={RoutePath.AverageBusy} element={<AverageBusy />} />
            <Route path={RoutePath.SmallChanges} element={<SmallChanges />} />
            <Route path={RoutePath.WorkoutTimes} element={<WorkoutTimes />} />
            <Route path={RoutePath.WithoutWorkout} element={<WithoutWorkout />} />
            <Route path={RoutePath.MovementImportant} element={<MovementImportant />} />
            <Route path={RoutePath.GlassesWater} element={<GlassesWater />} />
            <Route path={RoutePath.SleepHours} element={<SleepHours />} />
            <Route path={RoutePath.SleepGameChanger} element={<SleepGameChanger />} />
            <Route path={RoutePath.HowMotivated} element={<HowMotivated />} />
            <Route path={RoutePath.OutsideMotivation} element={<OutsideMotivation />} />
            <Route path={RoutePath.BodyAndMind} element={<BodyAndMind />} />
            <Route path={RoutePath.OldHabits} element={<OldHabits />} />
            <Route path={RoutePath.PeopleAround} element={<PeopleAround />} />
            <Route path={RoutePath.FeelFailure} element={<FeelFailure />} />
            <Route path={RoutePath.UpsAndDown} element={<UpsAndDown />} />
            <Route path={RoutePath.WellnessGoal} element={<WellnessGoal />} />
            <Route path={RoutePath.RealisticTargets} element={<RealisticTargets />} />
            <Route path={RoutePath.HearSource} element={<HearSource />} />
            <Route path={RoutePath.BodyHeightWeight} element={<BodyHeightWeight />} />
            <Route path={RoutePath.BodyIdealWeight} element={<BodyIdealWeight />} />
            <Route path={RoutePath.Email} element={<Email />} />
            <Route path={RoutePath.HitGoalChart} element={<HitGoalChart />} />
            <Route path={RoutePath.HelpedThousands} element={<HelpedThousands />} />
            <Route path={RoutePath.Testimonials} element={<Testimonials />} />
            <Route path={RoutePath.ProgramBuilder} element={<ProgramBuilder />} />
            <Route path={RoutePath.YouAreAble} element={<YouAreAble />} />
            <Route path={RoutePath.PersonalizedProgram} element={<PersonalizedProgram />} />
            <Route path={RoutePath.TrialPay} element={<TrialPay />} />
            <Route path={RoutePath.Subscribe} element={<Subscribe />} />
            <Route path={RoutePath.Payment} element={<Payment />} />
            <Route path={RoutePath.Congrats} element={<Congrats />} />
            <Route path={RoutePath.SkipTrial} element={<SkipTrial />} />
            <Route path={RoutePath.Addons} element={<Addons />} />
            <Route path={RoutePath.AddonsDiscount} element={<Addons />} />
            <Route path={RoutePath.Wait} element={<Wait />} />
            <Route path={RoutePath.AccessAccount} element={<AccessAccount />} />

            {/* List a generic 404-Not Found route here */}
        </Routes>
    );
};
