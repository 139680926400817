import {ChoiceType} from '../components/choice-button';

export const AGES: ChoiceType[] = [
    { key: 'underTwenty', value: 'Under 20' },
    { key: 'twentys', value: '20s' },
    { key: 'thirtys', value: '30s' },
    { key: 'fortys', value: '40s' },
    { key: 'fiftys', value: '50s' },
    { key: 'sixtyPlus', value: '60s+' },
];
