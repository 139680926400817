import React from "react";

import { Typography } from '../../../components/typography';
import { TypographyVariants } from '../../../constants';

import { useStyles } from "./styles";

export const DayTrial = () => {
    const { banner, title } = useStyles();

    return (
        <div className={banner}>
            <Typography className={title} variant={TypographyVariants.h1}>
                Start Your 7-Day Trial
            </Typography>
        </div>
    )
};
