import liz from '../../../assets/images/png/subscribe/reviews/liz.png';
import michael from '../../../assets/images/png/subscribe/reviews/michael.png';
import melisa from '../../../assets/images/png/subscribe/reviews/melisa.png';
import ali from '../../../assets/images/png/subscribe/reviews/ali.png';
import samanta from '../../../assets/images/png/subscribe/reviews/samanta.png';

export const reviewsConfig = [
    {
        name: "Liz Hoffman",
        avatar: liz,
        review: 'I’ve lost 20 lbs in 3 months! The knowledge and confidence I’ve gained is tenfold. I have an amazing coach who is always there to cheer me on and help me combat my “all or nothing” mindset. I’m so glad to have her and Able to help me change my relationship with food!'
    },
    {
        name: "Michael",
        avatar: michael,
        review: 'I love having a personal coach. It’s helped me keep going. What I’ve learned from my Able coach will stick with me forever!! :)'
    },
    {
        name: "Melisa Taylor",
        avatar: melisa,
        review: 'I haven’t had anything work so consistently and in such a well-rounded way. I’m losing weight, but I’m also building habits that are going to make this change sustainable! Thank you, Able!'
    },
    {
        name: "Ali",
        avatar: ali,
        review: 'With Able, you focus on your own personal needs, habits, and responses. The process is an incremental build up to better understanding the ways you think about food, your choices, and your goals. I feel really supported and accepted to do this at my own pace.'
    },
    {
        name: "Samanta",
        avatar: samanta,
        review: 'I love setting weekly goals with my personal coach. One month on Able and I lost 8 pounds! I’m excited to keep hitting my goals.'
    }
];
