import { createUseStyles } from 'react-jss';
import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ animatedPage }: ThemeProps) => ({
    animationPageWrapper: {
        ...animatedPage.animationPageWrapper,
    },
    animatedText: {
        opacity: 0,

        '&$first': {
            animation: `$animationFirst 4s 1 0s`,
        },
        '&$second': {
            animation: `$animationSecond 10s 1 0s`,
        },
        '&$third': {
            animation: `$animationThird 6s 1 0s`,
        },
        '&$hidden': {
            display: 'none',
        },
    },
    '@keyframes animationFirst': {
        '0%': {
            opacity: 0,
        },
        '20%': {
            opacity: 1,
        },
        '80%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes animationSecond': {
        '0%': {
            opacity: 0,
        },
        '20%': {
            opacity: 1,
        },
        '80%': {
            opacity: 1,
        },
        '90%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes animationThird': {
        '0%': {
            opacity: 0,
        },
        '20%': {
            opacity: 1,
        },
        '100%': {
            opacity: 1,
        },
    },
    first: {},
    second: {},
    third: {},
    hidden: {},
    '@keyframes animation': {
        '100%': {
            opacity: 1
        }
    },
    '@media screen and (max-width: 320px)': { // iPhone 4 resolution
        animationPageWrapper: {
            height: 'calc(100vh + 106px)'
        },
    },
}));
