import React, { FC, useEffect } from 'react';

import {ftValidationQuery, inchesValidationQuery} from './ft-inches-validation-query';

import {ValidatedInput} from '../../../components/validated-input';

import { useStyles } from "./styles";

type FtAndInputsProps = {
    ft: string,
    inches: string,
    autoFocus?: boolean,
    onError: (error: boolean, key: string) => void,
    onChangeFt: (e: any) => void;
    onChangeInches: (e: any) => void;
}

export const FtInchesInputs: FC<FtAndInputsProps> = ({
    ft,
    inches,
    autoFocus,
    onError,
    onChangeFt,
    onChangeInches
}) => {
    const { wrapper, inputWrapperClass } = useStyles();
    const handleError = (key: string) => (error: boolean) => onError(error, key);

    useEffect(() => () => {
        onError(false, 'ft');
        onError(false, 'inches');
    }, []);

    return (
        <div className={wrapper}>

            <ValidatedInput
                type="number"
                value={ft}
                autoFocus={autoFocus}
                onError={handleError('ft')}
                onChange={onChangeFt}
                validationQuery={ftValidationQuery}
                endAdornment={'ft'}
                wrapperClass={inputWrapperClass}
                {... { label: 'Height', maxLength: 1 }}
            />

            <ValidatedInput
                type="number"
                value={inches}
                onError={handleError('in')}
                onChange={onChangeInches}
                validationQuery={inchesValidationQuery}
                endAdornment={'in'}
                wrapperClass={inputWrapperClass}
                {... { label: 'Height', maxLength: 2 }}
            />

        </div>
    )
}
