import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
      padding: '0 0px 32px 0px',
    },
    header: {
        display: 'flex',
        flex: 1,
        fontWeight: 600,
        letterSpacing: '-0.0024em',
        marginBottom: '16px',
    },
    reviewWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'start',
        alignItems: 'stretch',
        overflowY: 'hidden',
        overflowX: 'scroll',
    },
    '@media (max-width: 500px)' : {
        reviewWrapper: {
            position: 'relative',
            width: '100vw',
            left: 'calc(-50vw + 50%)',
            padding: '0 16px',
        }
    },
});
