import { createUseStyles } from 'react-jss';

import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ ratingScale }: ThemeProps) => ({
    ratingScaleWrapper: {
        ...ratingScale.ratingScaleWrapper,
    },
    ratingScale: {
        ...ratingScale.ratingScale,
    },
    ratingGradationInfoWrapper: {
        ...ratingScale.ratingGradationInfoWrapper,
    }
}));
