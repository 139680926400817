import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    floatedButtonWrapper: {
        width: '100%',
        position: 'sticky',
        bottom: 0,
        left: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gradient: {
        maxWidth: 'inherit',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 21.6%)',
    },
    padding: {
        padding: '0 16px 48px',
    }
});
