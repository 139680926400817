import { Palette } from '../index';

export type RatingScaleType = {
    ratingScaleWrapper: {
        display: string,
        flexDirection: string,
        justifyContent: string,
    },
    ratingScale: {
        display: string,
        flexDirection: string,
        justifyContent: string,
        width: string,
    },
    ratingButton: {
        background: Palette | string,
        padding: string,
        width: string,
        height: string,
        border: string,
        color: Palette | string,
        borderRadius: string,
        cursor: string,
    },
    ratingButtonSelected: {
        background: Palette | string,
        color: Palette | string,
    },
    ratingButtonActive: {
        boxShadow: string,
    },
    ratingGradationInfoWrapper: {
        display: string,
        justifyContent: string,
        alignItems: string,
        height: string,
        width: string,
        margin: string,
    },
}

export const RatingScaleStyles: RatingScaleType = {
    ratingScaleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    ratingScale: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    ratingButton: {
        background: '#F6F6F6',
        padding: '16px',
        width: '62px',
        height: '56px',
        border: 'none',
        color: '#202B47',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    ratingButtonSelected: {
        background: '#4B46EC',
        color: '#F6F6F6',
    },
    ratingButtonActive: {
        boxShadow: 'rgb(145 156 248 / 50%) 0px 0px 0px 0.2rem',
    },
    ratingGradationInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '18px',
        width: '100%',
        margin: '8px 0',
    },
};
