import React, { useContext, useEffect, useState } from 'react';

import { TypographyVariants } from '../../constants';

import { PreloadImageServiceInstanceCTX } from "../../services/preload-image.service";

import { Typography } from '../typography';

import { useStyles } from "./styles";

type VerticalCardProps = {
    data: {
        text: string,
        image: string,
        description?: string
    },
    onClick: (value: any) => void,
    isSelected: boolean,
}

export const VerticalCard = ({ data, onClick, isSelected }: VerticalCardProps) => {
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);

    const [imageData, setImageData] = useState('');

    const {
        verticalCard,
        verticalCardSelected,
    } = useStyles();

    const { text, description } = data;

    useEffect(() => {
        (async () => {
            if (data?.image) {
                const img = await getImageByLocalPath(data?.image);
                setImageData(img as string);
            }
        })();
    }, [data?.image, getImageByLocalPath]);

    return (
        <button
            onClick={() => onClick(data)}
            className={`${verticalCard} ${isSelected ? verticalCardSelected : ''}`}
        >
            <div>
                <Typography
                    variant={TypographyVariants.verticalCard}
                >
                    {text}
                </Typography>
                {
                    description && (
                        <Typography
                            variant={TypographyVariants.verticalCardDescription}
                        >
                            {description}
                        </Typography>
                    )
                }
            </div>
            {imageData && <img src={imageData} alt={text}/>}
        </button>
    )
}
