import React from 'react';

import { Review } from './review';
import { Typography } from '../../../components/typography';
import { TypographyVariants } from '../../../constants';

import { reviewsConfig } from './config';
import { useStyles } from './styles';

type ReviewType = {
    name: string;
    avatar: string;
    review: string | (() => {});
}

export const CustomerReviews = () => {
    const { header, wrapper, reviewWrapper } = useStyles();

    return (
        <div className={wrapper}>
            <Typography className={header} variant={TypographyVariants.h2}>
                Customer reviews
            </Typography>
            <div className={reviewWrapper}>
                {reviewsConfig.map((review: ReviewType) => <Review key={review.name} {...review} />)}
            </div>

        </div>
    )
}
