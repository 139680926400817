import { RoutePath } from '../routes/route-path.constant';

export enum TypographyVariants {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    p = 'p',
    span = 'span',
    label = 'label', // map top span ?
    button = 'buttonTypography',
    choiceButton = 'choiceButtonTypography',
    multiChoiceButton = 'multiChoiceButtonTypography',
    inputLabel = 'inputLabel',
    inputStartAdornment = 'inputStartAdornment',
    inputEndAdornment = 'inputEndAdornment',
    validatedInputError = 'validatedInputError',
    verticalCard = 'verticalCard',
    verticalCardDescription = 'verticalCardDescription',
    coloredPage = 'coloredPage',
    tab = 'tabTypography',
    ratingButton = 'ratingButtonTypography',
    ratingGradationInfo = 'ratingGradationInfoTypography',
}

export const UPDATE_STORAGE_EVENT = 'onStoreUpdated';

export const OPTIMIZELY_ATTRIBUTES = {
    audience: `platform = "web"`,
    platform: "web"
};

export const LocalStorageKeys = {
    [RoutePath.MainGoal]: 'goal',
    [RoutePath.BodyGender]: 'gender',
    [RoutePath.BodyAge]: 'age',
    [RoutePath.BodyType]: 'body-type',
    [RoutePath.BodyBreastfeeding]: 'isBreastfeeding',
    [RoutePath.BodyRisk]: 'body-risk',
    [RoutePath.HappyWeight]: 'happy-weight',
    [RoutePath.LoseWeight]: 'lose-weight',
    [RoutePath.TypicalDay]: 'activity',
    [RoutePath.AchieveGoal]: 'achieve-goal',
    [RoutePath.NewsOffer]: 'news-offer',
    [RoutePath.DietActivity]: 'diet-activity',
    [RoutePath.WeightGain]: 'weight-gain',
    [RoutePath.PastTrials]: 'past-trials',
    [RoutePath.CurrentLifestyle]: 'current-lifestyle',
    [RoutePath.HealthyFridge]: 'healthy-fridge',
    [RoutePath.ConsumedItems]: 'consumed-items',
    [RoutePath.EatTimes]: 'eat-times',
    [RoutePath.PlanFits]: 'plan-fits',
    [RoutePath.AverageBusy]: 'average-busy',
    [RoutePath.WorkoutTimes]: 'workout-times',
    [RoutePath.WithoutWorkout]: 'without-workout',
    [RoutePath.GlassesWater]: 'glasses-water',
    [RoutePath.SleepHours]: 'sleep-hours',
    [RoutePath.HowMotivated]: 'how-motivated',
    [RoutePath.OutsideMotivation]: 'outside-motivation',
    [RoutePath.OldHabits]: 'old-habits',
    [RoutePath.PeopleAround]: 'people-around',
    [RoutePath.FeelFailure]: 'feel-failure',
    [RoutePath.WellnessGoal]: 'wellness-goal',
    [RoutePath.HearSource]: 'hear-source',
    [RoutePath.BodyIdealWeight]: 'weightGoal',
    [RoutePath.Email]: 'email',
    [RoutePath.TrialPay]: 'planCode',
};

export const MultipleLocalStorageKeys = {
    [RoutePath.BodyHeightWeight]: {
        unitSystem: 'body-height-weight-unitSystem',
        heightCm: 'body-height-cm',
        heightFt: 'body-height-ft',
        heightInches: 'body-height-inches',
        weightLbs: 'body-weight-lbs',
        weightKg: 'body-weight-kg'
    },
};
