import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const WithoutWorkout = () => {
    const { pageValue: withoutWorkout, setPageValue }: any = useLocalStorage({ key: LocalStorageKeys[RoutePath.WithoutWorkout], defaultValue: '' });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'yes', value: 'Yes' },
        { key: 'no', value: 'No' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                Are you looking to lose weight without working out?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == withoutWorkout}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
