import { Typography } from '../typography';
import { TypographyVariants } from '../../constants';

import { useStyles } from './styles';
import closeIconSvg from '../../assets/images/svg/close.svg';
import React, { ChangeEvent, FC, FocusEvent, useRef, useState } from 'react';

const isValueSet = (value: string | number | undefined, type = 'text'): boolean => {
    if (type === 'number') {
        return Number.isInteger(parseInt(value as string));
    }
    return !!value;
};

export interface InputProps {
    value?: string | number;
    type?: string;
    label?: string;
    disabled?: boolean;
    autoFocus?: boolean;

    onFocus?: (e: FocusEvent) => void;
    onBlur?: (e: FocusEvent) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement> | Event)=> void;

    inputWrapperClassName?: string;
    inputLabelClassName?: string;


    inputClassName?: string;

    error?: boolean;

    startAdornment?: string;
    endAdornment?: string;
    closeIcon?: boolean;
}

export const Input: FC<InputProps> = ({
        value,
        type,
        label,
        error,
        onFocus,
        onChange,
        startAdornment,
        endAdornment,
        onBlur,
        inputWrapperClassName,
        inputLabelClassName,
        closeIcon,
        ...props
    }) => {

    const {
        inputWrapper,
        input,
        inputLabelClass,
        inputCloseIconClass,
        inputActiveClass,
        inputDisabledClass,
        inputErrorClass,
        inputLabelActiveClass,
        inputLabelErrorClass,
        inputEndAdornmentClass
    } = useStyles();

    const inputEl = useRef({} as HTMLInputElement);

    const [activeClass, setActiveClass] = useState(false);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        if (disabled) return;

        setActiveClass(true);

        if (onFocus) {
            onFocus(e);
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e);
        }
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setActiveClass(!!e.target.value);

        if(onBlur) {
            onBlur(e);
        }
    }

    const handleClean = () => {
        const evt = new Event('change');
        const { current } = inputEl;

        current.value = '';

        const event = {
            ...evt,
            target: current,
            currentTarget: current
        }

        if (onChange) {
            onChange(event);
        }

        current.focus();
    }

    const disabled = false;

    const inputType =  type ? type : 'text';

    const combinedInputClass = `${input} ${disabled ? inputDisabledClass: ''}`;

    const combinedInputWrapperClass = `${inputWrapper} ${inputWrapperClassName} ${disabled ? inputDisabledClass : ''} ${activeClass ? inputActiveClass : '' } ${error ? inputErrorClass : '' }`;

    const combinedInputLabelClass = `${inputLabelClass} ${inputLabelClassName} ${isValueSet(value, inputType) || activeClass ? inputLabelActiveClass : ''} ${error ? inputLabelErrorClass : '' }`;

    const isEndAdornmentShown = activeClass || error;

    return (
        <div className={combinedInputWrapperClass}>
            {startAdornment && (
                <Typography variant={TypographyVariants.inputStartAdornment}>
                    {startAdornment}
                </Typography>
            )}

            {label && (
                <Typography variant={TypographyVariants.inputLabel} className={combinedInputLabelClass}>
                    {label} {endAdornment && !isEndAdornmentShown ? `- ${endAdornment}` : ''}
                </Typography>
            )}
            <input
                ref={inputEl}
                type={inputType}
                disabled={disabled}
                onBlur={handleBlur}
                onFocus={handleFocus}
                className={combinedInputClass}
                value={value}
                onChange={handleChange}
                {...props}
            />
            {endAdornment && isEndAdornmentShown && (
                <Typography variant={TypographyVariants.inputEndAdornment} className={inputEndAdornmentClass}>
                    {endAdornment}
                </Typography>
            )}
            {closeIcon && value && (
                <img className={inputCloseIconClass} onClick={handleClean} src={closeIconSvg} alt="Reset"/>
            )}
        </div>
        );
}
