import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    specialOffer: {
        color: '#36B992',
        border: '1px solid #36B992',
        borderRadius: '10px',
        padding: '8px 10px',
        width: '110px',
        fontWeight: 600,
        margin: '0 auto',
        marginBottom: '24px',
        marginTop: '32px',
        whiteSpace: 'nowrap',
    },
    title: {
        lineHeight: '35px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    subtitle: {
        fontWeight: 600,
        paddingBottom: '24px',
        borderBottom: '1px solid #868C99',
    },
    totalPriceWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 0',
        borderBottom: '1px solid #868C99',
    },
    totalPrice: {
        fontWeight: 600,
        lineHeight: '24px',
        margin: 0,
    },
    totalPriceCount: {
        fontWeight: 600,
        color: '#4B46EC',
        margin: 0,
    },
    codeHint: {
        color: '#36B992',
        margin: '6px 0 6px 0',
        textAlign: 'left',
        fontWeight: 600,
    },
    costPerWeekPrice: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        textAlign: 'left',
    },
    costPerWeekText: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 1,
        margin: '0 0 2px',
        letterSpacing: '0.2px',
    },
    initialPrice: {
        lineHeight: '24px',
        color: '#C5C5D1',
        marginRight: '6px',
    },
    finalPrice: {
        fontWeight: 600,
        lineHeight: '24px',
        margin: 0,
    },
    howMuchSaveText: {
        color: '#8E8E93',
        textAlign: 'left',
        paddingBottom: '12px',
        borderBottom: '1px solid #868C99',
    },
    reminderText: {
        fontStyle: 'italic',
        textAlign: 'left',
        padding: '12px 0',
    }
});
