import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLocalStorage } from './useLocalStorage';

import { ABPagesConfiguration } from '../services/ABPagesConfiguration';

import { RoutePath } from '../routes/route-path.constant';
import { LocalStorageKeys, MultipleLocalStorageKeys } from '../constants';
import { UNIT_SYSTEM } from '../constants/body-height-weight';

const sortAvailableRouters = (availableRouters: string[]) => {
    const layoutVariation = localStorage.getItem('layoutVariation');
    const routesList = Object.keys(ABPagesConfiguration(layoutVariation));

    return availableRouters.sort((a, b) => routesList.indexOf(a) - routesList.indexOf(b));
};

const getLastAvailableRouter = (availableRouters: string[]) => availableRouters[availableRouters.length - 1];

export const usePageAccessChecking = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(search);

    const [routerAvailable, setRouterAvailable] = useState(false);
    const {
        pageValue: availableRouters,
        setPageValue: setAvailableRouters
    } = useLocalStorage({
        key: 'available-routes',
        defaultValue: [RoutePath.MainGoal]
    });

    useEffect(() => {

        let routes: string[] = [...availableRouters, RoutePath.MainGoal];

        //@description: Encharge - fill the storage and redirect user back to the onboarding
        if (search) {

            const addNewRoutes = (newRoute: string) => {
                if (!routes.includes(newRoute)) {
                    routes.push(newRoute);
                }
            };

            for (let param of searchParams) {
                const key = param[0].toLowerCase();

                if (key === 'gender') {
                    addNewRoutes(RoutePath.BodyGender);

                    localStorage.setItem(LocalStorageKeys[RoutePath.BodyGender], param[1]);
                } else if (key === 'userage') {
                    addNewRoutes(RoutePath.BodyAge);

                    localStorage.setItem(LocalStorageKeys[RoutePath.BodyAge], param[1]);
                } else if (key === 'bodyweightmetricsystem') {
                    addNewRoutes(RoutePath.BodyHeightWeight);

                    const unitSystem = param[1] === 'kg' ? UNIT_SYSTEM.metric : UNIT_SYSTEM.imperial;

                    localStorage.setItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].unitSystem, unitSystem);
                } else if (key === 'bodyheightmetricsystem') {
                    addNewRoutes(RoutePath.BodyHeightWeight);

                    const unitSystem = param[1] === 'cm' ? UNIT_SYSTEM.metric : UNIT_SYSTEM.imperial;

                    localStorage.setItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].unitSystem, unitSystem);
                } else if (key === 'currentbodyweight') {
                    addNewRoutes(RoutePath.BodyHeightWeight);

                    const tab = searchParams.get('BodyWeightMetricSystem') === 'kg'; // true for metric; false for imperial

                    if (tab) {
                        localStorage.setItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].weightKg, param[1]);
                    } else {
                        localStorage.setItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].weightLbs, param[1]);
                    }
                } else if (key === 'currentbodyheight') {
                    addNewRoutes(RoutePath.BodyHeightWeight);

                    const tab = searchParams.get('BodyHeightMetricSystem') === 'cm'; // true for metric; false for imperial

                    if (tab) {
                        localStorage.setItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].heightCm, param[1]);
                    } else {
                        localStorage.setItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].heightFt, param[1]);
                        localStorage.setItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].heightInches, '0');
                    }
                } else if (key === 'goalbodyweight' || key === 'targetweight') {
                    addNewRoutes(RoutePath.BodyIdealWeight);

                    localStorage.setItem(LocalStorageKeys[RoutePath.BodyIdealWeight], param[1]);
                } else if (key === 'email') {
                    addNewRoutes(RoutePath.Email);

                    localStorage.setItem(LocalStorageKeys[RoutePath.Email], param[1]);
                } else {
                    localStorage.setItem(param[0], param[1]);
                }
            }

            routes = sortAvailableRouters([...routes]);

            const position = searchParams.get('Position');

            if (position?.toLowerCase() === 'afteremail') {
                routes.push(RoutePath.GoalChart);
            }

            if (position?.toLowerCase() === 'paywall') {
                routes.push(RoutePath.Subscribe);
            }

            setAvailableRouters(routes);

            navigate(`..${getLastAvailableRouter(routes)}`);
        } else {
            if (routes.includes(pathname)) {
                setRouterAvailable(true);
            } else {
                navigate(`..${getLastAvailableRouter(routes)}`);
            }
        }
    }, [search]);

    useEffect(() => {
        if (!availableRouters.includes(pathname)) {
            setRouterAvailable(true);
            setAvailableRouters([...availableRouters, pathname])
        }
    }, [pathname]);

    return {
        routerAvailable
    }
};
