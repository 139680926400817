import { MultipleLocalStorageKeys } from './index';

import { RoutePath } from '../routes/route-path.constant';

export const UNIT_SYSTEM = {
    imperial: 'imperial',
    metric: 'metric',
};

export const TABS = [
    { key: UNIT_SYSTEM.imperial, label: 'ft and in'},
    { key: UNIT_SYSTEM.metric, label: 'cm'}
];

export const getUnitSystem = () => localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].unitSystem);
