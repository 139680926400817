import React from "react";

import { useStyles } from "./styles";

type FloatedButtonProps = {
    floated?: boolean,
    withGradient?: boolean,
    bottomPadding?: boolean,
    children?: any
}

export const FloatedButton = ({ children, floated, withGradient, bottomPadding }: FloatedButtonProps) => {
    const { floatedButtonWrapper, gradient, padding } = useStyles();

    if (!floated) return children;

    let floatedButtonClass = `${floatedButtonWrapper}`;

    if (withGradient) {
        floatedButtonClass += ` ${gradient}`;
    }

    if (bottomPadding) {
        floatedButtonClass += ` ${padding}`;
    }

    return (
        <div className={floatedButtonClass}>
            {children}
        </div>
    )
};
