import React from 'react';

import { Typography } from '../../../components/typography';
import { TypographyVariants } from '../../../constants';
import { DiscountIcon } from './discount-icon';

import { useStyles } from './styles';

export const DiscountBlock = () => {
    const { wrapper, text } = useStyles();

    return (
        <div className={wrapper}>
            <DiscountIcon />
            <Typography className={text} variant={TypographyVariants.h3}>
                Additional 25% off discount applied!
            </Typography>
        </div>
    );
};
