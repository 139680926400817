import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    mainContent: {
        maxWidth: 560,
        margin: '0 auto',
        position: 'relative',
        padding: ({ scrollableContent }: any) => scrollableContent ? '0px 16px 110px' : '0px 16px'
    }
});
