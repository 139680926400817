// node modules
import { createUseStyles } from 'react-jss'

import {ThemeProps} from '../../themes';

export const useStyles = createUseStyles(({ choiceButton }: ThemeProps) => ({
    choiceButton: {
        ...choiceButton.choiceButton,
        display: 'flex',
        alignItems: 'center',
        '&:active': {
            ...choiceButton.choiceButtonActive,
        }
    },
    choiceButtonSelected: choiceButton.choiceButtonSelected,
    textAlignCenter: {
        justifyContent: 'center',

    }
}));
