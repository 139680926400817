import React from 'react';

import {
    GoalTypes,
    longValueGetter,
    valueGetter,
    LOSE_WEIGHT_BODY_GOAL,
    BOTH_BODY_GOAL,
} from './config';

import triangleIcon from '../../../assets/images/svg/addons/modal-content/triangle-icon.svg';
import cupIcon from '../../../assets/images/svg/addons/modal-content/cup.svg';

import { Typography } from '../../../components/typography';
import { LocalStorageKeys, TypographyVariants } from '../../../constants';
import { NextButton } from '../../../components/next-button';

import { useStyles } from './styles';
import { RoutePath } from '../../../routes/route-path.constant';

type ModalContentProps = {
    handleClose: () => void;
}

export const ModalContent = ({ handleClose }: ModalContentProps) => {
    const {
        wrapper, title, subtitle, textWrapper, planLabel, finalText,
        triangle, label, labelWrapper, imageContent, ableLabel, gainWrapper,
    } = useStyles();
    const goal = localStorage.getItem(LocalStorageKeys[RoutePath.MainGoal]) || LOSE_WEIGHT_BODY_GOAL;

    const longText = longValueGetter[goal as GoalTypes];
    const shortText = valueGetter[goal as GoalTypes];
    const labelWrapperClass = `${labelWrapper} ${BOTH_BODY_GOAL === goal ? gainWrapper : ''}`;

    return (
        <div className={wrapper}>
            <Typography className={title} variant={TypographyVariants.h1}>
                Did you know?
            </Typography>
            <Typography className={subtitle} variant={TypographyVariants.h3}>
                Able members who start their journey with our Premium Bundle <span>{longText}</span>
            </Typography>
            <div className={imageContent}>
                <div className={labelWrapperClass}>
                    <div className={textWrapper}>
                        <img className={triangle} src={triangleIcon} alt='' />
                        <Typography className={label} variant={TypographyVariants.h3}>
                            {shortText}
                        </Typography>
                    </div>
                    <img src={cupIcon} alt='' />
                </div>
                <Typography className={planLabel} variant={TypographyVariants.h3}>
                    Able + Plan
                </Typography>
                <Typography className={ableLabel} variant={TypographyVariants.h3}>
                    Able
                </Typography>
            </div>
            <Typography variant={TypographyVariants.h3} className={`${subtitle} ${finalText}`}>
                We want you to be satisfied, so we’re offering <span>25% off</span> Premium Bundle, just for you!
            </Typography>
            <NextButton
                onClick={handleClose}
                typographyText={'Continue'}
            />
        </div>
    );
};
