import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import {BaseContext} from '../../context/base.context';

import { Typography } from '../../components/typography';
import { TypographyVariants } from '../../constants';
import { disableBrowserBackButton } from '../../helpers/disableBrowserBackButton';

import { useStyles } from './styles';

export const Congrats = () => {
    const navigate = useNavigate();

    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    useEffect(() => {
        setTimeout(() => pageConfiguration.handleOnPageComplete(), 4000)
    }, [navigate]);

    const { content, title, text } = useStyles();

    return (
        <div>
            <div className={content}>
                <Typography
                    variant={TypographyVariants.h1}
                    className={title}
                >
                    Congratulations!
                </Typography>
                <Typography
                    variant={TypographyVariants.h2}
                    className={text}
                >
                    We’re excited to be a part of your health<br/>
                    journey. Please do not click back or refresh<br/>
                    the page while we finish creating your<br />
                    account.
                </Typography>
            </div>
        </div>
    );
};
