import { createUseStyles } from "react-jss";

const commonStylePrice = {
    fontWeight: 400,
    textAlign: 'center'
}

const commonBorderStyles = {
    borderTop: '1px solid #E0E3EB',
    paddingTop: 12
}

export const useStyles = createUseStyles({
    wrapper: {
        borderRadius: '8px',
        padding: '16px',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)'
    },
    title: {
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: 600,
        margin: '0 0 12px',
    },
    block: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px'
    },
    blockTitle: {
        margin: 0,
    },
    oneTimePriceWrapper: {
        ...commonBorderStyles
    },
    oneTimePrice: {
        color: '#FE8F20',
        marginBottom: '4px',
        fontSize: '16px',
        lineHeight: '18.75px',
        fontWeight: 600,
    },
    originalPriceClass: {
        ...commonStylePrice,
    },
    keepPlan: {
        ...commonStylePrice,
        ...commonBorderStyles,
        marginTop: '12px',
        '& span': {
            fontWeight: 600
        }
    }
});
