import React, { useContext } from 'react';

import { BaseContext } from '../../context/base.context';
import { useLocalStorage } from '../../hooks/useLocalStorage';

import { RoutePath } from '../../routes/route-path.constant';
import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { RatingButton } from '../../components/rating-button';

import { useStyles } from './styles';
import { NextButton } from '../../components/next-button';
import { PLANCODES } from '../../constants/trial-pay';

export const TrialPay = () => {

    const { text, firstParagraph, boldText, blueText, ratingScaleWrapper, ratingScale, ratingButton, ratingText, popularChoice } = useStyles();

    const { pageConfiguration, optimizely } = useContext(BaseContext);

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.TrialPay],
        defaultValue: {},
    });

    const handleClick = () => {
        pageConfiguration.event('OnboardingTrialPayNextClicked', {
            value: pageValue?.value,
        });

        // @TODO: Track for now, better to rewrite it if we'll use optimizely
        optimizely.track('WebTrialPayNextClicked', pageConfiguration.getUserId());

        pageConfiguration.handleOnPageComplete(pageValue);
    };

    return (
        <>
            <Typography variant={TypographyVariants.h2} typographyNext={true} className={`${text} ${firstParagraph}`}>
                We’ve helped millions of people to get fit and lose weight, and we want to help you too.
            </Typography>

            <Typography variant={TypographyVariants.h2} typographyNext={true} className={`${text} ${boldText}`}>
                Money shouldn’t stand in the way of finding a plan that finally works. So, choose an amount that you think is reasonable to try us out for one week.
            </Typography>

            <Typography variant={TypographyVariants.h2} className={`${text} ${blueText}`}>
                It costs us $13.21 to compensate our Able coaches for the trial, but please choose the amount you are comfortable with.
            </Typography>

            <div className={ratingScaleWrapper}>
                <div className={ratingScale}>
                    {
                        PLANCODES?.map((plan, i) =>
                            <div key={plan.key}>
                                <RatingButton
                                    rating={plan}
                                    selected={plan.key === pageValue?.key}
                                    buttonClass={ratingButton}
                                    buttonTextClass={ratingText}
                                    handleClick={() => setPageValue(plan)}
                                />
                                {i === PLANCODES.length - 1 ? (
                                    <Typography variant={TypographyVariants.h3} className={popularChoice}>
                                        Most
                                        <br />
                                        popular
                                        <br />
                                        choice*
                                    </Typography>
                                ) : (
                                    ""
                                )}
                            </div>
                        )
                    }
                </div>
            </div>
            <NextButton onClick={() => handleClick()} disabled={!pageValue.key} />
        </>
    );
};
