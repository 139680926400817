import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    listItem: {
        display: 'flex',
        marginBottom: '12px',

        '& svg': {
            height: '24px',
            minWidth: '24px',
        },
    },
    highLightList: {
        padding: '24px 16px 0px 16px',
    },
    listItemText: {
        textAlign: 'left',
        marginLeft: '6px',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#232323',
    }
});
