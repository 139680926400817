import { createUseStyles } from 'react-jss';

import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ ratingScale }: ThemeProps) => ({
    ratingButton: {
        ...ratingScale.ratingButton,
        '&:active': {
            ...ratingScale.ratingButtonActive,
        }
    },
    '@media screen and (max-width: 320px)': { // iPhone 4 resolution
        ratingButton: {
            width: '52px',
        },
    },
    ratingButtonSelected: ratingScale.ratingButtonSelected,
}));
