export const isBoolean = (val: any) => typeof val === 'boolean';

export const isString = (val: any) => typeof val === 'string';

export const isNumber = (val: any) => typeof val === 'number' && isFinite(val);

export const isEmpty = (val: any) => val.trim().length === 0;

export const debounce = (callback: (...args: any) => void, timeout = 300) => {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => { callback.apply(this, args); }, timeout);
    };
};
