import React from 'react';

export function QuoteIcon() {
    return (
        <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='12.5332' cy='12' r='12' fill='#4B46EC' />
            <g clipPath='url(#clip0_60_8237)'>
                <path
                    d='M18.2818 12.9484C17.6445 15.2277 16.0722 16.8823 14.5438 16.8823C14.4211 16.8823 14.2984 16.8716 14.179 16.8505C14.1112 16.8386 14.052 16.7988 14.0153 16.741C13.9787 16.6828 13.9684 16.6122 13.987 16.5461L14.3016 15.4208C14.332 15.3121 14.4367 15.2386 14.5457 15.2436L14.5707 15.2441C15.1375 15.2441 16.1733 14.3946 16.7014 12.5066C16.7223 12.4317 16.7421 12.3551 16.7611 12.2752C16.1683 12.5686 15.4612 12.6364 14.8208 12.4581C13.3761 12.0539 12.5298 10.5499 12.9337 9.1055C13.261 7.93511 14.3377 7.11746 15.5519 7.11746C15.7992 7.11746 16.0462 7.15158 16.286 7.21873C17.2387 7.485 17.9549 8.27595 18.3029 9.44536C18.6171 10.5004 18.6092 11.7771 18.2818 12.9484Z'
                    fill='white' />
                <path
                    d='M8.24364 16.8818C8.12112 16.8818 7.99836 16.8714 7.87901 16.8503C7.81137 16.8386 7.752 16.7985 7.71545 16.7407C7.6789 16.6826 7.66858 16.6119 7.68704 16.5459L8.00176 15.4205C8.03212 15.3119 8.13496 15.2384 8.24582 15.2434L8.27059 15.2439C8.83739 15.2439 9.87336 14.3944 10.4014 12.5064C10.4224 12.431 10.4422 12.3544 10.4612 12.275C9.86814 12.5683 9.16122 12.6362 8.52084 12.4578C7.07641 12.0536 6.22986 10.5497 6.63395 9.10525C6.9613 7.93487 8.03795 7.11721 9.25217 7.11721C9.4995 7.11721 9.74647 7.15133 9.9864 7.21848C10.939 7.48476 11.6552 8.2757 12.0033 9.44511C12.3173 10.5001 12.3096 11.777 11.9821 12.9484C11.3444 15.2275 9.77234 16.8818 8.24364 16.8818Z'
                    fill='white' />
            </g>
            <defs>
                <clipPath id='clip0_60_8237'>
                    <rect width='12' height='12' fill='white' transform='matrix(1 0 0 -1 6.53302 18)' />
                </clipPath>
            </defs>
        </svg>
    );
}
