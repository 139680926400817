export const LOSE_WEIGHT_BODY_GOAL = 'lose-weight';
export const GET_FIT_BODY_GOAL = 'get-fit';
export const BOTH_BODY_GOAL = 'both';

export const longValueGetter = {
    [LOSE_WEIGHT_BODY_GOAL]: 'lose almost 40% more weight.',
    [GET_FIT_BODY_GOAL]: 'lose almost 40% more weight.',
    [BOTH_BODY_GOAL]: 'gain almost 40% more muscle.'
};

export const valueGetter = {
    [LOSE_WEIGHT_BODY_GOAL]: '40% more weight loss!',
    [GET_FIT_BODY_GOAL]: '40% more weight loss!',
    [BOTH_BODY_GOAL]: '40% more muscle gained!'
}

export type GoalTypes = 'lose-weight' | 'get-fit' | 'both';
