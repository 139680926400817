import { createUseStyles } from 'react-jss'

import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ verticalCard }: ThemeProps) => ({
    verticalCard: {
        ...verticalCard.verticalCard,
    },
    verticalCardSelected: verticalCard.verticalCardSelected,
}));
