import React, { useContext } from 'react';

import { LocalStorageKeys, TypographyVariants } from '../../constants';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { MultiChoiceButton, MultiChoiceType } from '../../components/multichoice-button';
import { FloatedButton } from '../../components/floated-button';

import { BaseContext } from '../../context/base.context';
import { RoutePath } from '../../routes/route-path.constant';

import { useLocalStorage } from '../../hooks/useLocalStorage';

export const PastTrials = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue, setPageValue } = useLocalStorage({ key: LocalStorageKeys[RoutePath.PastTrials], defaultValue: [] });

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete(pageValue);
    };

    const multiChoices: MultiChoiceType[] = [
        { key: 'fitness-diet', value: 'Fitness & diet apps' },
        { key: 'gym-exercise', value: 'Gym or exercise' },
        { key: 'restrictive-diet', value: 'Restrictive diets' },
        { key: 'personal-training', value: 'Personal training' },
        { key: 'prescription-medication', value: 'Prescription medication' },
        { key: 'paid-meal-plans', value: 'Paid meal plans' },
        { key: 'none', value: 'None of the above' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1} typographyNext={true}>
                What have you tried in the past?
            </Typography>
            <Typography variant={TypographyVariants.h2}>
                Select all that apply
            </Typography>
            {
                multiChoices.map((v: MultiChoiceType) => (
                    <MultiChoiceButton
                        key={v.key}
                        item={v}
                        pageValue={pageValue}
                        setPageValue={setPageValue}
                    />
                ))
            }
            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
