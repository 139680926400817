import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    jeansImg: {
        display: 'block',
        margin: 'auto',
    },

    testimonialImg: {
        height: '390px',
        margin: 'auto',
        borderRadius: '8px',
        display: 'block',
    },

    testimonialName: {
        margin: '16px 0',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
    },

    testimonialText: {
        position: 'absolute',
        bottom: '0',
        margin: '0px 8px 8px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '18px',
        background: '#F0F0FF',
        borderRadius: '8px',
        padding: '24px 16px 16px',
    },
    container: {
        'position': 'relative',
        'width': 'max-content',
        'margin': 'auto',
        '& > div svg': {
            position: 'absolute',
            top: '-12px',
            left: '45%',
        },
    },
    '@media (max-width: 375px)' : {
        testimonialImg: {
            maxWidth: '340px',
        }
    },
    '@media (max-width: 360px)' : {
        testimonialImg: {
            maxWidth: '328px',
        }
    },
    '@media (max-width: 320px)' : {
        testimonialImg: {
            maxWidth: '288px',
            maxHeight: '340px',
        }
    },
});
