import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const WorkoutTimes = () => {
    const { pageValue: workoutTimes, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.WorkoutTimes],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'do-not-exercise', value: 'I don’t exercise now' },
        { key: '1-2-times', value: '1-2 times a week' },
        { key: '3-5-times', value: '3-5 times a week' },
        { key: 'daily', value: 'I exercise daily' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                How often do you work out?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == workoutTimes}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
