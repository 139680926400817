import React from 'react';

import {GoalChartLoseWeight} from '../lose-weight';
import {GoalChartGetFit} from '../get-fit';

type WeightChartType = { weightPlan: number[], monthsPlan: string[], goal: string, unit: string, latestMonth?: string }

export const WeightChart = ({ weightPlan, monthsPlan, goal, unit, latestMonth = '' } : WeightChartType) => {
    return (
        <>
            { goal === 'lose-weight' && <GoalChartLoseWeight unit={unit} weightPlan={weightPlan} monthsPlan={monthsPlan} latestMonth={latestMonth}/> }

            { goal === 'get-fit' && <GoalChartGetFit unit={unit} weightPlan={weightPlan} monthsPlan={monthsPlan}/> }
        </>
    );
};
