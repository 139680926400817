// node modules
import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    text1: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        '& a': {
            color: '#4B46EC'
        }
    },
    text2: {
        textAlign: 'inherit',
    }
});
