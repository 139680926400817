import { Typography } from '../typography';
import { TypographyVariants } from '../../constants';
import React from 'react';
import { useStyles } from './styles';

interface Props {
    rating: RatingType;
    handleClick: (rating: RatingType) => void;
    selected?: boolean,
    buttonClass?: string,
    buttonTextClass?: string,
}

export type RatingType = { key: string, value: string };

export const RatingButton = (props: Props) => {
    const { ratingButton, ratingButtonSelected } = useStyles();
    const { rating, handleClick } = props;

    let buttonClassName = `${ratingButton} ${props?.buttonClass} `;
    let buttonTextClassName = `${props?.buttonTextClass}`;

    if (props?.selected) {
        buttonClassName += ratingButtonSelected;
    }

    return (
        <button onClick={()=> handleClick(rating)} className={buttonClassName}>
            <Typography variant={TypographyVariants.ratingButton} className={buttonTextClassName}>
                {rating.value}
            </Typography>
        </button>
    );
};
