import Amplitude from 'amplitude-js';

import { getEnchargeUser } from '../helpers/encharge';
import { isString } from '../helpers';

const USER_ID_KEY = 'userID';

export interface GTagServiceInterface {
    event: (name: string, params: any) => void;
    onEnterEvent: (name: string) => void;
    getUserId: () => string;
}

export interface EnchargeServiceInterface {}

export class AnalyticsService implements GTagServiceInterface, EnchargeServiceInterface {

    private amplitude = Amplitude.getInstance();

    private userId = localStorage.getItem(USER_ID_KEY) || Math.random().toString(36).substring(2, 15);

    private useLog: boolean = window.location.origin.includes('localhost');

    private userIdentified = false;

    constructor() {
        localStorage.setItem(USER_ID_KEY, this.userId);
        this.amplitude.setUserId(this.userId);

        this.amplitude.init(
            process.env.REACT_APP_AMPLITUDE_API_KEY as string,
            this.userId,
            {
                includeUtm: true,
                includeGclid: true,
                includeReferrer: true,
                userId: this.userId,
            },
            () => {
                // this.amplitude.setUserProperties(parsed);
            }
        );
    }

    private logEvent(name: string, data = {}) {
        console.log(
            `%c[FIRED CLIENT EVENT] ${name}`,
            "color:orange; font-size: 16px; text-transform: capitalize;"
        );
        console.table({
            "EVENT NAME": name,
            "EVENT DATA": Object.keys(data).length ? JSON.stringify(data) : '-'
        });
        console.log(" ------------------------------ ");
    }

    private trackGTag(name: string, params: {} | string = '') {
        try {
            window.gtag('event', name, {
                'event_category': '<WebOnboarding>',
                'event_label': '<WeightLoss>',
                'value': params,
                'send_to': 'agency'
            });
        } catch (e: any) {
            console.error(`Failed to sent gtag event ${name}`, e);
        }
    }

    private trackEncharge(name: string, properties: {} | string = '') {
        const user = getEnchargeUser(this.userId, name, properties);

        if (user?.email && !this.userIdentified) {
            try {
                window.EncTracking.identify({ user: user?.email, userId: this.userId });
                this.userIdentified = true;
            } catch (e: any) {
                console.error(`Failed to sent Encharge identify ${name}`, e);
            }
        }

        try {
            window.EncTracking.track({ name, properties, user });
        } catch (e: any) {
            console.error(`Failed to sent Encharge track ${name}`, e);
        }
    }

    public getUserId() {
        return this.userId;
    }

    public onEnterEvent(name: string) {
        if (this.useLog) {
            return;
        }

        this.logEvent(name);

        this.trackGTag(name);
    }

    private getAmplitudeParams(name: string, params: any) {
        if (isString(params) || Array.isArray((params))) {
            return { [name]: params }
        }

        return params;
    }

    public event(name: string, params: {} | string = '') {
        if (this.useLog) {
            return;
        }

        this.logEvent(name, params);

        this.amplitude.logEvent(name, this.getAmplitudeParams(name, params));

        this.trackGTag(name, params);

        this.trackEncharge(name, params);
    }
}
