import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const TypicalDay = () => {
    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: typicalDay, setPageValue }: any = useLocalStorage({ key: LocalStorageKeys[RoutePath.TypicalDay], defaultValue: '' });

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'sedentary', value: 'Mostly seated' },
        { key: 'lightly_active', value: 'Minimal movement' },
        { key: 'moderately_active', value: 'Moderately active' },
        { key: 'very_active', value: 'On my feet all day' },
        { key: 'extra_active', value: 'Very physical' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                What does your typical day
                look like?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == typicalDay}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
