import { useStyles } from './styles';

export const Checkbox = ({ checked }: any) => {

    const { checkboxContainer, checkboxCheckmark } = useStyles();

    const eventHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <label className={checkboxContainer}>
            <input type="checkbox" checked={checked} onClick={eventHandler} readOnly />
            <span className={checkboxCheckmark} />
        </label>
    );
};
