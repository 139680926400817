import React, {FC, useEffect, useState} from 'react';
import {useStyles} from './styles';
import {Typography} from '../typography';
import {TypographyVariants} from '../../constants';

type Tab = { key: string, label: string };

export interface TabProps {
    tabs: Tab[],
    activeTab: string,
    onChange?: (index: string) => any,
}

export const Tabs: FC<TabProps> = ({ tabs, activeTab = tabs[0].key, onChange }) => {

    const {tabsWrapper, tabButton, tabButtonActive } = useStyles();

    const [currentTab, setCurrentTab] = useState(activeTab);

    useEffect(() => {
        setCurrentTab(activeTab);
    }, [activeTab]);

    useEffect(() => {
        if (onChange) {
            onChange(currentTab);
        }
    }, [currentTab]);

    return (
        <div className={tabsWrapper}>
            {tabs.map((tab) => {
                const btnClassName = `${tabButton} ${tab.key === currentTab ? tabButtonActive : ''}`;

                return (
                    <button key={tab.key}
                            className={btnClassName}
                            onClick={() => setCurrentTab(tab.key)}
                            style={{width: `calc(100% / ${tabs.length})`}}>
                        <Typography variant={TypographyVariants.tab}>
                            {tab.label}
                        </Typography>
                    </button>
                )
            })}
        </div>
    )
};
