import React, { useContext, useEffect, useState } from 'react';

import logoIcon from '../../assets/images/svg/logo.svg';

import { BaseContext } from '../../context/base.context';
import { useStyles } from './styles';

import { Typography } from '../../components/typography';
import { LocalStorageKeys, TypographyVariants } from '../../constants';
import { NextButton } from '../../components/next-button';
import { RoutePath } from '../../routes/route-path.constant';
import { PreloadImageServiceInstanceCTX } from '../../services/preload-image.service';

export const AccessAccount = () => {
    const {
        page, logo, title, list,
        listItem, boldText,
        continueImage, emailContinue, loginImage, listItemContent,
    } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);
    const { getImageByLocalPath } = useContext(PreloadImageServiceInstanceCTX);
    const [pageImages, setPageImages] = useState<string[]>([]);
    const email = localStorage.getItem(LocalStorageKeys[RoutePath.Email]);
    const linkDownload = `https://able-app.onelink.me/3FAG?deep_link_value=ableapp://email_login?email=${email}`;

    const handleClick = () => {
        window.open(linkDownload, '_blank');

        pageConfiguration.handleOnPageComplete();
    };

    const items = ['png/access-account/login.png', 'png/access-account/continue.png'];

    useEffect(() => {
        let images: string[] = [];

        items.map(item => {
            (async () => {
                if (item) {
                    const img = await getImageByLocalPath(item);
                    images.push(img as string);
                    setPageImages(images);
                }
            })();
        });
    }, [getImageByLocalPath]);

    return (
        <div className={page}>
            <div>
                <img src={logoIcon} className={logo} alt='able logo' />
            </div>
            <Typography
                variant={TypographyVariants.h1}
                className={title}
            >
                Simply follow these steps to <br /> access your account
            </Typography>
            <ul className={list}>
                <li className={listItem}>
                    <div className={listItemContent}>1</div>
                    <Typography variant={TypographyVariants.h2}>Download Able app</Typography>
                </li>
                <li className={listItem}>
                    <div className={listItemContent}>2</div>
                    <Typography variant={TypographyVariants.h2}>Open the app and tap <span className={boldText}> “Log In”</span> (see image below).
                    </Typography>
                </li>
                <img
                    className={loginImage}
                    src={pageImages[0]}
                    alt='access login'
                />
                <Typography variant={TypographyVariants.h2} className={emailContinue}>Then tap “<span className={boldText}>Continue with email</span>”</Typography>
                <img
                    className={continueImage}
                    src={pageImages[1]}
                    alt='access continue'
                />
                <li className={listItem}>
                    <div className={listItemContent}>3</div>
                    <Typography variant={TypographyVariants.h2}>Enter your email<br />
                        "<span className={boldText}>{email}</span>" and<br />
                        follow the instructions to create&nbsp;your<br />
                        password.
                    </Typography>
                </li>
            </ul>
            <NextButton
                typographyText='Download Able'
                onClick={handleClick}
            />
        </div>
    );
};
