import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    content: {
        height: '162px',
        background: '#F0F0FF',
        borderRadius: '10px',
        margin: '24px 16px',
    },
    innerContainer: {
        padding: '24px 17px'
    },
    title: {
        fontWeight: 600,
        color: '#121620',
        letterSpacing: '0.0008em',
        marginBottom: '12px'
    },
    text: {
        fontWeight: 600,
        lineHeight: '20px',
        color: '#121620',
        marginBottom: '12px'
    },
    time: {
        color: '#121620',
        fontWeight: 600,
        lineHeight: '20px',
        textTransform: 'uppercase',
    },
    timer: {
        fontSize: '18px',
    }
});
