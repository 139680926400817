import { Palette } from '../index';

export type HeaderType = {
    header: {
        background: Palette | string,
        position: string,
        height: string,
    },
    headerAvatar: {
        fontFamily: string
        fontWeight: 600,
        fontStyle: string,
        fontSize: string | number,
        lineHeight: string | number,
        color: string,
        background: string,
        letterSpacing: string,
    },
    headerEmail: {
        fontStyle: string,
        fontWeight: number,
        letterSpacing: string | number,
        color: string,
    }
}

export const HeaderStyles: HeaderType = {
    header: {
        background: '#F6F6F6',
        position: 'sticky',
        height: '52px',
    },
    headerAvatar: {
        fontFamily: 'SF Pro Text',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FE8F20',
        background: '#FFEEDE',
        letterSpacing: '-0.0024em',
    },
    headerEmail: {
        fontStyle: 'normal',
        fontWeight: 600,
        letterSpacing: '0.0008em',
        color: '#121620',
    }
};
