import React  from 'react';

import { Typography } from '../../../components/typography';
import { TypographyVariants } from '../../../constants';
import { MealIcon } from './icons/meal-icon';
import { HeartIcon } from './icons/heart-icon';

import { useStyles } from './styles';

const PRICE_SALE = 58;
const PRICE_SALE_DISCOUNT = 67;

type PriceBoxType = {
    price: number;
    originalPrice: number;
    discountPrice: number;
}

export const PriceBox = ({
                             price,
                             discountPrice,
                             originalPrice,
                         }: PriceBoxType) => {
    const {
        wrapper, block, title, blockTitle, keepPlan,
        oneTimePriceWrapper, oneTimePrice, originalPriceClass,
    } = useStyles();


    const isDiscount = window.location.pathname.includes('addons-discount');
    const pricing = isDiscount ? discountPrice : price;
    const savedPercents = isDiscount ? PRICE_SALE_DISCOUNT : PRICE_SALE;

    return (
        <div className={wrapper}>
            <Typography className={title} variant={TypographyVariants.h2}>
                What your premium bundle will include:
            </Typography>
            <div className={block}>
                <MealIcon />
                <Typography className={blockTitle} variant={TypographyVariants.h3}>
                    Custom meal plan
                </Typography>
            </div>
            <div className={block}>
                <HeartIcon />
                <Typography className={blockTitle} variant={TypographyVariants.h3}>
                    Custom self-care plan
                </Typography>
            </div>
            <div className={oneTimePriceWrapper}>
                <Typography className={oneTimePrice} variant={TypographyVariants.h3}>
                    One-time price of ${pricing}!
                </Typography>
                <Typography className={originalPriceClass} variant={TypographyVariants.h3}>
                    Original price is ${originalPrice}. Save {savedPercents}%!
                </Typography>
            </div>
            {!isDiscount && (
                <Typography variant={TypographyVariants.h3} className={keepPlan}>
                    These plans are <span>yours to keep</span> even if you decide Able isn’t right for you.
                </Typography>
            )}
        </div>
    );
};
