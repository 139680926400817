import React from 'react';
import { useStyles } from './styles';

interface Props {
    fraction: number;
    sqSize?: number;
    strokeWidth?: number;
}

export const CircularProgressBar = ({ fraction, sqSize = 206, strokeWidth = 8 }: Props) => {
    const { circle, circleBackground, circleProgress, circleText, percentSign } = useStyles();
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose circle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * fraction;

    return (
        <svg
            className={circle}
            width={sqSize}
            height={sqSize}
            viewBox={viewBox}>
            <circle
                className={circleBackground}
                cx={sqSize / 2}
                cy={sqSize / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`} />
            <circle
                className={circleProgress}
                cx={sqSize / 2}
                cy={sqSize / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`}
                // Start progress marker at 12 O'Clock
                transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
                style={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffset,
                }} />
            <text
                className={circleText}
                x='50%'
                y='50%'
                dy='.3em'
                textAnchor='middle'>
                {Math.floor(fraction * 100)}<tspan className={percentSign}>%</tspan>
            </text>
        </svg>
    );
};
