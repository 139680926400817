import React from 'react';

import { useStyles } from './styles';

export function HeartIcon() {
    const { blockImg } = useStyles();

    return (
        <svg className={blockImg} width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.543945" width="40" height="40" rx="20" fill="#FFEEDE"/>
            <path d="M29.5629 20.9749C29.0415 22.2735 28.258 23.4015 27.3341 24.4365C25.3126 26.7008 22.9153 28.5031 20.3426 30.0867C20.1092 30.2307 19.8723 30.2292 19.6452 30.0747C18.558 29.3372 17.4467 28.632 16.3924 27.8501C14.7242 26.6131 13.1954 25.2189 11.9218 23.5654C11.1948 22.6212 10.5867 21.6086 10.2521 20.4545C10.1409 20.0698 10.0825 19.67 10 19.277V18.339C10.012 18.2827 10.0287 18.2263 10.035 18.1694C10.1722 16.8483 10.6545 15.6724 11.546 14.6843C12.7136 13.3904 14.1736 12.7541 15.9185 12.9227C17.62 13.0871 18.9087 13.9713 19.8571 15.3743C19.9025 15.4416 19.9474 15.509 19.9829 15.5612C20.2684 15.2089 20.5221 14.8524 20.8191 14.5371C22.6465 12.597 25.5339 12.3491 27.663 13.9374C29.6375 15.4109 30.6334 18.3067 29.5629 20.9749Z" fill="#FE8F20"/>
        </svg>
    );
}
