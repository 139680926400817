export const FEMALE_TYPE = [
    {text: 'Strawberry', image: 'svg/body-type/female/strawberry.svg'},
    {text: 'Triangle', image: 'svg/body-type/female/triangle.svg'},
    {text: 'Rectangle', image: 'svg/body-type/female/rectangle.svg'},
    {text: 'Pear', image: 'svg/body-type/female/pear.svg'},
    {text: 'Hourglass', image: 'svg/body-type/female/hourglass.svg'}
]

export const MALE_TYPE = [
    {text: 'Ectomorph', image: 'svg/body-type/male/ectomorph.svg', description: 'Slim, finds it difficult to gain muscle'},
    {text: 'Mesomorph', image: 'svg/body-type/male/mesomorph.svg', description: 'Gains muscle quickly'},
    {text: 'Ecto-mesomorph', image: 'svg/body-type/male/ecto-mesomorph.svg', description: 'Gains muscle and fat quickly'},
    {text: 'Endomorph', image: 'svg/body-type/male/endomorph.svg', description: 'Gains fat quickly'}
]
