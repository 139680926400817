export const lbsValidationQuery: any = {
    type: {
        value: "number",
        moreThen: {
            value: 59,
            errorMessage: "Make sure you insert the correct weight."
        },
        lessThen: {
            value: 1001,
            errorMessage: "Make sure you insert the correct weight."
        }
    }
}
