import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        background: '#FFE9D2',
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        margin: '16px 0'
    },
    text: {
        fontWeight: 600,
        margin: 0,
    }
});
