// node modules
import { createUseStyles } from "react-jss";


export const useStyles = createUseStyles({
    errorMessageClass: {},
    validatedInputWrapper: {},
    hidden: {
        display: "none",
    },
});
