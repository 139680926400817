import React from 'react';

import excellentIcon from '../../../assets/images/svg/subscribe/excellent/excellent.svg';
import sunIcon from '../../../assets/images/svg/subscribe/excellent/sun.svg';
import dailyExpressIcon from '../../../assets/images/svg/subscribe/excellent/daily-express.svg';
import mirrorIcon from '../../../assets/images/svg/subscribe/excellent/mirror.svg';
import forbesIcon from '../../../assets/images/svg/subscribe/excellent/forbes.svg';
import usaTodayIcon from '../../../assets/images/svg/subscribe/excellent/usa-today.svg';
import yahooIcon from '../../../assets/images/svg/subscribe/excellent/yahoo.svg';

import { useStyles } from './styles';

export const Excellent = () => {
    const { newspapers, nBlock, excellent, theSun, dailyExpress, mirror, forbes, usaToday, yahoo } = useStyles();

    return (
        <>
            <img className={excellent} src={excellentIcon} alt="" />
            <div className={newspapers}>
                <div className={nBlock}>
                    <img className={theSun} src={sunIcon} alt="The Sun"/>
                    <img className={dailyExpress} src={dailyExpressIcon} alt="Daily Express"/>
                    <img className={mirror} src={mirrorIcon} alt="Mirror"/>
                </div>
                <div className={nBlock}>
                    <img className={forbes} src={forbesIcon} alt="Forbes"/>
                    <img className={usaToday} src={usaTodayIcon} alt="USA Today"/>
                    <img className={yahoo} src={yahooIcon} alt="Yahoo"/>
                </div>
            </div>
        </>
    );
};
