import { createUseStyles } from "react-jss";

import SFProText from '../../assets/fonts/SF-Pro-Text/SF-Pro-Text-Regular.otf';

import { ThemeProps } from '../../themes';

// export const useStyles = createUseStyles(( typography : any) => ({

export const useStyles = createUseStyles(({ typography }: ThemeProps) => ({
    '@font-face': {
        fontFamily: "SF Pro Text",
        src: `url('${SFProText}') format("opentype")`
    },
    h1: typography.h1,
    h2: typography.h2,
    h3: typography.h3,
    p: typography.h1, // @TODO: change it
    buttonTypography: typography.button,
    choiceButtonTypography: typography.choiceButton,
    multiChoiceButtonTypography: typography.multiChoiceButton,
    input: typography.input,
    inputStartAdornment: typography.inputStartAdornment,
    inputEndAdornment: typography.inputEndAdornment,
    inputLabel: typography.inputLabel,
    validatedInputError: typography.validatedInputError,
    verticalCard: typography.verticalCard,
    verticalCardDescription: typography.verticalCardDescription,
    coloredPage: typography.coloredPage,
    tabTypography: typography.tab,
    ratingButtonTypography: typography.ratingButton,
    ratingGradationInfoTypography: typography.ratingGradationInfo,
    marginBottom16: {
        marginBottom: '16px',
    }
}));
