import React, { useContext } from 'react';

import { LocalStorageKeys, TypographyVariants } from '../../constants';
import { FEMALE_TYPE, MALE_TYPE } from '../../constants/body-type';

import { Typography } from '../../components/typography';
import { VerticalCard } from '../../components/vertical-card';

import { BaseContext } from '../../context/base.context';

import { useLocalStorage } from '../../hooks/useLocalStorage';

import { useStyles } from './styles';
import { RoutePath } from '../../routes/route-path.constant';

type TypeData = {
    text: string,
    image: string,
    description?: string
}

interface BodyTypeDataInterface {
    male: TypeData[],
    female: TypeData[],
}

export const BodyType = (() => {
    const { menCards, womenCards } = useStyles();
    const { pageValue: bodyType, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyType],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const BodyGender = localStorage.getItem(LocalStorageKeys[RoutePath.BodyGender]);

    const onClick = (value: TypeData) => {
        setPageValue(value.text);

        pageConfiguration.handleOnPageComplete(value.text);
    };

    const bodyTypeData: BodyTypeDataInterface = { male: MALE_TYPE, female: FEMALE_TYPE };

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                What is your body type?
            </Typography>

            {BodyGender === 'male' ? (
                <div className={menCards}>
                    {bodyTypeData?.male.map((data) => {
                        return (
                            <VerticalCard
                                onClick={onClick}
                                key={data.text}
                                data={data}
                                isSelected={bodyType === data.text}
                            />
                        );
                    })}
                </div>
            ) : (
                <div className={womenCards}>
                    {bodyTypeData?.female.map((data) => {
                        return (
                            <VerticalCard
                                onClick={onClick}
                                key={data.text}
                                data={data}
                                isSelected={bodyType === data.text}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
});
