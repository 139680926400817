import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    banner: {
        height: '84px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#FFF0EB',
        paddingLeft: '24px',
    },
    title: {
        lineHeight: '18px',
        fontSize: '24px',
        fontWeight: 600,
        color: '#121620',
    },
    '@media (max-width: 500px)' : {
        banner: {
            position: 'relative',
            width: '100vw',
            left: 'calc(-50vw + 50%)',
        }
    },
});
