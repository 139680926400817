import React, {useContext} from 'react';

import { BaseContext } from '../../context/base.context';

import { NextButton } from '../../components/next-button';
import { TypographyVariants } from '../../constants';
import { Typography } from '../../components/typography';

import { disableBrowserBackButton } from '../../helpers/disableBrowserBackButton';

import waitImg from '../../assets/images/svg/wait/wait_img.svg';

import { useStyles } from './styles';

export const Wait = () => {
    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const { title, text, wrapper, img } = useStyles();

    const handleSubmit = () => {
        pageConfiguration.handleOnPageComplete();
    }

    return (
        <div className={wrapper}>
            <img className={img} src={waitImg} alt=""/>
            <Typography className={title} variant={TypographyVariants.h1}>
                Sit tight!
            </Typography>
            <Typography variant={TypographyVariants.h2} className={text}>
                We are wrapping your premium bundle&nbsp;up<br/>
                <span>to deliver it to your e-mail within the<br/>
                next 30 minutes or less.</span> After all, good<br/>
                things (and premium bundles!) come to<br/>
                those who wait.
            </Typography>
            <NextButton
                onClick={handleSubmit}
            />
        </div>
    )
};
