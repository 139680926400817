import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    title: {
        fontSize: '22px',
        textAlign: 'center',
        maxWidth: '250px',
        margin: '0 auto 8px',
    },
    subtitle: {
        textAlign: 'center',
        maxWidth: '340px',
        margin: '0 auto 16px auto',
    },
    wrapper: {
        maxWidth: '400px',
        margin: '0 auto',
    },
    skipWrapper: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    skipButton: {
        display: 'inline-block',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        color: '#FE8F20',
        borderRadius: '20px',
        padding: '4px 8px',
        cursor: 'pointer',
        background: '#F6F6F6',
        margin: '12px 0 8px 0'
    },
    tryItTitle: {
        fontSize: '18px',
        lineHeight: '22px',
        paddingBottom: '15px',
        color: '#FE8F20',
        margin: 0,
    },
    buttonContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {
        marginRight: '10px'
    },
    helperText: {
        fontSize: '12px',
        lineHeight: '16px',
        color: '#868C99',
        paddingBottom: '14px'
    }
});
