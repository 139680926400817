import React from 'react';

import { useStyles } from './styles';

export function MealIcon() {
    const { blockImg } = useStyles();

    return (
        <svg className={blockImg} width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.543945" width="40" height="40" rx="20" fill="#FFEEDE"/>
            <path d="M17.3925 16.2176C17.0086 16.6831 16.5414 17.0441 15.9779 17.2671C15.8288 17.3264 15.7901 17.3951 15.8065 17.56C15.9632 19.1372 16.107 20.7156 16.2561 22.2933C16.4199 24.0266 16.586 25.7594 16.7504 27.4927C16.8331 28.3632 16.93 29.2325 16.994 30.1041C17.0826 31.3133 16.2667 32.3199 15.0728 32.5101C15.0435 32.5148 15.0159 32.5324 14.9877 32.5441H14.5187C14.082 32.4491 13.6641 32.3164 13.316 32.0153C12.7155 31.4964 12.4385 30.8343 12.5195 30.0577C12.7279 28.055 12.9674 26.0552 13.1916 24.0542C13.4023 22.1771 13.6101 20.2994 13.819 18.4217C13.8502 18.1429 13.8889 17.8641 13.9071 17.5835C13.9112 17.5219 13.8578 17.415 13.8067 17.3968C12.2782 16.841 11.4618 15.6829 11.1296 14.1585C10.8061 12.6764 11.0491 11.273 11.9695 10.0462C13.5168 7.98303 16.1898 8.05993 17.6484 10.1848C18.8787 11.9774 18.7719 14.5436 17.3925 16.2176Z" fill="#FE8F20"/>
            <path d="M29.0054 9.54579V13.5994C29.0054 15.4137 28.0862 16.7297 26.3864 17.3507C26.3646 17.3589 26.3435 17.3689 26.3012 17.3871C26.3306 17.7287 26.3582 18.0768 26.3911 18.4243C26.6235 20.8954 26.8565 23.3666 27.0913 25.8383C27.224 27.237 27.3595 28.6358 27.4945 30.034C27.6125 31.2601 26.7943 32.3155 25.5746 32.5098C25.5452 32.5145 25.5176 32.5321 25.4895 32.5438H25.0205C24.5844 32.4487 24.1664 32.3161 23.8178 32.015C23.2032 31.4832 22.9367 30.8052 23.023 30.0111C23.3218 27.2412 23.6388 24.473 23.9504 21.7037C24.0989 20.3854 24.2469 19.067 24.4053 17.7493C24.4318 17.5292 24.393 17.4305 24.1547 17.3601C22.6086 16.9023 21.531 15.4871 21.5104 13.8741C21.491 12.3668 21.5034 10.8594 21.5057 9.3515C21.5063 8.87606 21.828 8.5409 22.2635 8.54618C22.6896 8.55088 23.0019 8.88193 23.0037 9.34622C23.0072 10.5648 23.0007 11.7827 23.0066 13.0013C23.0089 13.5841 23.5443 13.9551 24.0502 13.7291C24.3695 13.5865 24.5063 13.3235 24.5057 12.9778C24.5034 11.798 24.5045 10.6188 24.5057 9.43955C24.5057 9.338 24.504 9.23411 24.5251 9.13667C24.6061 8.76571 24.9459 8.51742 25.3169 8.54794C25.7055 8.58023 26.0007 8.89367 26.0025 9.30513C26.0083 10.5237 26.0072 11.7422 26.0037 12.9608C26.0031 13.3047 26.1275 13.5724 26.4451 13.7221C26.9551 13.9627 27.501 13.5894 27.5039 12.9948C27.5086 11.8156 27.5051 10.6358 27.5063 9.45657C27.5063 9.35561 27.5022 9.25172 27.5215 9.15311C27.5932 8.78214 27.9283 8.52505 28.2999 8.54736C28.6714 8.56907 28.9708 8.86314 29.0013 9.24115C29.0095 9.34211 29.0054 9.44424 29.0054 9.54579Z" fill="#FE8F20"/>
        </svg>
    );
}
