import React, { useContext } from 'react';

import { BaseContext } from '../../context/base.context';

import { LocalStorageKeys, TypographyVariants } from '../../constants';
import { Typography } from '../../components/typography';

import logo2 from '../../assets/images/svg/logo-black.svg';

import { useStyles } from './styles';
import { RoutePath } from '../../routes/route-path.constant';
import { getUnitSystem, UNIT_SYSTEM } from '../../constants/body-height-weight';


export const PersonalizedProgram = () => {
    const { animationPageWrapper, textWrapper, animatedText, orangeText, first, second, third, forth } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const unit = getUnitSystem() === UNIT_SYSTEM.imperial ? 'lb' : 'kg';
    const goalValue = localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight]);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <div className={animationPageWrapper}>
            <img src={logo2} alt='' />
            <div className={textWrapper}>
                <Typography className={`${animatedText} ${first}`} variant={TypographyVariants.h1} typographyNext>
                    Based on your answers,
                </Typography>
                <Typography className={`${animatedText} ${second}`} variant={TypographyVariants.h1} typographyNext>
                    we’ve created a<br />
                    <span className={orangeText}>personalized program</span>
                </Typography>
                <Typography className={`${animatedText} ${third}`} variant={TypographyVariants.h1} typographyNext>
                    to support you through<br />
                    <span className={orangeText}>1-on-1 health coaching</span>
                </Typography>
                <Typography onAnimationEnd={() => handleClick()} className={`${animatedText} ${forth}`}
                            variant={TypographyVariants.h1}>
                    and get you to<br />
                    <span className={orangeText}>{goalValue} {unit}</span> for good.
                </Typography>
            </div>
        </div>
    );
};
