import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #eff2fd',
        borderRadius: '16px',
        padding: '16px',
        minHeight: '122px',
        minWidth: '324px',
        marginRight: '8px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
    },
    '@media (max-width: 414px)' : {
        wrapper: {
            minWidth: '314px'
        }
    },
    '@media (max-width: 393px)' : {
        wrapper: {
            minWidth: '289px'
        }
    },
    '@media (max-width: 375px)' : {
        wrapper: {
            minWidth: '274px'
        }
    },
    '@media (max-width: 360px)' : {
        wrapper: {
            minWidth: '257px'
        }
    },
    '@media (max-width: 320px)' : {
        wrapper: {
            minWidth: '218px'
        }
    },
    typ: {
        marginTop: '2px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        fontFamily: 'SF Pro Text',
        color: '#4B46EC',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'start'
    },
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    customerImg: {
        width: '32px',
        height: '32px',
        borderRadius: '16px'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: '12px'
    },
    customReviewRecommended: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content'
    },
    typ2: {
        color: '#868C99',
        fontSize: '12px',
        lineHeight: '16px'
    },
    comment: {
        marginTop: '12px',
        textAlign: 'left'
    },
    starBlock: {
        marginLeft: '6px',
        '& img': {
            marginRight: '4px'
        }

    }
});
