import * as React from 'react';

import { Button } from '../button';

import { useStyles } from './styles';

export const NextButton = ({children, typographyText, ...props}: any) => {

    const { nextButton } = useStyles();

    typographyText = typographyText ? typographyText : "Next";

    return (
        <Button className={nextButton} typographyText={typographyText} {...props}>{children}</Button>
    );
}
