import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        height: '30px',
        width: '80px',
        margin: '12px 0 24px',
    },
    title: {
        fontFamily: 'SF Pro Display',
        lineHeight: '130%',
        letterSpacing: '0.2px',
        margin: '0 0 24px',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '343px',
        position: 'relative',
        counterReset: 'num',
        width: '100%',
        padding: '0 28px',
        boxSizing: 'border-box',
        textAlign: 'left',
    },
    listItem: {
        counterIncrement: 'num',
        paddingLeft: '40px',
        display: 'flex',

        '& h2': {
            margin: '0 0 12px',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'left',
        }
    },
    listItemContent: {
        fontFamily: 'SF Pro Display',
        fontSize: '20px',
        lineHeight: '130%',
        letterSpacing: '0.2px',
        marginLeft: '-35px',
        marginRight: '22px',
        color: '#FE8F20',
        fontWeight: 600,
        width: '10px',
    },
    loginImage: {
        height: '291px',
        maxWidth: '260px',
        marginLeft: 'calc((100% - 70%)/2)',
        marginBottom: '24px',
    },
    emailContinue: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '0 0 12px',
        paddingLeft: '21px',
    },
    continueImage: {
        height: '178px',
        maxWidth: '260px',
        marginLeft: 'calc((100% - 70%) / 2)',
        marginBottom: '24px',
    },
    boldText: {
        fontWeight: 600,
    },
});
