import { createUseStyles } from 'react-jss'

import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ coloredPage }: ThemeProps) => ({
    coloredPageWrapper: {
        ...coloredPage.coloredPageWrapper,
        '& img': {
            ...coloredPage.coloredPageIcon,
        }
    },
    childrenWrapper: {
        maxWidth: '560px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '80%',
    }
}));
