import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '../../../components/typography';
import { TypographyVariants } from '../../../constants';
import {RoutePath} from '../../../routes/route-path.constant';

import { Timer } from './timer';

import { useStyles } from './styles';

export const TimeRemaining = () => {
    const { content, innerContainer, title, text, time, timer } = useStyles();
    const navigate = useNavigate();

    const endOfTimerHandler = () => {
        localStorage.clear();
        //@TODO: Navigate to first page
        navigate(`..${RoutePath.MainGoal}`);
    }

    return (
        <div className={content}>
            <div className={innerContainer}>
                <Typography className={title} variant={TypographyVariants.h3}>
                    PERSONALIZED PLAN RESERVED
                </Typography>
                <Typography className={text} variant={TypographyVariants.h3}>
                    Your personalized plan has been saved for the next 15 minutes!
                </Typography>
                <Typography className={time} variant={TypographyVariants.h3}>
                    Time remaining:
                </Typography>
                <Typography className={`${time} ${timer}`} variant={TypographyVariants.h3}>
                    <Timer initialMinute={15} initialSeconds={0} endOfTimerHandler={endOfTimerHandler} />
                </Typography>
            </div>
        </div>
    )
};
