import { useEffect, useState } from 'react';

import { useLocalStorage } from './useLocalStorage';

import { LocalStorageKeys, MultipleLocalStorageKeys } from '../constants';
import { RoutePath } from '../routes/route-path.constant';
import { getUnitSystem, UNIT_SYSTEM } from '../constants/body-height-weight';

import { BodyPlanCalculator } from '../services/body-plan-calculator.service';

export const useBodyPlanData = () => {
    const [monthsPlan, setMonthsPlan] = useState<string[]>([]);
    const [weightPlan, setWeightPlan] = useState<number[]>([]);
    const [earlierMonthsPlan, setEarlierMonthsPlan] = useState<string[]>([]);
    const [earlierWeightPlan, setEarlierWeightPlan] = useState<number[]>([]);
    const [finalDay, setFinalDay] = useState<string>('');
    const [finalMonth, setFinalMonth] = useState<string>('');
    const [earlierFinalDay, setEarlierFinalDay] = useState<string>('');
    const [earlierFinalMonth, setEarlierFinalMonth] = useState<string>('');
    const [earlierWeightLossDifference, setEarlierWeightLossDifference] = useState<number>(0);

    const unitSystem = getUnitSystem();
    const unit = unitSystem === UNIT_SYSTEM.imperial ? 'lb' : 'kg';
    const bodyHeightWeightKeys = MultipleLocalStorageKeys[RoutePath.BodyHeightWeight];

    const { pageValue: weightLbs }: any = useLocalStorage({ key: bodyHeightWeightKeys.weightLbs, defaultValue: '' });
    const { pageValue: weightKg }: any = useLocalStorage({ key: bodyHeightWeightKeys.weightKg, defaultValue: '' });
    const { pageValue: heightFt }: any = useLocalStorage({ key: bodyHeightWeightKeys.heightFt, defaultValue: '' });
    const { pageValue: heightInches }: any = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });
    const { pageValue: heightCm }: any = useLocalStorage({ key: bodyHeightWeightKeys.heightCm, defaultValue: '' });
    const { pageValue: goalWeight }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight],
        defaultValue: '',
    });

    const weight = unitSystem === UNIT_SYSTEM.imperial ? weightLbs : weightKg;
    const height = unitSystem === UNIT_SYSTEM.imperial ? heightFt * 12 + heightInches : heightCm;

    let goal;
    goal = weight > goalWeight ? 'lose-weight' : 'get-fit';

    useEffect(() => {
        const bodyPlan = new BodyPlanCalculator({ weight, height, goalWeight, unit });

        const { finalDay, finalMonth, weights, dates } = bodyPlan.getWeightLossGraphicData(false);
        const { finalDay: earlierFinalDay, finalMonth: earlierFinalMonth, weights: earlierWeights, dates: earlierDates } = bodyPlan.getWeightLossGraphicData(true);
        const earlierWeightLossDifference = bodyPlan.getEarlierDays();
        // Weight loss goal chart
        setFinalDay(finalDay);
        setFinalMonth(finalMonth);
        setMonthsPlan(dates);
        setWeightPlan(weights);

        // Earlier weight loss hit goal chart
        setEarlierFinalDay(earlierFinalDay);
        setEarlierFinalMonth(earlierFinalMonth);
        setEarlierWeightLossDifference(earlierWeightLossDifference);
        setEarlierMonthsPlan(earlierDates);
        setEarlierWeightPlan(earlierWeights);
    }, [weight, goalWeight, unit]);

    return { monthsPlan, weightPlan, unit, goal, lossDay: finalDay, lossMonth: finalMonth, earlierMonthsPlan, earlierWeightPlan, earlierLossDay: earlierFinalDay, earlierLossMonth: earlierFinalMonth, earlierWeightLossDifference };
};
