import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const HealthyFridge = () => {
    const { pageValue: healthyFridge, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.HealthyFridge],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const answers: ChoiceType[] = [
        { key: 'yes', value: 'Yeah, that\'s so me.' },
        { key: 'sometimes', value: 'That\'s sometimes me.' },
        { key: 'rarely', value: 'That\'s rarely me.' },
        { key: 'no', value: 'Nope, not me.' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                Do you stock your fridge with vegetables, fruits, and / or
                lean proteins?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == healthyFridge}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
