export const cmValidationQuery: any = {
    type: {
        value: "number",
        moreThen: {
            value: 69,
            errorMessage: "Make sure you insert the correct height."
        },
        lessThen: {
            value: 251,
            errorMessage: "Make sure you insert the correct height."
        }
    }
}