import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    newspapers: {
        marginTop: '40px',
    },
    nBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '25px'
    },
    excellent: {

    },
    theSun: {},
    dailyExpress: {},
    mirror: {},
    forbes: {},
    usaToday: {},
    yahoo: {},
    '@media (max-width: 320px)' : {
        excellent: {
            width: '90%'
        },
        theSun: {
            width: '60px',
        },
        dailyExpress: {
            width: '130px',
        },
        mirror: {
            width: '60px',
        },
        forbes: {
            width: '80px',
        },
        usaToday: {
            width: '110px',
        },
        yahoo: {
            width: '80px',
        }
    },
});
