import { useEffect, useState } from "react";

import { IsJsonString } from "../helpers/json-validator";


import { UPDATE_STORAGE_EVENT } from '../constants';

const createUpdateStorageCustomEvent = (value: any, keyEvent: string) => new CustomEvent(UPDATE_STORAGE_EVENT, {
    detail: { value, keyEvent }
});

const getDefaultValue = (key: string, defaultValue: any) => {
    const data = localStorage.getItem(key);
    if (data) {
        if (IsJsonString(data)) {
            return JSON.parse(data);
        }
        return data;
    }
    return defaultValue;
};

type StorageParams = { key: string; defaultValue: any; };

export const useLocalStorage = ({ key, defaultValue }: StorageParams) => {
    const [pageValue, setPageValue] = useState(getDefaultValue(key, defaultValue));

    useEffect(() => {
        window.addEventListener(UPDATE_STORAGE_EVENT, (e:any) => {
            if(key === e.detail.keyEvent) {
                setPageValue(e.detail.value);
            }
        });

        return () => {
            window.removeEventListener(UPDATE_STORAGE_EVENT, (e:any) => {
                if(key === e.detail.keyEvent) {
                    setPageValue(e.detail.value);
                }
            });
        }
    }, [key]);

    useEffect(() => {
        if (typeof pageValue === 'object') {
            localStorage.setItem(key, JSON.stringify(pageValue) || '');
        } else {
            localStorage.setItem(key, typeof pageValue === 'boolean' ? pageValue : pageValue || '');
        }
        window.dispatchEvent(createUpdateStorageCustomEvent(pageValue, key));
    }, [pageValue, key]);

    return { pageValue, setPageValue };
};
