// node modules
import { createUseStyles } from 'react-jss';
import { ThemeProps } from '../../themes';

export const useStyles = createUseStyles(({ progressBar }: ThemeProps) => ({
    wrapperBar: {
        zIndex: 1,
        position: progressBar.wrapperBar.position,
        height: progressBar.wrapperBar.height,
        background: progressBar.wrapperBar.background,
        top: progressBar.wrapperBar.top,
    },
    progressBar: {
        maxWidth: '100%',
        height: '100%',
        borderRadius: progressBar.progressBar.borderRadius,
        background: progressBar.progressBar.background,
        transition: 'width .3s',
    },
    labelStyles: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '16px',
        fontFamily: 'SF Pro Text',
        lineHeight: '24px',
        color: '#fff',
    },
}));
