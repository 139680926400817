import React from 'react';

import { Typography } from '../../../components/typography';

import { LocalStorageKeys, MultipleLocalStorageKeys, TypographyVariants } from '../../../constants';
import { RoutePath } from '../../../routes/route-path.constant';
import { getUnitSystem, UNIT_SYSTEM } from '../../../constants/body-height-weight';

import { getCorrectedWeight } from '../../../services/body-plan-calculator.service';
import { useBodyPlanData } from '../../../hooks/useBodyPlanData';

import { useStyles } from './styles';


export const HighLightList = () => {
    const { listItem, highLightList, listItemText } = useStyles();
    const { earlierLossMonth, earlierLossDay } = useBodyPlanData();

    const getGoalText = (): string => {
        const weightDifference = getCorrectedWeight();
        const finalDate = `${earlierLossMonth} ${earlierLossDay}`;
        const unitSystem = getUnitSystem();

        let goal;

        const unit = unitSystem === UNIT_SYSTEM.imperial ? 'lb' : 'kg';

        const weightLbs = parseFloat(localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].weightLbs) || '0');
        const weightKg = parseFloat(localStorage.getItem(MultipleLocalStorageKeys[RoutePath.BodyHeightWeight].weightKg) || '0');
        const goalWeight = parseFloat(localStorage.getItem(LocalStorageKeys[RoutePath.BodyIdealWeight]) || '0');

        const weight = unitSystem === UNIT_SYSTEM.imperial ? weightLbs : weightKg;

        goal = weight > goalWeight ? 'lose-weight' : 'get-fit';

        if (goal === 'lose-weight') {
            return `Lose ${weightDifference} ${unit} by ${finalDate}`;
        }

        return `Gain ${weightDifference} ${unit} of muscle by ${finalDate}`;
    };

    const goalText = getGoalText();

    const checkIcon = (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6.26636L9 17.2664L4 12.2664" stroke="#FE8F20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );

    return (
        <div className={highLightList}>
            <div className={listItem}>
                {checkIcon}
                <Typography className={listItemText} variant={TypographyVariants.h3}>
                    {goalText}
                </Typography>
            </div>
            <div className={listItem}>
                {checkIcon}
                <Typography className={listItemText} variant={TypographyVariants.h3}>
                    Continue developing healthy habits to keep the weight off after reaching your goal
                </Typography>
            </div>
            <div className={listItem}>
                {checkIcon}
                <Typography className={listItemText} variant={TypographyVariants.h3}>
                    Feel comfortable and confident in your body
                </Typography>
            </div>
            <div className={listItem}>
                {checkIcon}
                <Typography className={listItemText} variant={TypographyVariants.h3}>
                    1-on-1 health coaching
                </Typography>
            </div>
        </div>
    );
};
