import React, { useContext, useEffect, useState } from 'react';

import { BaseContext } from '../../context/base.context';

import { TypographyVariants } from '../../constants';
import { Typography } from '../../components/typography';
import { CircularProgressBar } from '../../components/circular-progress-bar-rounded';

import logo2 from '../../assets/images/svg/logo-black.svg';

import { useStyles } from './styles';


export const ProgramBuilder = () => {
    const { animationPageWrapper, animatedText, first, second, third } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const [progress, setProgress] = useState(0);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    const totalSteps = 1000;
    const threshold1 = 0.3;
    const threshold2 = 0.8;

    const moveProgressBar = (from: number, to: number, totalDurationMs: number, callback: () => void) => {
        let counter = totalSteps * from;
        const interval = setInterval(() => {
            if (counter >= totalSteps * to) {
                clearInterval(interval);
                callback();
            } else {
                setProgress(++counter);
            }
        }, totalDurationMs / (totalSteps * (to - from)));

        return interval;
    };

    useEffect(() => {
        let currentIntervalId: NodeJS.Timeout;
        currentIntervalId = moveProgressBar(0, threshold1, 4000, () => {
            currentIntervalId = moveProgressBar(threshold1, threshold2, 10000, () => {
                currentIntervalId = moveProgressBar(threshold2, 1, 6000, () => {
                    handleClick();
                });
            });
        });

        return () => {
            clearInterval(currentIntervalId);
        };
    }, []);

    return (
        <div className={animationPageWrapper}>
            <img src={logo2} alt='' />
            <div>
                <Typography variant={TypographyVariants.h1}>
                    Sit tight!<br />
                    We’re building your perfect<br />
                    program...
                </Typography>
            </div>

            <CircularProgressBar fraction={progress / totalSteps} />

            <div>
                {
                    progress / totalSteps < threshold1 &&
                    <Typography
                        className={`${animatedText} ${first}`}
                        variant={TypographyVariants.h2}>
                        Analyzing your body parameters...
                    </Typography>
                }
                {
                    progress / totalSteps >= threshold1 &&
                    progress / totalSteps < threshold2 &&
                    <Typography
                        className={`${animatedText} ${second}`}
                        variant={TypographyVariants.h2}>
                        Reviewing your lifestyle and preferences...
                    </Typography>
                }
                {
                    progress / totalSteps > threshold2 &&
                    <Typography className={`${animatedText} ${third}`} variant={TypographyVariants.h2}>
                        Preparing your program...
                    </Typography>
                }
            </div>
        </div>
    );
};
