import {defaultTheme, ThemeProps} from '../../themes';

export const customTheme: Partial<ThemeProps> = {
    ...defaultTheme,
    button: {
        ...defaultTheme.button,
        button: {
            ...defaultTheme.button.button,
            background: '#4B46EC',
            color: '#FFFFFF',
        }
    },
    nextButton: {
        ...defaultTheme.nextButton,
        nextButton: {
            background: '#1fec00',
            color: '#FFFFFF',
        }
    },
    choiceButton: {
        ...defaultTheme.choiceButton,
        choiceButtonSelected: {
            background: '#FE8F20',
            color: '#FFFFFF',
        }
    },
    multiChoiceButton: {
        ...defaultTheme.choiceButton,
        multiChoiceButton: {
            ...defaultTheme.multiChoiceButton.multiChoiceButton,
            background: '#fed9ad',
            height: '75px',
            margin: '0 0 25px',
            padding: '10px',
        },
        multiChoiceButtonCheckboxCheckmark: {
            ...defaultTheme.multiChoiceButton.multiChoiceButtonCheckboxCheckmark,
            backgroundColor: '#FE8F20',
            borderRadius: '12px',
        },
        multiChoiceButtonSelected: {
            ...defaultTheme.multiChoiceButton.multiChoiceButtonSelected,
            border: '1px solid #FE8F20',
            background: '#f0f0ff',
            color: '#202B47'
        }
    },
    typography: {
        ...defaultTheme.typography,
        h1: {
            ...defaultTheme.typography.h1,
            fontFamily: 'BriosoPro',
        },
        multiChoiceButton: {
            ...defaultTheme.typography.multiChoiceButton,
            fontFamily: 'BriosoPro',
        }
    }
};
