import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 16px'
    },
    img: {
        marginTop: '32px'
    },
    title: {
        fontSize: '22px',
        maxWidth: '250px',
        margin: '32px 0 0',
    },
    text: {
        fontSize: '16px',
        lineHeight: '24px',
        whiteSpace: 'nowrap',
        maxWidth: '305px',
        margin: '12px 0',
        '& span': {
            fontWeight: 600
        }
    }
});
