import React, { useContext } from 'react';

import { Typography } from '../../components/typography';
import { ChoiceButton, ChoiceType } from '../../components/choice-button';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';
import {RoutePath} from '../../routes/route-path.constant';

export const AverageBusy = () => {
    const { pageValue: averageBusy, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.AverageBusy],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ChoiceType) => {
        setPageValue(answer.key);

        pageConfiguration.handleOnPageComplete(answer.key);
    };

    const answers: ChoiceType[] = [
        { key: 'hardly-have-time-for-myself', value: 'I hardly have time for myself' },
        {
            key: 'busy-but-try-put-time-to-recharge',
            value: 'I\'m busy but always try to put time aside time to recharge',
        },
        { key: 'open-flexible', value: 'My schedule is pretty open and flexible' },
    ];

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                How busy are you<br/>
                on an average day?
            </Typography>

            {
                answers.map((v: ChoiceType) => (
                    <ChoiceButton
                        key={v.key}
                        center={true}
                        selected={v.key == averageBusy}
                        typographyText={v.value}
                        onClick={() => handleClick(v)}
                    />
                ))
            }
        </>
    );
};
