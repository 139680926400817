import React from 'react';

import { useStyles } from './styles';

export function DiscountIcon() {
    const { img } = useStyles();

    return (
        <svg className={img} width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.995 3.67876C31.9942 1.79371 30.7503 0.549804 28.8669 0.548969C25.2537 0.548134 21.6406 0.555648 18.0282 0.54396C17.0774 0.541455 16.2926 0.862031 15.6222 1.53407C10.746 6.41952 5.86471 11.3008 0.97759 16.1762C0.465002 16.688 0.157784 17.2899 0 17.9836V18.7959C0.17448 19.7218 0.760533 20.3746 1.40419 21.0141C4.79027 24.3785 8.16551 27.7537 11.5307 31.1398C12.1702 31.7834 12.823 32.3686 13.748 32.544H14.5603C15.4778 32.3795 16.1307 31.8101 16.766 31.1723C21.4986 26.4221 26.2413 21.6819 30.9873 16.9459C31.6669 16.268 32.0033 15.4808 32 14.5174C31.9883 10.9051 31.9958 7.29192 31.995 3.67876ZM16.0138 7.92305C17.6317 7.92973 18.9415 9.25127 18.9382 10.8742C18.934 12.4979 17.6233 13.8045 15.9971 13.8045C14.3717 13.8045 13.0601 12.4971 13.0568 10.8734C13.0535 9.23875 14.3792 7.91637 16.0138 7.92305ZM15.9704 25.1699C14.3541 25.1582 13.0493 23.83 13.0568 22.2062C13.0651 20.585 14.3817 19.2826 16.0096 19.2876C17.635 19.2927 18.9382 20.6025 18.9382 22.2304C18.9374 23.8634 17.6058 25.1816 15.9704 25.1699ZM21.9336 17.4827C20.1537 17.4852 18.3747 17.4836 16.5957 17.4836H15.9712C14.0043 17.4836 12.0375 17.4852 10.0714 17.4827C9.48454 17.4827 9.07881 17.1338 9.04626 16.6137C9.01286 16.0919 9.38436 15.6586 9.9053 15.6127C9.95706 15.6077 10.0097 15.6094 10.0614 15.6094C14.0152 15.6094 17.9698 15.6085 21.9236 15.6094C22.4979 15.6102 22.8986 15.9425 22.9462 16.4475C23.0022 17.0428 22.5781 17.4819 21.9336 17.4827ZM26.0518 7.43551C25.5317 7.44219 25.1143 7.03646 25.1076 6.51886C25.1018 6.0021 25.5125 5.5755 26.0251 5.56632C26.5243 5.55713 26.9668 5.98707 26.9768 6.49215C26.9868 7.00056 26.5661 7.42967 26.0518 7.43551Z" fill="#FE8F20"/>
        </svg>
    );
}
