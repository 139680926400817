import { API } from './api';

class GeoCoding extends API {

    getUserData() {
        console.log("[countryCode] fetch:");
        return fetch(`/api/country-code`, { method: 'GET' })
            .then((response) => {
                console.log("[countryCode] response:", response);
                // console.log("[countryCode] response json:", response.json());
                // @ts-ignore
                return response.text();
            })
            .catch((e) => {
                console.log("[countryCode] error:", e);
                console.log(e)
            });
    }
}

export const GeoCodingProvider = new GeoCoding();
