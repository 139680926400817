import React, {useContext, useState} from 'react';

import {LocalStorageKeys, TypographyVariants} from '../../constants';

import { useLocalStorage } from '../../hooks/useLocalStorage';
import { BaseContext } from '../../context/base.context';

import { Typography } from '../../components/typography';
import { NextButton } from '../../components/next-button';
import { ValidatedInput } from '../../components/validated-input';

import { useStyles } from './styles';
import {getUnitSystem, UNIT_SYSTEM} from '../../constants/body-height-weight';
import {RoutePath} from '../../routes/route-path.constant';

export const Email = () => {

    const { text1, text2 } = useStyles();

    const { pageConfiguration, optimizely } = useContext(BaseContext);

    const { pageValue: email, setPageValue: setEmail } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email], defaultValue: ''
    });

    const { pageValue: bodyIdealWeight } = useLocalStorage({ key: LocalStorageKeys[RoutePath.BodyIdealWeight], defaultValue: ''});

    const handleClick = () => {
        // @TODO: Track for now, better to rewrite it if we'll use optimizely
        optimizely.track('WebEmailClicked', pageConfiguration.getUserId());

        pageConfiguration.handleOnPageComplete(email);
    };

    const [pageError, setPageError] = useState(false);

    const onChange = (e: any) => { setEmail(e.target.value); };

    const onError = (error: boolean) => {
        setPageError(error)
    };

    const termsEvent = () => pageConfiguration.event('OnboardingStartTermsClicked');
    const privacyEvent = () => pageConfiguration.event('OnboardingStartPrivacyClicked');

    const unitSystem = getUnitSystem();
    const weightUnit = unitSystem === UNIT_SYSTEM.imperial ? 'lb' : 'kg';

    return (
        <>
            <Typography variant={TypographyVariants.h1}>
                Enter your email to see when  Able can help you reach {bodyIdealWeight} {weightUnit}
            </Typography>

            <ValidatedInput
                value={email}
                onChange={onChange}
                onError={onError}
                validationQuery={{
                    type: {
                        value: 'email',
                        errorMessage: 'Please enter correct email.'
                    }
                }}
                label='Your email'
            />

            <Typography variant={TypographyVariants.p} className={text1}>
                Able ensures the confidentiality of your personal information.
            </Typography>

            <Typography variant={TypographyVariants.p} className={`${text1} ${text2}`}>
                By clicking "See My Results" below you acknowledge that you have read, understood, and accepted Able’s&nbsp;
                <a
                    href="https://help.ableapp.com/en/articles/5590061-eula"
                    target='_blank'
                    rel='noreferrer nofollow' onClick={termsEvent}>Terms of Use</a> and &nbsp;
                <a
                    href="https://help.ableapp.com/en/articles/5590060-privacy-notice"
                    target='_blank'
                    rel='noreferrer nofollow' onClick={privacyEvent}>Privacy Policy</a>.
            </Typography>

            <NextButton onClick={handleClick} typographyText="See My Results" disabled={!email || pageError}/>
        </>
    )
};
