import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 24px',
    },
    title: {
        margin: 0,
    },
    text: {
        fontSize: '16px',
        lineHeight: '22px',
        margin: '12px 0 0',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        '& span': {
            color: '#FE8F20',
        },
    },
    cards: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '56px',
        width: '100%',
    },
    firstCard: {
        paddingTop: '16px',
        borderColor: '#C5C5D1',
        marginRight: '4px',
        '& > p': {
            display: 'none',
        },
        '& button': {
            padding: '0 10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '56px',
            marginTop: '12px',
            width: '132px',
            borderRadius: '8px',
        },
    },
    secondCard: {
        paddingTop: '16px',
        marginLeft: '4px',
        borderTopRightRadius: 0,
        BorderTopLeftRadius: 0,
        borderTop: 'none',
        '& > p': {
            top: '-32px',
            border: '2px solid #FE8F20',
            'box-sizing': 'border-box',
        },
        '& button': {
            padding: '0 10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '56px',
            marginTop: '12px',
            width: '132px',
            borderRadius: '8px',
        },
    },
    skipWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    skipButton: {
        display: 'inline-block',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        color: '#FE8F20',
        borderRadius: '20px',
        padding: '4px 8px',
        cursor: 'pointer',
        background: '#F6F6F6',
        margin: '12px 0 8px 0',
    },
    '@media (max-width: 365px)': {
        textBreak: {
            display: 'none',
        },
        firstCard: {
            '& button': {
                width: '106px',
            },
        },
        secondCard: {
            '& button': {
                width: '106px',
            },
        },
    },
});
