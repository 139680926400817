import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    dottedLoader: {
        display: 'inline-block',
        position: 'relative',

        '& div': {
            position: 'absolute',
            width: '3px',
            height: '3px',
            background: '#fff',
            borderRadius: '50%',
            animation: 'lds-default 1.2s linear infinite',

            '&:nth-child(1)': {
                animationDelay: '0s',
                top: '18.5px',
                left: '33px',
            },
            '&:nth-child(2)': {
                animationDelay: '-0.1s',
                top: '11px',
                left: '31px',
            },
            '&:nth-child(3)': {
                animationDelay: '-0.2s',
                top: '5.5px',
                left: '26px',
            },
            '&:nth-child(4)': {
                animationDelay: '-0.3s',
                top: '3.5px',
                left: '18.5px',
            },
            '&:nth-child(5)': {
                animationDelay: '-0.4s',
                top: '5.5px',
                left: '11px',
            },
            '&:nth-child(6)': {
                animationDelay: '-0.5s',
                top: '11px',
                left: '5.5px',
            },
            '&:nth-child(7)': {
                animationDelay: '-0.6s',
                top: '18.5px',
                left: '3.5px',
            },
            '&:nth-child(8)': {
                animationDelay: '-0.7s',
                top: '26px',
                left: '5.5px',
            },
            '&:nth-child(9)': {
                animationDelay: '-0.8s',
                top: '31px',
                left: '11px',
            },
            '&:nth-child(10)': {
                animationDelay: '-0.9s',
                top: '33px',
                left: '18.5px',
            },
            '&:nth-child(11)': {
                animationDelay: '-1s',
                top: '31px',
                left: '26px',
            },
            '&:nth-child(12)': {
                animationDelay: '-1.1s',
                top: '26px',
                left: '31px',
            },
        }
    },

    '@keyframes lds-default': {
        '0%, 20%, 80%, 100%': {
            transform: 'scale(1)'
        },
        '50%': {
            transform: 'scale(1.5)'
        }
    }
});
